import React, {Component} from 'react';
import "./Home.css";
import utils from "./utils";
import {Link} from "react-router-dom";

class Home extends Component{

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
        if(this.props.isAuthenticated()){
            this.props.history.push(utils.contextualizePath('/dashboard'));
        }
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark"
                     data-navbar-darken-on-scroll="data-navbar-darken-on-scroll">
                    <div className="container"><a className="navbar-brand" href="../index.html"><span
                        className="text-white dark__text-white">CI3.0</span></a>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false"
                                aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                        <div className="collapse navbar-collapse scrollbar" id="navbarStandard">
                            <ul className="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#"
                                                                     role="button" data-bs-toggle="dropdown"
                                                                     aria-haspopup="true" aria-expanded="false"
                                                                     id="dashboards">Dashboard</a>
                                    <div className="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                                         aria-labelledby="dashboards">
                                        <div className="bg-white dark__bg-1000 rounded-3 py-2"><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../index.html">Default</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../dashboard/analytics.html">Analytics</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../dashboard/crm.html">CRM</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../dashboard/e-commerce.html">E commerce</a><a
                                            className="dropdown-item link-600 fw-medium" href="../dashboard/lms.html">LMS<span
                                            className="badge rounded-pill ms-2 badge-soft-success">New</span></a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../dashboard/project-management.html">Management</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../dashboard/saas.html">SaaS</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../dashboard/support-desk.html">Support desk<span
                                            className="badge rounded-pill ms-2 badge-soft-success">New</span></a>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#"
                                                                     role="button" data-bs-toggle="dropdown"
                                                                     aria-haspopup="true" aria-expanded="false"
                                                                     id="apps">App</a>
                                    <div className="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                                         aria-labelledby="apps">
                                        <div className="card navbar-card-app shadow-none dark__bg-1000">
                                            <div className="card-body scrollbar max-h-dropdown"><img
                                                className="img-dropdown"
                                                src="../assets/img/icons/spot-illustrations/authentication-corner.png"
                                                width="130" alt=""/>
                                                <div className="row">
                                                    <div className="col-6 col-md-4">
                                                        <div className="nav flex-column"><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/calendar.html">Calendar</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/chat.html">Chat</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/kanban.html">Kanban</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">Social</p><a
                                                                className="nav-link py-1 link-600 fw-medium"
                                                                href="../app/social/feed.html">Feed</a><a
                                                                className="nav-link py-1 link-600 fw-medium"
                                                                href="../app/social/activity-log.html">Activity
                                                                log</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../app/social/notifications.html">Notifications</a><a
                                                                className="nav-link py-1 link-600 fw-medium"
                                                                href="../app/social/followers.html">Followers</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">Support
                                                                Desk</p><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../app/support-desk/table-view.html">Table
                                                                view</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../app/support-desk/card-view.html">Card
                                                                view</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../app/support-desk/contacts.html">Contacts</a><a
                                                                className="nav-link py-1 link-600 fw-medium"
                                                                href="../app/support-desk/contact-details.html">Contact
                                                                details</a><a
                                                                className="nav-link py-1 link-600 fw-medium"
                                                                href="../app/support-desk/tickets-preview.html">Tickets
                                                                preview</a><a
                                                                className="nav-link py-1 link-600 fw-medium"
                                                                href="../app/support-desk/quick-links.html">Quick
                                                                links</a><a className="nav-link py-1 link-600 fw-medium"
                                                                            href="../app/support-desk/reports.html">Reports</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">E-Learning</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../app/e-learning/course/course-list.html">Course
                                                                list</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../app/e-learning/course/course-grid.html">Course
                                                            grid</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../app/e-learning/course/course-details.html">Course
                                                            details</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../app/e-learning/course/create-a-course.html">Create
                                                            a course</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../app/e-learning/student-overview.html">Student
                                                            overview</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../app/e-learning/trainer-profile.html">Trainer
                                                            profile</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">Events</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/events/create-an-event.html">Create an
                                                            event</a><a className="nav-link py-1 link-600 fw-medium"
                                                                        href="../app/events/event-detail.html">Event
                                                            detail</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../app/events/event-list.html">Event
                                                            list</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">Email</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/email/inbox.html">Inbox</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/email/email-detail.html">Email detail</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/email/compose.html">Compose</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">E-Commerce</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../app/e-commerce/product/product-list.html">Product
                                                                list</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../app/e-commerce/product/product-grid.html">Product
                                                            grid</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../app/e-commerce/product/product-details.html">Product
                                                            details</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../app/e-commerce/orders/order-list.html">Order
                                                            list</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../app/e-commerce/orders/order-details.html">Order
                                                            details</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../app/e-commerce/customers.html">Customers</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/e-commerce/customer-details.html">Customer
                                                            details</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../app/e-commerce/shopping-cart.html">Shopping
                                                            cart</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../app/e-commerce/checkout.html">Checkout</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/e-commerce/billing.html">Billing</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../app/e-commerce/invoice.html">Invoice</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#"
                                                                     role="button" data-bs-toggle="dropdown"
                                                                     aria-haspopup="true" aria-expanded="false"
                                                                     id="pagess">Pages</a>
                                    <div className="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                                         aria-labelledby="pagess">
                                        <div className="card navbar-card-pages shadow-none dark__bg-1000">
                                            <div className="card-body scrollbar max-h-dropdown"><img
                                                className="img-dropdown"
                                                src="../assets/img/icons/spot-illustrations/authentication-corner.png"
                                                width="130" alt=""/>
                                                <div className="row">
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Simple
                                                                Auth</p><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../pages/authentication/simple/login.html">Login</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/simple/logout.html">Logout</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/simple/register.html">Register</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/simple/forgot-password.html">Forgot
                                                            password</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../pages/authentication/simple/confirm-mail.html">Confirm
                                                            mail</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../pages/authentication/simple/reset-password.html">Reset
                                                            password</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../pages/authentication/simple/lock-screen.html">Lock
                                                            screen</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Card Auth</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../pages/authentication/card/login.html">Login</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/card/logout.html">Logout</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/card/register.html">Register</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/card/forgot-password.html">Forgot
                                                            password</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../pages/authentication/card/confirm-mail.html">Confirm
                                                            mail</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../pages/authentication/card/reset-password.html">Reset
                                                            password</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../pages/authentication/card/lock-screen.html">Lock
                                                            screen</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Split Auth</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../pages/authentication/split/login.html">Login</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/split/logout.html">Logout</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/split/register.html">Register</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/authentication/split/forgot-password.html">Forgot
                                                            password</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../pages/authentication/split/confirm-mail.html">Confirm
                                                            mail</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../pages/authentication/split/reset-password.html">Reset
                                                            password</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../pages/authentication/split/lock-screen.html">Lock
                                                            screen</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Layouts</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../demo/navbar-vertical.html">Navbar vertical</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../demo/navbar-top.html">Top nav</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../demo/navbar-double-top.html">Double top<span
                                                            className="badge rounded-pill ms-2 badge-soft-success">New</span></a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../demo/combo-nav.html">Combo nav</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">Others</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/starter.html">Starter</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/landing.html">Landing</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">User</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/user/profile.html">Profile</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/user/settings.html">Settings</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Pricing</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/pricing/pricing-default.html">Pricing
                                                            default</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../pages/pricing/pricing-alt.html">Pricing
                                                            alt</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Errors</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/errors/404.html">404</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/errors/500.html">500</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Miscellaneous</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../pages/miscellaneous/associations.html">Associations</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/miscellaneous/invite-people.html">Invite
                                                            people</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../pages/miscellaneous/privacy-policy.html">Privacy
                                                            policy</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">FAQ</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/faq/faq-basic.html">Faq basic</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/faq/faq-alt.html">Faq alt</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../pages/faq/faq-accordion.html">Faq accordion</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Other Auth</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../pages/authentication/wizard.html">Wizard</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../#authentication-modal"
                                                            data-bs-toggle="modal">Modal</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#"
                                                                     role="button" data-bs-toggle="dropdown"
                                                                     aria-haspopup="true" aria-expanded="false"
                                                                     id="moduless">Modules</a>
                                    <div className="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                                         aria-labelledby="moduless">
                                        <div className="card navbar-card-components shadow-none dark__bg-1000">
                                            <div className="card-body scrollbar max-h-dropdown"><img
                                                className="img-dropdown"
                                                src="../assets/img/icons/spot-illustrations/authentication-corner.png"
                                                width="130" alt=""/>
                                                <div className="row">
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Components</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../modules/maps/google-map.html">Google map</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/maps/leaflet-map.html">Leaflet map</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/accordion.html">Accordion</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/alerts.html">Alerts</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/anchor.html">Anchor</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/animated-icons.html">Animated
                                                            icons</a><a className="nav-link py-1 link-600 fw-medium"
                                                                        href="../modules/components/background.html">Background</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/badges.html">Badges</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/bottom-bar.html">Bottom bar<span
                                                            className="badge rounded-pill ms-2 badge-soft-success">New</span></a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/breadcrumbs.html">Breadcrumbs</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/buttons.html">Buttons</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/calendar.html">Calendar</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column mt-md-4 pt-md-1"><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/cards.html">Cards</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/carousel/bootstrap.html">Bootstrap
                                                            carousel</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../modules/components/carousel/swiper.html">Swiper</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/collapse.html">Collapse</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/cookie-notice.html">Cookie
                                                            notice</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/components/countup.html">Countup</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/draggable.html">Draggable</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/dropdowns.html">Dropdowns</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/jquery-components.html">Jquery<span
                                                            className="badge rounded-pill ms-2 badge-soft-success">New</span></a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/list-group.html">List
                                                            group</a><a className="nav-link py-1 link-600 fw-medium"
                                                                        href="../modules/components/modals.html">Modals</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/navs-and-tabs/navs.html">Navs</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column mt-xxl-4 pt-xxl-1"><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/navs-and-tabs/navbar.html">Navbar</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/navs-and-tabs/vertical-navbar.html">Navbar
                                                            vertical</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../modules/components/navs-and-tabs/top-navbar.html">Top
                                                            nav</a><a className="nav-link py-1 link-600 fw-medium"
                                                                      href="../modules/components/navs-and-tabs/double-top-navbar.html">Double
                                                            top<span
                                                                className="badge rounded-pill ms-2 badge-soft-success">New</span></a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/navs-and-tabs/combo-navbar.html">Combo
                                                            nav</a><a className="nav-link py-1 link-600 fw-medium"
                                                                      href="../modules/components/navs-and-tabs/tabs.html">Tabs</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/offcanvas.html">Offcanvas</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/pictures/avatar.html">Avatar</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/pictures/images.html">Images</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/pictures/figures.html">Figures</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/pictures/hoverbox.html">Hoverbox</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/pictures/lightbox.html">Lightbox</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column mt-xxl-4 pt-xxl-1"><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/progress-bar.html">Progress
                                                            bar</a><a className="nav-link py-1 link-600 fw-medium"
                                                                      href="../modules/components/placeholder.html">Placeholder</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/pagination.html">Pagination</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/popovers.html">Popovers</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/scrollspy.html">Scrollspy</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/search.html">Search</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/spinners.html">Spinners</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/timeline.html">Timeline</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/toasts.html">Toasts</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Forms</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/basic/form-control.html">Form
                                                            control</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../modules/forms/basic/input-group.html">Input
                                                            group</a><a className="nav-link py-1 link-600 fw-medium"
                                                                        href="../modules/forms/basic/select.html">Select</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/basic/checks.html">Checks</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/basic/range.html">Range</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/basic/layout.html">Layout</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/advance/advance-select.html">Advance
                                                            select</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/forms/advance/date-picker.html">Date
                                                            picker</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/forms/advance/editor.html">Editor</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/advance/emoji-button.html">Emoji
                                                            button</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/forms/advance/file-uploader.html">File
                                                            uploader</a><a className="nav-link py-1 link-600 fw-medium"
                                                                           href="../modules/forms/advance/rating.html">Rating</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/floating-labels.html">Floating
                                                            labels</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/forms/wizard.html">Wizard</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/forms/validation.html">Validation</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Tables</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/tables/basic-tables.html">Basic
                                                            tables</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/tables/advance-tables.html">Advance
                                                            tables</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/tables/bulk-select.html">Bulk
                                                            select</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">Charts</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/charts/chartjs.html">Chartjs</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">ECharts</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/charts/d3js.html">D3js<span
                                                            className="badge rounded-pill ms-2 badge-soft-success">New</span></a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/charts/echarts/line-charts.html">Line
                                                            charts</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/charts/echarts/bar-charts.html">Bar
                                                            charts</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/charts/echarts/candlestick-charts.html">Candlestick
                                                            charts</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/charts/echarts/geo-map.html">Geo
                                                            map</a><a className="nav-link py-1 link-600 fw-medium"
                                                                      href="../modules/charts/echarts/scatter-charts.html">Scatter
                                                            charts</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/charts/echarts/pie-charts.html">Pie
                                                            charts</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/charts/echarts/gauge-charts.html">Gauge
                                                            charts</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/charts/echarts/radar-charts.html">Radar
                                                            charts</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Utilities</p>
                                                            <a className="nav-link py-1 link-600 fw-medium"
                                                               href="../modules/components/tooltips.html">Tooltips</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/treeview.html">Treeview</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/typed-text.html">Typed
                                                            text</a><a className="nav-link py-1 link-600 fw-medium"
                                                                       href="../modules/components/videos/embed.html">Embed</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/components/videos/plyr.html">Plyr</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/borders.html">Borders</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/clearfix.html">Clearfix</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/colors.html">Colors</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/colored-links.html">Colored
                                                            links</a><a className="nav-link py-1 link-600 fw-medium"
                                                                        href="../modules/utilities/display.html">Display</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/flex.html">Flex</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/float.html">Float</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/grid.html">Grid</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/overlayscrollbar.html">Overlay
                                                            scrollbar<span
                                                                className="badge rounded-pill ms-2 badge-soft-success">New</span></a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/utilities/position.html">Position</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xxl-3">
                                                        <div className="nav flex-column pt-xxl-1">
                                                            <p className="nav-link text-700 mb-0 fw-bold">Icons</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/charts/echarts/heatmap-charts.html">Heatmap
                                                            charts</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/charts/echarts/how-to-use.html">How
                                                            to use</a><a className="nav-link py-1 link-600 fw-medium"
                                                                         href="../modules/icons/font-awesome.html">Font
                                                            awesome</a><a className="nav-link py-1 link-600 fw-medium"
                                                                          href="../modules/icons/bootstrap-icons.html">Bootstrap
                                                            icons</a>
                                                            <p className="nav-link text-700 mb-0 fw-bold">Maps</p><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/icons/feather.html">Feather</a><a
                                                            className="nav-link py-1 link-600 fw-medium"
                                                            href="../modules/icons/material-icons.html">Material
                                                            icons</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#"
                                                                     role="button" data-bs-toggle="dropdown"
                                                                     aria-haspopup="true" aria-expanded="false"
                                                                     id="documentations">Documentation</a>
                                    <div className="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                                         aria-labelledby="documentations">
                                        <div className="bg-white dark__bg-1000 rounded-3 py-2"><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/getting-started.html">Getting started</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/customization/configuration.html">Configuration</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/customization/styling.html">Styling<span
                                            className="badge rounded-pill ms-2 badge-soft-success">Updated</span></a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/customization/dark-mode.html">Dark mode</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/customization/plugin.html">Plugin</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/faq.html">Faq</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/gulp.html">Gulp</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../documentation/design-file.html">Design file</a><a
                                            className="dropdown-item link-600 fw-medium"
                                            href="../changelog.html">Changelog</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item d-flex align-items-center me-2">
                                    <div className="nav-link theme-switch-toggle fa-icon-wait p-0">
                                        <input className="form-check-input ms-0 theme-switch-toggle-input"
                                               id="themeControlToggle" type="checkbox" data-theme-control="theme"
                                               value="dark"/>
                                            <label className="mb-0 theme-switch-toggle-label theme-switch-toggle-light"
                                                   htmlFor="themeControlToggle" data-bs-toggle="tooltip"
                                                   data-bs-placement="left" title="Switch to light theme"><span
                                                className="fas fa-sun"></span></label>
                                            <label className="mb-0 py-2 theme-switch-toggle-light d-lg-none"
                                                   htmlFor="themeControlToggle"><span>Switch to light theme</span></label>
                                            <label className="mb-0 theme-switch-toggle-label theme-switch-toggle-dark"
                                                   htmlFor="themeControlToggle" data-bs-toggle="tooltip"
                                                   data-bs-placement="left" title="Switch to dark theme"><span
                                                className="fas fa-moon"></span></label>
                                            <label className="mb-0 py-2 theme-switch-toggle-dark d-lg-none"
                                                   htmlFor="themeControlToggle"><span>Switch to dark theme</span></label>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link"
                                          id="navbarDropdownLogin" href="#" role="button"
                                          aria-haspopup="true" aria-expanded="false"
                                          to={utils.contextualizePath('/signin')}>Login</Link>
                                    {/*
                                    <a className="nav-link"
                                                                     id="navbarDropdownLogin" href="#" role="button"
                                                                     data-bs-toggle="dropdown" aria-haspopup="true"
                                                                     aria-expanded="false">Login</a>
                                    */}

                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={utils.contextualizePath('/signup')}>Register</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-4">
                                <div className="row text-start justify-content-between align-items-center mb-2">
                                    <div className="col-auto">
                                        <h5 id="modalLabel">Register</h5>
                                    </div>
                                    <div className="col-auto">
                                        <p className="fs--1 text-600 mb-0">Have an account? <a
                                            href="../pages/authentication/simple/login.html">Login</a></p>
                                    </div>
                                </div>
                                <form>
                                    <div className="mb-3">
                                        <input className="form-control" type="text" autoComplete="on"
                                               placeholder="Name"/>
                                    </div>
                                    <div className="mb-3">
                                        <input className="form-control" type="email" autoComplete="on"
                                               placeholder="Email address"/>
                                    </div>
                                    <div className="row gx-2">
                                        <div className="mb-3 col-sm-6">
                                            <input className="form-control" type="password" autoComplete="on"
                                                   placeholder="Password"/>
                                        </div>
                                        <div className="mb-3 col-sm-6">
                                            <input className="form-control" type="password" autoComplete="on"
                                                   placeholder="Confirm Password"/>
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                               id="modal-register-checkbox"/>
                                        <label className="form-label" htmlFor="modal-register-checkbox">I accept the <a
                                            href="#!">terms </a>and <a href="#!">privacy policy</a></label>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary d-block w-100 mt-3" type="submit"
                                                name="submit">Register
                                        </button>
                                    </div>
                                </form>
                                <div className="position-relative mt-4">
                                    <hr/>
                                    <div className="divider-content-center">or register with</div>
                                </div>
                                <div className="row g-2 mt-2">
                                    <div className="col-sm-6"><a
                                        className="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span
                                        className="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span> google</a>
                                    </div>
                                    <div className="col-sm-6"><a
                                        className="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span
                                        className="fab fa-facebook-square me-2"
                                        data-fa-transform="grow-8"></span> facebook</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="py-0 overflow-hidden light" id="banner">

                    <div className="bg-holder overlay"
                         style={{backgroundImage: 'url(../assets/img/generic/bg-1.jpg)', backgroundPosition:'center bottom'}}>
                    </div>

                    <div className="container">
                        <div className="row flex-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
                            <div className="col-md-11 col-lg-8 col-xl-4 pb-7 pb-xl-9 text-center text-xl-start"><a
                                className="btn btn-outline-danger mb-4 fs--1 border-2 rounded-pill" href="#!"><span
                                className="me-2" role="img" aria-label="Gift">ðŸŽ</span>Become a pro</a>
                                <h1 className="text-white fw-light">Bring <span className="typed-text fw-bold"
                                                                                data-typed-text='["design","beauty","elegance","perfection"]'></span><br/>to
                                    your webapp</h1>
                                <p className="lead text-white opacity-75">With the power of Falcon, you can now focus
                                    only on functionaries for your digital products, while leaving the UI design on
                                    us!</p><a
                                    className="btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2"
                                    href="#!">Start building with the falcon<span className="fas fa-play ms-2"
                                                                                  data-fa-transform="shrink-6 down-1"></span></a>
                            </div>
                            <div className="col-xl-7 offset-xl-1 align-self-end mt-4 mt-xl-0"><a
                                className="img-landing-banner rounded" href="../index.html"><img className="img-fluid"
                                                                                                 src="../assets/img/generic/dashboard-alt.jpg"
                                                                                                 alt=""/></a></div>
                        </div>
                    </div>

                </section>

                <section className="py-3 bg-light shadow-sm">

                    <div className="container">
                        <div className="row flex-center">
                            <div className="col-3 col-sm-auto my-1 my-sm-3 px-x1"><img className="landing-cta-img"
                                                                                       height="40"
                                                                                       src="../assets/img/logos/b&amp;w/6.png"
                                                                                       alt=""/></div>
                            <div className="col-3 col-sm-auto my-1 my-sm-3 px-x1"><img className="landing-cta-img"
                                                                                       height="45"
                                                                                       src="../assets/img/logos/b&amp;w/11.png"
                                                                                       alt=""/></div>
                            <div className="col-3 col-sm-auto my-1 my-sm-3 px-x1"><img className="landing-cta-img"
                                                                                       height="30"
                                                                                       src="../assets/img/logos/b&amp;w/2.png"
                                                                                       alt=""/></div>
                            <div className="col-3 col-sm-auto my-1 my-sm-3 px-x1"><img className="landing-cta-img"
                                                                                       height="30"
                                                                                       src="../assets/img/logos/b&amp;w/4.png"
                                                                                       alt=""/></div>
                            <div className="col-3 col-sm-auto my-1 my-sm-3 px-x1"><img className="landing-cta-img"
                                                                                       height="35"
                                                                                       src="../assets/img/logos/b&amp;w/1.png"
                                                                                       alt=""/></div>
                            <div className="col-3 col-sm-auto my-1 my-sm-3 px-x1"><img className="landing-cta-img"
                                                                                       height="40"
                                                                                       src="../assets/img/logos/b&amp;w/10.png"
                                                                                       alt=""/></div>
                            <div className="col-3 col-sm-auto my-1 my-sm-3 px-x1"><img className="landing-cta-img"
                                                                                       height="40"
                                                                                       src="../assets/img/logos/b&amp;w/12.png"
                                                                                       alt=""/></div>
                        </div>
                    </div>
                </section>

                <section>

                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-8 col-xl-7 col-xxl-6">
                                <h1 className="fs-2 fs-sm-4 fs-md-5">WebApp theme of the future</h1>
                                <p className="lead">Built on top of Bootstrap 5, super modular Falcon provides you
                                    gorgeous design &amp; streamlined UX for your WebApp.</p>
                            </div>
                        </div>
                        <div className="row flex-center mt-8">
                            <div className="col-md col-lg-5 col-xl-4 ps-lg-6"><img className="img-fluid px-6 px-md-0"
                                                                                   src="../assets/img/icons/spot-illustrations/50.png"
                                                                                   alt=""/></div>
                            <div className="col-md col-lg-5 col-xl-4 mt-4 mt-md-0">
                                <h5 className="text-danger"><span className="far fa-lightbulb me-2"></span>PLAN</h5>
                                <h3>Blueprint &amp; design </h3>
                                <p>With Falcon as your guide, now you have a fine-tuned state of the earth tool to make
                                    your wireframe a reality.</p>
                            </div>
                        </div>
                        <div className="row flex-center mt-7">
                            <div className="col-md col-lg-5 col-xl-4 pe-lg-6 order-md-2"><img
                                className="img-fluid px-6 px-md-0" src="../assets/img/icons/spot-illustrations/49.png"
                                alt=""/></div>
                            <div className="col-md col-lg-5 col-xl-4 mt-4 mt-md-0">
                                <h5 className="text-info"><span className="far fa-object-ungroup me-2"></span>BUILD</h5>
                                <h3>38 Sets of components</h3>
                                <p>Build any UI effortlessly with Falcon's robust set of layouts, 38 sets of built-in
                                    elements, carefully chosen colors, typography, and css helpers.</p>
                            </div>
                        </div>
                        <div className="row flex-center mt-7">
                            <div className="col-md col-lg-5 col-xl-4 ps-lg-6"><img className="img-fluid px-6 px-md-0"
                                                                                   src="../assets/img/icons/spot-illustrations/48.png"
                                                                                   alt=""/></div>
                            <div className="col-md col-lg-5 col-xl-4 mt-4 mt-md-0">
                                <h5 className="text-success"><span className="far fa-paper-plane me-2"></span>DEPLOY
                                </h5>
                                <h3>Review and test</h3>
                                <p>From IE to iOS, rigorously tested and optimized Falcon will give the near perfect
                                    finishing to your webapp; from the landing page to the logout screen.</p>
                            </div>
                        </div>
                    </div>

                </section>


                <section className="bg-light text-center">

                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h1 className="fs-2 fs-sm-4 fs-md-5">Here's what's in it for you</h1>
                                <p className="lead">Things you will get right out of the box with Falcon.</p>
                            </div>
                        </div>
                        <div className="row mt-6">
                            <div className="col-lg-4">
                                <div className="card card-span h-100">
                                    <div className="card-span-img"><span className="fab fa-sass fs-4 text-info"></span>
                                    </div>
                                    <div className="card-body pt-6 pb-4">
                                        <h5 className="mb-2">Bootstrap 5.x</h5>
                                        <p>Build your webapp with the world's most popular front-end component library
                                            along with Falcon's 32 sets of carefully designed elements.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-6 mt-lg-0">
                                <div className="card card-span h-100">
                                    <div className="card-span-img"><span
                                        className="fab fa-node-js fs-5 text-success"></span></div>
                                    <div className="card-body pt-6 pb-4">
                                        <h5 className="mb-2">SCSS &amp; Javascript files</h5>
                                        <p>With your purchased copy of Falcon, you will get all the uncompressed &
                                            documented SCSS and Javascript source code files.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-6 mt-lg-0">
                                <div className="card card-span h-100">
                                    <div className="card-span-img"><span
                                        className="fab fa-gulp fs-6 text-danger"></span></div>
                                    <div className="card-body pt-6 pb-4">
                                        <h5 className="mb-2">Gulp based workflow</h5>
                                        <p>All the painful or time-consuming tasks in your development workflow such as
                                            compiling the SCSS or transpiring the JS are automated.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-200 text-center">

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-xl-8">
                                <div className="swiper-container theme-slider"
                                     data-swiper='{"autoplay":true,"spaceBetween":5,"loop":true,"loopedSlides":5,"slideToClickedSlide":true}'>
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="px-5 px-sm-6">
                                                <p className="fs-sm-1 fs-md-2 fst-italic text-dark">Falcon is the best
                                                    option if you are looking for a theme built with Bootstrap. On top
                                                    of that, Falcon&apos;s creators and support staff are very brilliant
                                                    and attentive to users&apos; needs.</p>
                                                <p className="fs-0 text-600">- Scott Tolinski, Web Developer</p><img
                                                className="w-auto mx-auto" src="../assets/img/logos/google.png" alt=""
                                                height="45"/>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="px-5 px-sm-6">
                                                <p className="fs-sm-1 fs-md-2 fst-italic text-dark">We&apos;ve become
                                                    fanboys! Easy to change the modular design, great dashboard UI,
                                                    enterprise-class support, fast loading time. What else do you want
                                                    from a Bootstrap Theme?</p>
                                                <p className="fs-0 text-600">- Jeff Escalante, Developer</p><img
                                                className="w-auto mx-auto" src="../assets/img/logos/netflix.png" alt=""
                                                height="30"/>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="px-5 px-sm-6">
                                                <p className="fs-sm-1 fs-md-2 fst-italic text-dark">When I first saw
                                                    Falcon, I was totally blown away by the care taken in the interface.
                                                    It felt like something that I&apos;d really want to use and
                                                    something I could see being a true modern replacement to the current
                                                    class of Bootstrap themes.</p>
                                                <p className="fs-0 text-600">- Liam Martens, Designer</p><img
                                                className="w-auto mx-auto" src="../assets/img/logos/paypal.png" alt=""
                                                height="45"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-nav">
                                        <div className="swiper-button-next swiper-button-white"></div>
                                        <div className="swiper-button-prev swiper-button-white"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                <section className="light bg-dark">

                    <div className="bg-holder overlay"
                         style={{backgroundImage:'url(../assets/img/generic/bg-2.jpg)', backgroundPosition: 'center top'}}>
                    </div>

                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-8">
                                <p className="fs-3 fs-sm-4 text-white">Join our community of 20,000+ developers and
                                    content creators on their mission to build better sites and apps.</p>
                                <button className="btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2"
                                        type="button">Start your webapp
                                </button>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="bg-dark pt-8 pb-4 light">

                    <div className="container">
                        <div className="position-absolute btn-back-to-top bg-dark"><a className="text-600"
                                                                                      href="#banner"
                                                                                      data-bs-offset-top="0"><span
                            className="fas fa-chevron-up" data-fa-transform="rotate-45"></span></a></div>
                        <div className="row">
                            <div className="col-lg-4">
                                <h5 className="text-uppercase text-white opacity-85 mb-3">Our Mission</h5>
                                <p className="text-600">Falcon enables front end developers to build custom streamlined
                                    user interfaces in a matter of hours, while it gives backend developers all the UI
                                    elements they need to develop their web app.And it's rich design can be easily
                                    integrated with backends whether your app is based on ruby on rails, laravel,
                                    express or any other server side system.</p>
                                <div className="icon-group mt-4"><a className="icon-item bg-white text-facebook"
                                                                    href="#!"><span
                                    className="fab fa-facebook-f"></span></a><a
                                    className="icon-item bg-white text-twitter" href="#!"><span
                                    className="fab fa-twitter"></span></a><a
                                    className="icon-item bg-white text-google-plus" href="#!"><span
                                    className="fab fa-google-plus-g"></span></a><a
                                    className="icon-item bg-white text-linkedin" href="#!"><span
                                    className="fab fa-linkedin-in"></span></a><a className="icon-item bg-white"
                                                                                 href="#!"><span
                                    className="fab fa-medium-m"></span></a></div>
                            </div>
                            <div className="col ps-lg-6 ps-xl-8">
                                <div className="row mt-5 mt-lg-0">
                                    <div className="col-6 col-md-3">
                                        <h5 className="text-uppercase text-white opacity-85 mb-3">Company</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-1"><a className="link-600" href="#!">About</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Contact</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Careers</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Blog</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Terms</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Privacy</a></li>
                                            <li><a className="link-600" href="#!">Imprint</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <h5 className="text-uppercase text-white opacity-85 mb-3">Product</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-1"><a className="link-600" href="#!">Features</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Roadmap</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Changelog</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Pricing</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Docs</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">System Status</a>
                                            </li>
                                            <li className="mb-1"><a className="link-600" href="#!">Agencies</a></li>
                                            <li className="mb-1"><a className="link-600" href="#!">Enterprise</a></li>
                                        </ul>
                                    </div>
                                    <div className="col mt-5 mt-md-0">
                                        <h5 className="text-uppercase text-white opacity-85 mb-3">From the Blog</h5>
                                        <ul className="list-unstyled">
                                            <li>
                                                <h5 className="fs-0 mb-0"><a className="link-600" href="#!"> Interactive
                                                    graphs and charts</a></h5>
                                                <p className="text-600 opacity-50">Jan 15 &bull; 8min read </p>
                                            </li>
                                            <li>
                                                <h5 className="fs-0 mb-0"><a className="link-600" href="#!"> Lifetime
                                                    free updates</a></h5>
                                                <p className="text-600 opacity-50">Jan 5 &bull; 3min read &starf;</p>
                                            </li>
                                            <li>
                                                <h5 className="fs-0 mb-0"><a className="link-600" href="#!"> Merry
                                                    Christmas From us</a></h5>
                                                <p className="text-600 opacity-50">Dec 25 &bull; 2min read</p>
                                            </li>
                                            <li>
                                                <h5 className="fs-0 mb-0"><a className="link-600" href="#!"> The New
                                                    Falcon Theme</a></h5>
                                                <p className="text-600 opacity-50">Dec 23 &bull; 10min read </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="py-0 bg-dark light">

                    <div>
                        <hr className="my-0 text-600 opacity-25"/>
                        <div className="container py-3">
                            <div className="row justify-content-between fs--1">
                                <div className="col-12 col-sm-auto text-center">
                                    <p className="mb-0 text-600 opacity-85">Thank you for creating with Falcon <span
                                        className="d-none d-sm-inline-block">| </span><br
                                        className="d-sm-none"/> 2022 &copy; <a className="text-white opacity-85"
                                                                               href="https://themewagon.com">Themewagon</a>
                                    </p>
                                </div>
                                <div className="col-12 col-sm-auto text-center">
                                    <p className="mb-0 text-600 opacity-85">v3.14.0</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="modal fade" id="authentication-modal" tabIndex="-1" role="dialog"
                     aria-labelledby="authentication-modal-label" aria-hidden="true">
                    <div className="modal-dialog mt-6" role="document">
                        <div className="modal-content border-0">
                            <div className="modal-header px-5 position-relative modal-shape-header bg-shape">
                                <div className="position-relative z-index-1 light">
                                    <h4 className="mb-0 text-white" id="authentication-modal-label">Register</h4>
                                    <p className="fs--1 mb-0 text-white">Please create your free Falcon account</p>
                                </div>
                                <button className="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
                                        data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body py-4 px-5">
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="modal-auth-name">Name</label>
                                        <input className="form-control" type="text" autoComplete="on"
                                               id="modal-auth-name"/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="modal-auth-email">Email address</label>
                                        <input className="form-control" type="email" autoComplete="on"
                                               id="modal-auth-email"/>
                                    </div>
                                    <div className="row gx-2">
                                        <div className="mb-3 col-sm-6">
                                            <label className="form-label" htmlFor="modal-auth-password">Password</label>
                                            <input className="form-control" type="password" autoComplete="on"
                                                   id="modal-auth-password"/>
                                        </div>
                                        <div className="mb-3 col-sm-6">
                                            <label className="form-label" htmlFor="modal-auth-confirm-password">Confirm
                                                Password</label>
                                            <input className="form-control" type="password" autoComplete="on"
                                                   id="modal-auth-confirm-password"/>
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                               id="modal-auth-register-checkbox"/>
                                        <label className="form-label" htmlFor="modal-auth-register-checkbox">I accept
                                            the <a href="#!">terms </a>and <a href="#!">privacy policy</a></label>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary d-block w-100 mt-3" type="submit"
                                                name="submit">Register
                                        </button>
                                    </div>
                                </form>
                                <div className="position-relative mt-5">
                                    <hr/>
                                    <div className="divider-content-center">or register with</div>
                                </div>
                                <div className="row g-2 mt-2">
                                    <div className="col-sm-6"><a
                                        className="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span
                                        className="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span> google</a>
                                    </div>
                                    <div className="col-sm-6"><a
                                        className="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span
                                        className="fab fa-facebook-square me-2"
                                        data-fa-transform="grow-8"></span> facebook</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}
export default Home;
