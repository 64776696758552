import React, {Component} from 'react';

class FieldsValidityIconDanger extends Component{

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }


    render() {
        return (
            <>
                <span className="fas fa-asterisk text-danger"/>
            </>
        );
    };
}
export default FieldsValidityIconDanger;