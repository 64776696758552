import React, {Component} from 'react';
import "../Home.css";
import utils from "../utils";
import {Link} from "react-router-dom";
import constants from "../../constants";
import message from "../alertMessage";
import SignupController from "./SignupController";

class Signup extends Component {

    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.passwordConfirmationRef = React.createRef();
        this.privacyPolicyRef = React.createRef();
        this.signupController = new SignupController()

        this.state = {
            accountType: 'individual',
            isInvalidEmail: false,
            isInvalidPassword: false,
            isInvalidPrivacyPolicy: true,
            isDisabledSubmitButton: true,
        };
    }


    validateEmail = () => {
        this.setState({isInvalidEmail: this.isInValidEmail()});
        this.disableSubmitButton();
    }

    isInValidEmail = () => {
        return utils.fn_isInValidEmail(this.emailRef.current.value)
    }

    hideInvalidEmailMessage = () => {
        this.setState({isInvalidEmail: false});
    }

    validatePassword = () => {
        this.setState({isInvalidPassword: this.isInValidPassword()});
        this.disableSubmitButton();
    }

    isInValidPassword = () => {
        return utils.isInValidPassword(this.passwordRef.current.value, this.passwordConfirmationRef.current.value);
    }

    hideInvalidPasswordMessage = () => {
        this.setState({isInvalidPassword: false});
    }

    validatePrivacyPolicy = () => {
        this.setState({isInvalidPrivacyPolicy: this.isInValidPrivacyPolicy()});
        this.disableSubmitButton();
    }

    isInValidPrivacyPolicy = () => {
        return !this.privacyPolicyRef.current.checked
    }

    disableSubmitButton = () => {
        this.setState({isDisabledSubmitButton: this.isInValidEmail() || this.isInValidPassword() || this.isInValidPrivacyPolicy()});
    }

    componentDidMount() {
    }

    handleSignupSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append("accountType", this.state.accountType.toUpperCase());
        this.signupController
            .signup(formData)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.text().then((data) => {
                            const url = '/signup-result/' + data + '/success';
                            this.props.history.push(utils.contextualizePath(url));
                        });
                    } else if (409 === response.status) {
                        const url = '/signup-result/ /error';
                        this.props.history.push(utils.contextualizePath(url));
                    } else if (422 === response.status) {
                        //TODO : handle 422 use case
                        response.json().then((data) => {
                            if (data && data.functionnalDetails) {
                                const functionnalDetails = JSON.parse(data.functionnalDetails);
                                console.log('Functional details is:', functionnalDetails)
                                const phoneValidationError = functionnalDetails.filter(detail => detail.fieldName === 'phone');
                                const passwordValidationError = functionnalDetails.filter(detail => detail.fieldName === 'password');
                                this.setState({
                                    isInvalidPassword: passwordValidationError && passwordValidationError.length > 0
                                });
                            }
                        });
                    } else {
                        response.json().then((data) => {
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: message.getFr(data.functionnalDetails)})
                        });
                    }
                },
                (error) => {
                    console.log("Error occurred during the call", error);
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: 'Error occurred while signing up, please try again later'})
                }
            );

    }

    setAccountType = (type) => {
        this.setState({accountType: type});
    }

    isDefaultCheckedAccount = (type) => {
        return this.state.accountType === type;
    }

    render() {
        return (

            <>
                <div className="container-fluid">
                    <div className="row min-vh-100 bg-100">
                        <div className="col-6 d-none d-lg-block position-relative">
                            <div className="bg-holder"
                                 style={{backgroundImage: 'url(../../../assets/img/generic/19.jpg)'}}>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                            <div className="row justify-content-center g-0">
                                <div className="col-lg-9 col-xl-8 col-xxl-6">
                                    <div className="card">
                                        <div className="card-header bg-circle-shape bg-shape text-center p-2"><a
                                            className="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light"
                                            href="../../../index.html">CI3.0</a></div>
                                        <div className="card-body p-4">
                                            <div className="row flex-between-center">
                                                <div className="col-auto">
                                                    <h3>Register</h3>
                                                </div>
                                                <div className="col-auto fs--1 text-600"><span
                                                    className="mb-0 fw-semi-bold">Already User?</span> <span>
                                                    <Link to={utils.contextualizePath('/signin')}>Login</Link>
                                                    </span>
                                                </div>
                                            </div>
                                            <form onSubmit={this.handleSignupSubmit}>
                                                <div className="mb-3">
                                                <span style={{marginRight: '30pt'}}>
                                                 <input  className="form-check-input"
                                                        type="radio"
                                                        value="corporation"
                                                         onChange={() => this.setAccountType('corporation')}
                                                         checked={this.isDefaultCheckedAccount('corporation')}
                                                        id="accountType_corporation"
                                                        name="accountType"
                                                />
                                                <label className="form-check-label mb-0" htmlFor="accountType_corporation">&nbsp; Entreprise</label>
                                                </span>
                                                <span>
                                                <input  className="form-check-input"
                                                        type="radio"
                                                        value="individual"
                                                        checked={this.isDefaultCheckedAccount('individual')}
                                                        onChange={() => this.setAccountType('individual')}
                                                        id="accountType_individual"
                                                        name="accountType"
                                                    />
                                                    <label className="form-check-label mb-0" htmlFor="accountType_individual">&nbsp; Particulier</label>
                                                    </span>
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="split-email">Email
                                                        address</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        autoComplete="on"
                                                        id="split-email"
                                                        name="email"
                                                        ref={this.emailRef}
                                                        onBlur={this.validateEmail}
                                                        onFocus={this.hideInvalidEmailMessage}
                                                    />
                                                    <p style={{display: this.state.isInvalidEmail ? 'block' : 'none'}}
                                                       className="mb-3 invalid-input-message">Invalid user email</p>
                                                </div>

                                                <div className="mb-3">
                                                    <div className="row g-3 mb-3">
                                                        <div className="col-md-6 " style={{display: 'individual' === this.state.accountType? 'block':'none'}}>
                                                            <label className="form-check-label mb-0">Nom</label>
                                                            <input  className="form-control form-select-lg"
                                                                    id="firstName"
                                                                    name="firstName"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 " style={{display: 'individual' === this.state.accountType? 'block':'none'}}>
                                                            <label className="form-check-label mb-0">Prénoms</label>
                                                            <input  className="form-control form-select-lg"
                                                                    id="surname"
                                                                    name="surname"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row g-3 mb-3" style={{display: 'corporation' === this.state.accountType? 'block':'none'}}>
                                                        <div className="col-md-12 ">
                                                            <label className="form-check-label mb-0">Dénomination</label>
                                                            <input  className="form-control form-select-lg"
                                                                    id="corporationName"
                                                                    name="corporationName"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row gx-2">
                                                    <div className="mb-3 col-sm-6">
                                                        <label className="form-label"
                                                               htmlFor="split-password">Password</label>
                                                        <input
                                                            className="form-control"
                                                            type="password"
                                                            autoComplete="on"
                                                            id="split-password"
                                                            name="password"
                                                            ref={this.passwordRef}
                                                            onBlur={this.validatePassword}
                                                            onFocus={this.hideInvalidPasswordMessage}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-sm-6">
                                                        <label className="form-label" htmlFor="split-confirm-password">Confirm
                                                            Password</label>
                                                        <input
                                                            className="form-control"
                                                            type="password"
                                                            autoComplete="on"
                                                            id="split-confirm-password"
                                                            name="passwordConfirm"
                                                            ref={this.passwordConfirmationRef}
                                                            onBlur={this.validatePassword}
                                                            onFocus={this.hideInvalidPasswordMessage}
                                                        />
                                                    </div>
                                                    <p style={{display: this.state.isInvalidPassword ? 'block' : 'none'}}
                                                       className="mb-3 invalid-input-message">
                                                        Invalid password: Password and its confirmation should be
                                                        identical, has at least 7 chars that contains letter, caps,
                                                        numbers and one of the following
                                                        special chars {constants.PASSWORD_ACCEPTED_SPECIAL_CHARS} </p>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="cover-register-checkbox"
                                                        ref={this.privacyPolicyRef}
                                                        onChange={this.validatePrivacyPolicy}
                                                    />
                                                    <label className="form-label" htmlFor="cover-register-checkbox">I
                                                        accept the <a href="#!">terms </a>and <a href="#!">privacy
                                                            policy</a></label>
                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        className="btn btn-primary d-block w-100 mt-3"
                                                        type="submit"
                                                        name="submit"
                                                        disabled={this.state.isDisabledSubmitButton}
                                                    >Register
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default Signup;
