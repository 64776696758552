import React, {Component} from 'react';
import "./UploadImagePreview.css";

class UploadedImagePreview extends Component{

    constructor(props) {
        super(props);
        this.imagePreviewRef = React.createRef();
    }

    componentDidMount() {
        this.imagePreviewRef.current.src = URL.createObjectURL(this.props.file)
        this.imagePreviewRef.current.alt = this.props.file.name
        this.imagePreviewRef.current.title = this.props.file.name
    }

    render() {
        return (
            <>
                <div className="upload-image-preview-container">
                    <img
                        src="#"
                        ref={this.imagePreviewRef}
                        className="upload-image-preview"
                        alt="" />
                    <a
                        className="btn btn-falcon-danger d-block"
                        onClick={this.props.delete}
                        href="#!"
                    >Delete</a>
                </div>
            </>
        );
    };
}
export default UploadedImagePreview;