const constants = {

    restApiEndpoints: {
        SEED_HOUSES: "/restapi/seeddata/houses",
        SEED_APARTMENTS: "/restapi/seeddata/apartments",
        SEED_BUILDING_LANDS: "/restapi/seeddata/buildinglands",
        SEED_ALL_REALESTATE: "/restapi/seeddata/allrealestates",
        REALESTATE: "/restapi/realestate",
        REALESTATE_PICTURE: "/restapi/realestate/picture/",
        REALESTATE_TYPES: "/restapi/realestate/types",
        REALESTATE_COMPONENT_TYPES: "/restapi/realestate/component-types",
        USER_REALESTATES_URL: "/restapi/user/realestates",
        SIMPLIFIED_LOAN_SIMULATION_URL: "/restapi/loan-simulation/simplified",

        FILE_DATA: "/restapi/file/",
        TENANT: "/restapi/tenant/",
        TENANT_INDIVIDUAL: "/restapi/tenant/individual",
        TENANT_CORPORATION: "/restapi/tenant/corporation",
    },
    auxiliariesApiEndpoints: {
        TONWS_LIKE: "/auxiliary/address/town",
        COUNTRIES_LIKE: "/auxiliary/address/country",
        JOBS_AND_CATEGORY_LIKE: "/auxiliary/job",
        LOOKUP_TOWN_LIKE: "/auxiliary/lookup/country",
        LOOKUP_LEGAL_STATUS_LIKE: "/auxiliary/lookup/corporation-legal-status",
        LOOKUP_IDENTIFICATION_DOCUMENT_TYPES: "/auxiliary/lookup/identification-document-types",
        LOOKUP_DOCUMENT_TYPES: "/auxiliary/lookup/document-types",
        LOOKUP_CONTRACT_TYPES: "/auxiliary/lookup/contract-types",
    },
    authenticationApiEndpoints: {
        SIGNIN: "/authentication/signin",
        USER: "/authentication/user",
        PROFILE: "/authentication/profile",
        SUBSCRIPTION: "/authentication/subscription/",
        PASSWORD_RENEW: "/authentication/password/renew",
        PASSWORD_RESET: "/authentication/password/",
        ACCOUNT: "/authentication/account/",
        ACCOUNT_DETAILS: "/authentication/account/details",
        INDIVIDUAL: "/authentication/account/individual/",
        CORPORATION: "/authentication/account/corporation/",
        FILE_DATA: "/authentication/file/",
        TENANT: "/authentication/tenant/",
        TENANT_INDIVIDUAL: "/authentication/tenant/individual",
        TENANT_CORPORATION: "/authentication/tenant/corporation",
    },
    common: {
        GRANT_TYPE_KEY: "grant_type",
        GRANT_TYPE_VALUE_PASSWORD: "password",
        GRANT_TYPE_VALUE_CLIENT_CREDENTIALS: "client_credentials",
        TOKEN_STORAGE_KEY: "currentUser",
        PASSWORD_ACCEPTED_SPECIAL_CHARS: "[~!@#$%^&*_+=\'|(){}\[\]:;\"\'<>,.? \/]",
    }

}
export default constants;