import React, {Component} from 'react';
import "./Account.css";

class BankAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return (

            <>
                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Nom de la banque:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.bankName}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Addresse de la banque:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.address}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-2 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Code banque:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.rib.substring(0, 5)}</p>
                        </div>
                    </div>

                    <div className="col-md-2 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Code guichet:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.rib.substring(5, 10)}</p>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Numéro de compte:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.rib.substring(10, 21)}</p>
                        </div>
                    </div>

                    <div className="col-md-2 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Clé RIB:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.rib.substring(21, 23)}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">IBAN:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.iban}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">BIC/SWIFT:</h6>
                            <p className="fs-0 mb-0"> {this.props.bankAccount.bic}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default BankAccount;
