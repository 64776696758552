import React, { Component } from 'react';
import utils from "../utils";
import {Link} from "react-router-dom";

class SideNavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated:this.props.isAuthenticated,
        };
    }

    componentDidMount(){
        const navbarStyle = localStorage.getItem("navbarStyle");
        if (navbarStyle && navbarStyle !== 'transparent') {
            document.querySelector('.navbar-vertical').classList.add(`navbar-${navbarStyle}`);
        }
    }

    render() {
        return (
            <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
                <div className="d-flex align-items-center">
                    <div className="toggle-icon-wrapper">

                        <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                                data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation"><span
                            className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>

                    </div>
                    <a className="navbar-brand" href="index.html">
                        <div className="d-flex align-items-center py-3"><img className="me-2"
                                                                             src={utils.getPublicUrlOf('assets/img/icons/spot-illustrations/falcon.png')}
                                                                             alt="" width="40"/><span
                            className="font-sans-serif">CI3.0</span>
                        </div>
                    </a>
                </div>
                <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
                    <div className="navbar-vertical-content scrollbar">
                        <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                            <li className="nav-item">
                                <a className="nav-link dropdown-indicator" href="#dashboard"
                                                       role="button" data-bs-toggle="collapse" aria-expanded="true"
                                                       aria-controls="dashboard">
                                <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                    className="fas fa-chart-pie"></span></span><span
                                    className="nav-link-text ps-1">Dashboard</span>
                                </div>
                            </a>
                                <ul className="nav collapse show" id="dashboard">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link active"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text ps-1">Dashboard Item 1</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text ps-1">Dashboard Item 2</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                
                                <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                    <div className="col-auto navbar-vertical-label">Gestion de biens
                                    </div>
                                    <div className="col ps-0">
                                        <hr className="mb-0 navbar-vertical-divider"/>
                                    </div>
                                </div>

                                <a className="nav-link dropdown-indicator" href="#events"
                                                       role="button" data-bs-toggle="collapse" aria-expanded="false"
                                                       aria-controls="events">
                                <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                    className="fas fa-calendar-day"></span></span><span
                                    className="nav-link-text ps-1">Biens et Lots</span>
                                </div>
                            </a>
                                <ul className="nav collapse" id="events">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/bien')}>
                                            <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Biens</span>
                                            </div>
                                        </Link>

                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Lots</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>

                                <a className="nav-link dropdown-indicator" href="#e-learning"
                                                       role="button" data-bs-toggle="collapse" aria-expanded="false"
                                                       aria-controls="e-learning">
                                <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                    className="fas fa-graduation-cap"></span></span><span
                                    className="nav-link-text ps-1">Gestionnaires et Locataires</span>
                                </div>
                            </a>
                                <ul className="nav collapse" id="e-learning">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Gestionnaires</span>
                                            </div>
                                        </Link>

                                        {/*
                                        <a className="nav-link"
                                                                href="app/e-learning/student-overview.html">
                                            <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Gestionnaires</span>
                                            </div>
                                        </a>
                                        */}

                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/tenant')}>
                                            <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Locataires</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                
                                <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                    <div className="col-auto navbar-vertical-label">Gestion de bail
                                    </div>
                                    <div className="col ps-0">
                                        <hr className="mb-0 navbar-vertical-divider"/>
                                    </div>
                                </div>

                                <Link
                                    className="nav-link"
                                    role="button"
                                    to={utils.contextualizePath('/underconstruction')}>
                                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                        className="fas fa-flag"></span></span><span
                                        className="nav-link-text ps-1">Baux</span>
                                    </div>
                                </Link>

                                <Link
                                    className="nav-link"
                                    role="button"
                                    to={utils.contextualizePath('/underconstruction')}>
                                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                        className="fas fa-globe"></span></span><span
                                        className="nav-link-text ps-1">Documents</span>
                                    </div>
                                </Link>

                            </li>
                            <li className="nav-item">
                                
                                <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                    <div className="col-auto navbar-vertical-label">Autres Modules
                                    </div>
                                    <div className="col ps-0">
                                        <hr className="mb-0 navbar-vertical-divider"/>
                                    </div>
                                </div>
                                <a className="nav-link dropdown-indicator" href="#forms"
                                                       role="button" data-bs-toggle="collapse" aria-expanded="false"
                                                       aria-controls="forms">
                                <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                    className="fas fa-file-alt"></span></span><span
                                    className="nav-link-text ps-1">Module1</span>
                                </div>
                            </a>
                                <ul className="nav collapse" id="forms">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text ps-1">item 1.1</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text ps-1">item 1.2</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>

                                <a className="nav-link dropdown-indicator" href="#forms"
                                   role="button" data-bs-toggle="collapse" aria-expanded="false"
                                   aria-controls="forms">
                                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                        className="fas fa-file-alt"></span></span><span
                                        className="nav-link-text ps-1">Module2</span>
                                    </div>
                                </a>
                                <ul className="nav collapse" id="forms">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text ps-1">item 2.1</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={utils.contextualizePath('/underconstruction')}>
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text ps-1">item 2.2</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>

                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        );
    }

}
export default SideNavBar;