import SignupService from "./SignupService";

class SignupController {

    constructor() {
        this.signupService = new SignupService();
    }

    signup = (userData) => {
        return this.signupService.signup(userData);
    }

    resendSubscriptionEmail = (resourceUuid) => {
        return this.signupService.resendSubscriptionEmail(resourceUuid);
    }

    validateSubscription = (resourceUuid, data) => {
        return this.signupService.validateSubscription(resourceUuid, data);
    }

    regenerateSubscriptionValidationLink = (formData) => {
        return this.signupService.regenerateSubscriptionValidationLink(formData);
    }

    deleteAccount = () => {
        return this.signupService.deleteAccount()
    }

}
export default SignupController;
