import AuthService from "../AuthService";
import constants from "../../constants";
import utils from "../utils";

class SigninService {

    signin = (signinData) => {
        signinData.append(constants.common.GRANT_TYPE_KEY, constants.common.GRANT_TYPE_VALUE_PASSWORD);
        return AuthService.signin(signinData);
    }

    renewPassword = (data) => {
        console.log("recieved signin data is : ", data)

        let url = utils.proxify(constants.authenticationApiEndpoints.PASSWORD_RENEW);
        let dataAsJson = utils.formDataToJsonObject(data);
        url = utils.addQueryParameters(url, dataAsJson)
        let headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: ''
            });
    }

    resetPassword = (formData, resourceUuid) => {
        console.log("recieved reset password data is : ", formData)
        let url = utils.proxify(constants.authenticationApiEndpoints.PASSWORD_RESET);
        url += resourceUuid + '/reset'
        let dataAsJson = utils.formDataToJsonObject(formData);
        url = utils.addQueryParameters(url, dataAsJson)
        let headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'PUT',
                headers: headers,
                mode: 'cors',
                body: JSON.stringify(utils.formDataToJson(formData))
            });
    }
}

export default SigninService;
