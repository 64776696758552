import React, {Component} from 'react';
import {Link} from "react-router-dom";
import utils from "../utils";
import SigninController from "./SigninController";
import {isEmpty} from "lodash";
import constants from "../../constants";
import message from "../alertMessage";

class Resignin extends Component {

    constructor(props) {
        super(props);
        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.signinController =  new SigninController();
        this.state = {
            isInvalidUsername: false,
            isInvalidPassword: false,
            isFailedSignin: false,
            failedSigninMessage: '',
            isDisabledSubmitButton: true,
        };
    }

    showModal = () => {
        window.jQuery('#modalBox').modal("show");
    }

    hideModal = (history) => {
        window.jQuery('#modalBox').modal("hide");
    }

    isInValidUsername = () => {
        return isEmpty(this.usernameRef.current.value)
    }

    isInValidPassword = () => {
        return isEmpty(this.passwordRef.current.value)
    }

    validateUsername = () => {
        this.setState({isInvalidUsername: this.isInValidUsername()});
        this.disableSubmitButton();
    }

    validatePassword = () => {
        this.setState({isInvalidPassword: this.isInValidPassword()});
        this.disableSubmitButton();
    }

    hideInvalidUsernameMessage = () => {
        this.setState({isInvalidUsername: false, isFailedSignin:false});
    }

    hideInvalidPasswordMessage = () => {
        this.setState({isInvalidPassword: false, isFailedSignin:false});
    }

    disableSubmitButton = () => {
        this.setState({isDisabledSubmitButton: this.isInValidUsername() || this.isInValidPassword()});
    }

    handleReSigninSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        this.signinController
            .signin(formData)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            localStorage.setItem(constants.common.TOKEN_STORAGE_KEY, JSON.stringify(data));
                            console.log("Connexion succeeded !!");
                            this.hideModal();
                        });
                    } else if (401 === response.status) {
                        response.json().then((data) => {
                            this.setState({isFailedSignin: true, failedSigninMessage: 'Invalid unsername/password'});
                        });
                    } else if(422 === response.status){
                        //TODO : handle 422 use case
                    } else {
                        response.json().then( (data) => {
                            this.setState({isFailedSignin: true, failedSigninMessage: message.getFr(data.functionnalDetails)});
                        });
                    }
                },
                (error) => {
                    console.log("Error occurred during the call", error);
                    this.setState({isFailedSignin: true, failedSigninMessage: 'Error occurred while signing in, please try again later'});
                }
            );

    }

    render() {
        return (

            <>
                <div className="modal fade" id="modalBox" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document" >
                        <div className="modal-content position-relative">
                            <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                                <button
                                    className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="row justify-content-center g-0">
                                    <p style={{display: this.state.isFailedSignin?'':'none'}} className="failed-signin-message" >{this.state.failedSigninMessage}</p>
                                    <div className="card">
                                        <div className="card-header bg-circle-shape bg-shape text-center p-2"><a
                                            className="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light"
                                            href="../../../index.html" onClick={this.hideModal}>CI3.0</a></div>
                                        <div className="card-body p-4">
                                            <div className="row flex-between-center">
                                                <div className="col-auto">
                                                    <h3>Login</h3>
                                                </div>
                                                <div className="col-auto fs--1 text-600"><span
                                                    className="mb-0 fw-semi-bold">New User?</span> <span>
                                                    <Link to={utils.contextualizePath('/signup')} onClick={this.hideModal}>Create account</Link>
                                                    </span>
                                                </div>
                                            </div>
                                            <form onSubmit={this.handleReSigninSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="split-login-email">Username or Email</label>
                                                    <input className="form-control"
                                                           id="split-login-email"
                                                           name="username"
                                                           ref={this.usernameRef}
                                                           onBlur={this.validateUsername}
                                                           onFocus={this.hideInvalidUsernameMessage}
                                                    />
                                                    <p style={{display: this.state.isInvalidUsername? 'block':'none'}} className="mb-3 invalid-input-message">Invalid email</p>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label"
                                                               htmlFor="split-login-password">Password</label>
                                                    </div>
                                                    <input className="form-control"
                                                           id="split-login-password"
                                                           name="password"
                                                           type="password"
                                                           ref={this.passwordRef}
                                                           onBlur={this.validatePassword}
                                                           onFocus={this.hideInvalidPasswordMessage}
                                                    />
                                                    <p style={{display: this.state.isInvalidPassword? 'block':'none'}} className="mb-3 invalid-input-message">Invalid password</p>
                                                </div>
                                                <div className="row flex-between-center">
                                                    <div className="col-auto">
                                                        <div className="form-check mb-0">
                                                            <input className="form-check-input" type="checkbox"
                                                                   id="split-checkbox"/>
                                                            <label className="form-check-label mb-0"
                                                                   htmlFor="split-checkbox">Remember me</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <Link className="fs--1" to={utils.contextualizePath('/password/renew')} onClick={this.hideModal}>Forgot Password?</Link>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <button disabled={this.state.isDisabledSubmitButton}
                                                            className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Log in
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default Resignin;
