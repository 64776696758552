import utils from "../utils";
import constants from "../../constants";
class RealEstateService {

    createRealEstate = (data) => {
        const url = utils.proxify(constants.restApiEndpoints.REALESTATE);
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

    updateRealEstate = (resourceUuid, data) => {
        const url = utils.proxify(constants.restApiEndpoints.REALESTATE + '/' + resourceUuid);
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'PUT',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

    getRealEstateTypes = () => {
        const url = utils.proxify(constants.restApiEndpoints.REALESTATE_TYPES);
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getHabitableRealEstateTypes = () => {
        const url = utils.proxify(constants.restApiEndpoints.REALESTATE_COMPONENT_TYPES);
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getUserRealEstates = () => {
        const url = utils.proxify(constants.restApiEndpoints.REALESTATE);
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getRealEstateDetails = (resourceUuid) => {
        const url = utils.proxify(constants.restApiEndpoints.REALESTATE) + '/' + resourceUuid;
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    deleteRealEstate = (resourceUuid) => {
        const url = utils.proxify(constants.restApiEndpoints.REALESTATE) + '/' + resourceUuid;
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'DELETE',
                headers: headers,
                mode: 'cors'
            });
    }

}
export default RealEstateService;
