import React, {Component} from 'react';
import {isEmpty} from "lodash";

class TenantDetailsPersonsPreview extends Component{

    constructor(props) {
        super(props);
        this.state = {
            persons: isEmpty(this.props.persons)?[]:this.props.persons,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Mobile</th>
                        <th>Fixe</th>
                        <th>Email</th>
                        <th>Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.persons.map((person, index) => {
                            return <tr key={index}>
                                <td><a href="">{person.name}</a></td>
                                <td>{person.mobile}</td>
                                <td>{person.phone}</td>
                                <td>{person.email}</td>
                                <td>{person.type}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </>
        );
    };
}
export default TenantDetailsPersonsPreview;