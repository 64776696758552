
const message = {
    ERR_GEN_401: {
        fr:"Nom d'utilisateur ou mot de passe incorrect",
        en:"Username or password incorrect"
    },
    ERR_GEN_403: {
        fr:"Vous n'êtes pas autorisés à effectuer cette action",
        en:"You are not allowed to execute this action"
    },
    ERR_GEN_422: {
        fr:"Certains champs obligatoire n'ont pas des valeurs correctes",
        en:"Some mandatory fields are filled with incorrect values"
    },
    ERR_GEN_500: {
        fr:"Une erreur s'est produite lors de l'exécution de votre action",
        en:"An error occured while processing your action"
    },
    ERR_GEN_UNEXPECTED: {
        fr:"Une erreur inatendue s'est produite lors de l'exécution de votre action",
        en:"An unexpected error occured while processing your action"
    },
    SUCC_REALESTATE_UPDATE: {
        fr:"Le bien a été mis à jour",
        en:"Real estate was successfully updated"
    },
    SUCC_REALESTATE_CREATION: {
        fr:"Le bien a été créé",
        en:"Real estate was successfully created"
    },
    SUCC_PROFILE_CREATION: {
        fr:"Votre profile a été créé",
        en:"Your profil was successfully created"
    },
    SUCC_SUBSCRIPTION_SENT: {
        fr:"L'email de souscription a bien été renvoyé",
        en:"Subscription email has been successfully resent"
    },
    SUCC_SUBSCRIPTION_VALIDATED: {
        fr:"Votre compte a bien été validé",
        en:"Your account has been successfully validated!"
    },
    SUCC_SIGNUP: {
        fr:"Vous avez bien été enrégistré!",
        en:"You've been successfully registred"
    },


    getFr: code => getFr(code),
    getEn: code => getEn(code),
}
function getFr(code) {
    return message[code].fr;
}
function getEn(code) {
    return message[code].en;
}
export default message;