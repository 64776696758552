import AuthService from '../components/AuthService'
import {isEmpty} from "lodash";
const en = require('./i18n/en.json');
const fr = require('./i18n/fr.json');

function buildJsonFromString(result, key, value) {
    const nodeElements = key.split('.');
    if (nodeElements.length === 1) {
        result[nodeElements[0]] = value
    } else {
        if (!result[nodeElements[0]]) {
            result[nodeElements[0]] = {}
        }
        let newKey = nodeElements.slice(1, nodeElements.length).join('.');
        buildJsonFromString(result[nodeElements[0]], newKey, value)
    }
}

function addHeader(config, headerKey, headerValue) {
    config[headerKey] = headerValue;
    return config;
}

function removeHeader(config, headerKey) {
    if(config[headerKey] === undefined || config[headerKey] === null)
        return config;
    let result = {};
    Object.keys(config).forEach(key => {
        if(headerKey !== key){
            result[key] = config[key];
        }
    })
    return result;
}

function addBasicAutorizationHeader(config) {
    return addHeader(config, 'Authorization', 'Basic ');
}

function addBearerAuthorizationHeader(config) {
    config = removeHeader(config, 'CI30-DELEGATION-POLICY')
    if (AuthService.isSignedIn()){
        let currentToken = AuthService.getAccesToken();
        return addHeader(config, 'Authorization', 'Bearer ' + currentToken);
    }
    return config;
}

function getDefaultRequestHeaders() {

    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
    }
}

function addQueryParameters(url, paramsObj) {
    let isFirstElmt = true;
    for (let key in paramsObj) {
        if (paramsObj.hasOwnProperty(key)) {
            url += ((isFirstElmt === true) ? '?' : '&') + key + '=' + paramsObj[key];
            if (isFirstElmt === true)
                isFirstElmt = false;
        }
    }
    return url;
}

function addFormDateAsQueryParams(url, formData) {
    const data = [...formData.entries()];
    const queryString = data
        .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
        .join('&');
    url +='?' + queryString;
    return url;
}

function addCi30DelegationPolicyToClient(config) {
    //addHeader(config, 'Content-Encoding ', 'gzip');
    return addHeader(config, 'CI30-DELEGATION-POLICY', 'system_user');
}

function addCi30DelegationPolicyToAgent(config) {
    return addHeader(config, 'CI30-DELEGATION-POLICY', 'agent');
}

function formDataToJsonObject(formDataObj) {

    let json = {};
    for (let entry of formDataObj.entries()) {
        json[entry[0]] = entry[1];
    }
    return json;
}


function replaceAccentedChars(textToReplace) {
    const accentedCharsMap = {
        'à':'a',
        'â':'a',
        'ç':'c',
        'è':'e',
        'é':'e',
        'ê':'e',
        'î':'i',
        'ô':'o',
        'ù':'u',
        'û':'u'
    }
    let result = '';
    for(let i=0; i<textToReplace.length; i++){
        let replacedChar = textToReplace[i];
        if(accentedCharsMap[replacedChar])
            replacedChar = accentedCharsMap[replacedChar];
        result += replacedChar
    }
    return result;
}

function getHumanReadableAddress (addressObject) {
    if(!addressObject)
        return '';
    let result = '';
    result += addressObject.streetNumber + ', '
    result += addressObject.street + ' '
    result += addressObject.town + ' '
    result += addressObject.postalCode + ' - '
    result += addressObject.country
    return result;
}

function hasWhiteSpace(s) {
    return /\s/g.test(s);
}

function doesPasswordMatchExpectedPattern (password) {
    return 6<password.length && password.match(/[a-z]+/) && password.match(/[A-Z]+/) && password.match(/[0-9]+/)
        && password.match(/[~!@#$%^&*_+=\-'|(){}\[\]:;"'<>,.? \/]+/)
}

function i18n(key, language) {
    if('en' === language){
        return en[key]
    }else if ('fr' === language){
        return fr[key]
    }else {
        throw new Error("Unsupported language")
    }
}

function buildPersonDetails(data, type, result){
    if (!isEmpty(data)){
        for (let i=0; i<data.length; i++){
            let name, contact;
            if ('individual' === data[i].type){
                name = data[i].person.firstName + ' ' + data[i].person.surname;
            }else{
                name = data[i].person.name + ' (' + data[i].person.legalStatus + ')';
            }
            contact = !isEmpty(data[i].person.contacts)?data[i].person.contacts[0]:{}
            result.push({
                name:name,
                mobile:contact.mobile,
                phone:contact.phone,
                email:contact.email,
                type:type,
            });
        }
    }
}

 function mergeGuarantorsAndEmergencyContacts (guarantors, emergencyContacts){
    let result = [];
    buildPersonDetails(guarantors, 'Guarant', result);
    buildPersonDetails(emergencyContacts, "Contact d'urgence", result);
    return result;
}

function mergeTenantDocumentsForDisplay(tenantDetails) {
    let result = [];
    if (!isEmpty(tenantDetails.identificationDocument)){
        const fileDataUuid = !isEmpty(tenantDetails.identificationDocument.fileData)?tenantDetails.identificationDocument.fileData.resourceUuid:'';
        const fileName = !isEmpty(tenantDetails.identificationDocument.fileData)?tenantDetails.identificationDocument.fileData.label:'';
        const row = {
            type: i18n(tenantDetails.identificationDocument.type, 'en'),
            number: tenantDetails.identificationDocument.registrationNumber,
            description: i18n(tenantDetails.identificationDocument.type, 'en'),
            expirationDate: tenantDetails.identificationDocument.expirationDate,
            uploadDate: tenantDetails.identificationDocument.uploadDate,
            fileDataUuid: fileDataUuid,
            fileName: fileName,
        }
        result.push(row);
    }

    if (!isEmpty(tenantDetails.documents)){
        for (let i=0; i<tenantDetails.documents.length; i++){
            const fileDataUuid = !isEmpty(tenantDetails.documents[i].fileData)?tenantDetails.documents[i].fileData.resourceUuid:'';
            const fileName = !isEmpty(tenantDetails.documents[i].fileData)?tenantDetails.documents[i].fileData.label:'';
            const row = {
                type: i18n(tenantDetails.documents[i].type, 'en'),
                number: 'Aucun',
                description: tenantDetails.documents[i].description,
                expirationDate: 'Aucun',
                uploadDate: tenantDetails.documents[i].uploadDate,
                fileDataUuid: fileDataUuid,
                fileName: fileName,
            }
            result.push(row);
        }
    }
    return result;
}

const utils = {
    proxify: (url) => {
        console.log("ENV : " + process.env.NODE_ENV);
        console.log("ENV : " + process.env.REACT_APP_REST_API_URL);
        return process.env.NODE_ENV === 'development' ? url : process.env.REACT_APP_REST_API_URL + url;
    },
    contextualizePath: (path) => {
        console.log("current ENV is : " + process.env.NODE_ENV);
        console.log("current application context is : " + process.env.REACT_APP_CONTEXT);
        return process.env.REACT_APP_CONTEXT + path;
    },
    getPublicUrlOf: (url) => {
        console.log("current ENV is : " + process.env.NODE_ENV);
        console.log("current public base url is : " + process.env.REACT_APP_PUBLIC_URL);
        console.log("computed url is : " + (process.env.REACT_APP_PUBLIC_URL + url));
        return process.env.REACT_APP_PUBLIC_URL + url;
    },
    formDataToJson: (formData) => {
        let result = {};
        formData.forEach((value, key) => {
            if (key.indexOf('.') > 0) {
                buildJsonFromString(result, key, value);
            } else {
                result[key] = value;
            }
        });
        return result;
    },
    isInValidPassword : (password, passwordConfirmation) => {
        return isEmpty(password) || isEmpty(passwordConfirmation) ||
            (!isEmpty(password) && !isEmpty(passwordConfirmation) &&
                (password !== passwordConfirmation || !doesPasswordMatchExpectedPattern(password)))
    },
    i18n : i18n,
    formatDate : (date) => {
        return window.dateFormat(date, "dd/mm/yyyy, hh:MM:ss");
    },
    computeToastMessage : (rawMessage) => {
        let messages = [];
        JSON.parse(rawMessage.functionnalDetails).forEach(details => messages.push(details));
        return {
            messageDetails:messages,
            messageDate: new Date(rawMessage.timestamp.year, rawMessage.timestamp.monthValue -1 , rawMessage.timestamp.dayOfMonth, rawMessage.timestamp.hour, rawMessage.timestamp.minute, rawMessage.timestamp.second)
        }
    },
    getGligthBoxGallery : () => {
        return window.GLightbox({
            elements: [],
        });
    },
    isUploadedFileAnImage : (file) => {
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'];
        const fileType = file['type'];
        return validImageTypes.includes(fileType)
    },
    updateQueuedFiles : (file, queuedFiles) => {
        let currentKey = 0;
        const keys = Object.keys(queuedFiles);
        if(keys.length !== 0) {
            const max = keys.map(function(key, index) {
                return key
            }).reduce((acc, val) => {
                return acc > val ? acc : val;
            });
            currentKey = max + 1;
        }
        queuedFiles[currentKey] = file;
        return queuedFiles;
    },
    fn_validateSurface : (input) => {
        if(isEmpty(input))
            return true;
        const matched = input.match(/[0-9]+/)
        return !(matched && input === matched[0]);
    },
    fn_isInValidTel: (tel) => {
        const matched = tel.match(/(\+33|\+330|33|330|0033|00330|0){0,1}(1|2|3|4|5|8|9 ){1,1}[0-9]{8,8}/)
        return !(matched && tel === matched[0]);
    },
    fn_isInValidMobile: (mobile) => {
        const matched = mobile.match(/(\+33|\+330|33|330|0033|00330|0){0,1}(6|7){1,1}[0-9]{8,8}/)
        return !(matched && mobile === matched[0]);
    },
    fn_isInValidEmail: (email) => {
        const matched = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        return !(matched && email === matched[0]);
    },
    fn_isInValidDate: (dateAsString) => {
        const splitDate = dateAsString.split('/')
        const date = new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]);
        return !!!date || isNaN(date.valueOf());
    },
    fn_isValidUrl: url => {
        // TODO: FIXE ME i am buggy malformed url are evaluated to be well formed !!!!!
        const webPrefix = 'http://';
        let isValidUrl;
        if (url.indexOf(webPrefix) === -1){
            url = webPrefix + url;
        }
        try {
            isValidUrl = Boolean(new URL(url));
        }
        catch(e){
            isValidUrl = false;
        }
        return isValidUrl;
    },
    fn_isValidSiret: siret => {
        const matched = siret.match(/^[0-9]{14,14}$/)
        return matched && siret === matched[0];
    },
    fn_isValidSiren: siren => {
        const matched = siren.match(/^[0-9]{9,9}$/)
        return matched && siren === matched[0];
    },
    fn_isValidVat: vat => {
        let value = vat.substring(2);
        const matched = value.match(/^[0-9]{11,11}$/)
        return vat.startsWith('FR') && matched && value === matched[0];
    },
    fn_getBankAccountComponents: (rib) => {
        if (isEmpty(rib))
            return {};
        return {
            code: rib.substring(0, 5),
            office: rib.substring(5, 10),
            accountNumber: rib.substring(10, 21),
            ribKey: rib.substring(21, 23)
        };
    },
    fn_buildPersonDetails : buildPersonDetails,
    fn_mergeGuarantorsAndEmergencyContacts: mergeGuarantorsAndEmergencyContacts,
    fn_mergeTenantDocumentsForDisplay: mergeTenantDocumentsForDisplay,
    getHabitableRealEstateTypes: () => {
        return ['HOUSE', 'APARTEMENT', 'COMMERCIAL_SPACE', 'CASTEL', 'OFFICE']
    },
    doesPasswordMatchExpectedPattern:doesPasswordMatchExpectedPattern,
    addBasicAutorizationHeader: cfg => addBasicAutorizationHeader(cfg),
    addBearerAuthorizationHeader: (cfg) => addBearerAuthorizationHeader(cfg),
    getDefaultRequestHeaders: getDefaultRequestHeaders(),
    addQueryParameters: (url, paramsObj) => addQueryParameters(url, paramsObj),
    addFormDateAsQueryParams: (url, paramsObj) => addFormDateAsQueryParams(url, paramsObj),
    formDataToJsonObject: formDataObj => formDataToJsonObject(formDataObj),
    addCi30DelegationPolicyToClient: config => addCi30DelegationPolicyToClient(config),
    addCi30DelegationPolicyToAgent: config => addCi30DelegationPolicyToAgent(config),
    replaceAccentedChars: textToReplace => replaceAccentedChars(textToReplace),
    getHumanReadableAddress: address => getHumanReadableAddress(address),
    hasWhiteSpace: content => hasWhiteSpace(content),
}
export default utils;