import React, {Component} from 'react';
import "./Account.css";

class Individual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountDetails:{},
        }
    }

    componentDidMount() {
    }

    render() {
        return (

            <>
                <div className="section-title">Informations Personnelles</div>

                <div className="row g-3 mb-3">
                    <div className="col-md-3 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Civilité:</h6>
                            <p className="fs-0 mb-0"> {this.props.accountDetails.gender}</p>
                        </div>
                    </div>
                    <div className="col-md-5 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Prénoms:</h6>
                            <p className="fs-0 mb-0"> {this.props.accountDetails.surname}</p>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Nom de famille:</h6>
                            <p className="fs-0 mb-0"> {this.props.accountDetails.firstName}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-4 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Date de naissance:</h6>
                            <p className="fs-0 mb-0"> {this.props.accountDetails.birthDate}</p>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Lieu de naissance:</h6>
                            <p className="fs-0 mb-0"> {this.props.accountDetails.placeOfBirth}</p>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Nationalité:</h6>
                            <p className="fs-0 mb-0"> {this.props.accountDetails.nationality}</p>
                        </div>
                    </div>
                </div>

            </>
        );
    };
}

export default Individual;
