import React, {Component} from 'react';
import "../UploadImagePreview.css";
import utils from "../utils";
import constants from "../../constants";
import {isEmpty} from "lodash";

class ImageFromBytes extends Component{

    constructor(props) {
        super(props);
        this.imagePreviewRef = React.createRef();
    }

    componentDidMount() {
        const url = !!!this.props.url?constants.restApiEndpoints.REALESTATE_PICTURE:this.props.url;
        const pictureUrl = utils.proxify(url) + this.props.resourceUuid
        this.imagePreviewRef.current.src = pictureUrl;
        this.imagePreviewRef.current.alt = this.props.name;
        this.imagePreviewRef.current.title = this.props.name;
        //TODO: This is just a dirty fix to avoid galery containing image with url with undefined value, implement a real  fix  by adding the image element only when component is really ready
        if (-1 === pictureUrl.indexOf('undefined')){
            this.props.gallery.insertSlide(this.addElementToGligthBoxGallery(pictureUrl));
        }
    }

    addElementToGligthBoxGallery = (url) => {
        return {
            href: url,
            type: 'image',
        }
    }

    openInGligthBoxGallery = () => {
        this.props.gallery.openAt(this.props.index);
    }

    delete = () => {
        this.props.delete(this.props.resourceUuid);
    }

    render() {
        return (
            <>
                <div className="upload-image-preview-container">
                    <img
                        src="#"
                        ref={this.imagePreviewRef}
                        className="upload-image-preview"
                        style={isEmpty(this.props.styles)?{cursor:'pointer'}:this.props.styles}
                        onClick={this.openInGligthBoxGallery}
                        alt="" />

                        <div className="row">
                            <div className="col-md-10" style={{display:this.props.readOnly?'none':''}}>
                                <a
                                    className="btn btn-falcon-danger d-block"
                                    onClick={this.delete}
                                    href="#!"
                                    style={{cursor:'pointer'}}
                                >Delete</a>
                            </div>
                            <div className="col-md-2" style={{paddingLeft: '0px', paddingTop: '10px', cursor:'pointer'}}>
                                <a href="#!"
                                   onClick={this.openInGligthBoxGallery}
                                   style={{cursor:'pointer'}}
                                >
                                    <i
                                        className='fas fa-expand-arrows-alt'
                                        style={{cursor:'pointer'}}
                                    >&nbsp;</i>
                                </a>
                            </div>
                        </div>
                </div>
            </>
        );
    };
}
export default ImageFromBytes;
