import React, {Component} from 'react';
import utils from "../utils";

class DocumentsPreviewRow extends Component{

    constructor(props) {
        super(props);
        this.state = {
            document: this.props.document,
        }
    }

    componentDidMount() {
    }

    delete = () => {
        this.props.delete();
    }

    download = () => {
        this.props.download();
    }

    render() {
        return (

            <>
                <tr>
                    <td>{utils.i18n(this.state.document.type, 'en' )}</td>
                    <td>{this.state.document.description}</td>
                    <td>
                        <a onClick={() => this.download()} href="#" download="">
                        <i className="fa fa-download"></i>
                        {(this.state.document.metaData && this.state.document.metaData.fileName)?this.state.document.metaData.fileName:''}
                    </a></td>
                    <td>{this.state.document.uploaddate}</td>
                    <td>
                        <span onClick={() => this.delete()} style={{cursor: 'pointer'}}>
                            <i className="fa fa-trash text-danger"/>
                        </span>
                    </td>
                </tr>
            </>
        );
    };
}
export default DocumentsPreviewRow;