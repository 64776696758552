import React, {Component} from 'react';
import {isEmpty} from "lodash";
import SigninController from "./SigninController";

class PasswordRenew extends Component {

    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.signinController =  new SigninController();
        this.state = {
            isInValidEmail:false,
            isFailedPasswordRenew:false,
            isSucceedPasswordRenew:false,
            isDisabledSubmitButton:true,
            failedPasswordRenewMessage:'',
        };
    }

    isInValidEmail = () => {
        return isEmpty(this.emailRef.current.value)
    }

    validateUsername = () => {
        this.setState({isInValidEmail: this.isInValidEmail()});
        this.disableSubmitButton();
    }

    hideInvalidEmailMessage = () => {
        this.setState({isInValidEmail: false, isFailedPasswordRenew:false, isSucceedPasswordRenew:false});
    }

    disableSubmitButton = () => {
        this.setState({isDisabledSubmitButton: this.isInValidEmail()});
    }

    handlePasswordRenewSubmit = (event) => {
        event.preventDefault();
        this.hideInvalidEmailMessage()
        const formData = new FormData(event.target);
        this.signinController
            .renewPassword(formData)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.text().then((data) => {
                            console.log("Password renew request succeeded !!");
                            this.setState({isSucceedPasswordRenew: true});
                        });
                    } else if(422 === response.status){
                        //TODO : handle 422 use case
                    } else {
                        console.log("Password renew request failed with 500!!");
                        this.setState({isFailedPasswordRenew: true, failedPasswordRenewMessage:'An issue occured while requesting password renewal'});
                    }
                },
                (error) => {
                    console.log("Error occurred during the call", error);
                    this.setState({isFailedPasswordRenew: true, failedPasswordRenewMessage:'An issue occured while requesting password renewal'});
                }
            );

    }

    componentDidMount(){
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row min-vh-100 bg-100">
                        <div className="col-6 d-none d-lg-block position-relative">
                            <div className="bg-holder overlay"
                                 style={{backgroundImage:'url(../../../assets/img/generic/17.jpg)', backgroundPosition: '50% 76%'}}>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                            <div className="row justify-content-center g-0">
                                <div className="col-lg-9 col-xl-8 col-xxl-6">
                                    <p style={{display: this.state.isFailedPasswordRenew?'':'none'}} className="failed-signin-message" >{this.state.failedPasswordRenewMessage}</p>
                                    <p style={{display: this.state.isSucceedPasswordRenew?'':'none'}} className="succeed-signup-message" >Password renew requested</p>
                                    <div className="card">
                                        <div className="card-header bg-circle-shape bg-shape text-center p-2"><a
                                            className="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light"
                                            href="../../../index.html">CI3.0</a></div>
                                        <div className="card-body p-4">
                                            <div className="text-center">
                                                <h4 className="mb-0"> Forgot your password?</h4><small>Enter your email
                                                and we'll send you a reset link.</small>
                                                <form onSubmit={this.handlePasswordRenewSubmit} className="mb-3 mt-4">
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        placeholder="Email address"
                                                        name="email"
                                                        ref={this.emailRef}
                                                        onBlur={this.validateUsername}
                                                        onFocus={this.hideInvalidEmailMessage}
                                                    />
                                                    <p style={{display: this.state.isInValidEmail?'block':'none'}} className="mb-3 invalid-input-message">Invalid email</p>
                                                    <div className="mb-3"></div>
                                                    <button
                                                        className="btn btn-primary d-block w-100 mt-3"
                                                        type="submit"
                                                        disabled={this.state.isDisabledSubmitButton}
                                                            name="submit">Send reset link
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default PasswordRenew;
