import React, {Component} from 'react';
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import "../UploadImagePreview.css";
import TenantController from "./TenantController";
import utils from "../utils";
import constants from "../../constants";
import {isEmpty} from "lodash";
import TenantDetailsPersonsPreview from "./TenantDetailsPersonsPreview";
import TenantDetailsDocumentsPreview from "./TenantDetailsDocumentsPreview";
import {Link} from "react-router-dom";
import ImageFromBytes from "../bien/ImageFromBytes";

class CorporationTenantDetails extends Component{

    constructor(props) {
        super(props);
        this.tenantController = new TenantController();
        this.gallery = utils.getGligthBoxGallery();

        this.state = {
            tenantDetails: {},
            guarantorsAndEmergencyContacts: [],
            documents: [],
            bankAccountComponents: {},

        }
    }


    componentDidMount() {
        this.fetchTenantDetails();
    }

    fetchTenantDetails = () => {
        this.tenantController.getCorporationTenantDetails(this.props.match.params.tenantUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            const bankAccountComponents = this.getBankAccountComponents(data);
                            this.setState({
                                tenantDetails: data,
                                documents:utils.fn_mergeTenantDocumentsForDisplay(data),
                                guarantorsAndEmergencyContacts:utils.fn_mergeGuarantorsAndEmergencyContacts(data.guarantors, data.emergencyContacts),
                                bankAccountComponents:bankAccountComponents});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        response.json().then((data) => {
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No Tenant estate with id : ' + this.props.match.params.tenantUuid + ' found.'})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }

    getBankAccountComponents = (data) => {
        if(isEmpty(data.person) || isEmpty(data.person.bankAccounts))
            return {};
        return utils.fn_getBankAccountComponents(data.person.bankAccounts[0].rib);
    }

    downloadFile = (resourceUuid, fileName) => {
        const url = utils.proxify(constants.restApiEndpoints.FILE_DATA) + resourceUuid;
        this.tenantController.getFileAsByte(url)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.blob().then((data) => {
                            const downloadUrl = URL.createObjectURL(data);
                            const link = document.createElement("a");
                            link.href = downloadUrl;
                            link.download = fileName;
                            link.click();
                            URL.revokeObjectURL(downloadUrl);
                        });
                    } else {
                        return undefined;
                    }
                },
                (error) => {
                    return undefined;
                }
            );
    }

    displayTenantPicture = (filedata) => {
        const gallery = this.gallery;
        const url = constants.restApiEndpoints.FILE_DATA;
        return <ImageFromBytes url={url} gallery={gallery}
                               key={filedata.label}
                               name={filedata.label}
                               resourceUuid={filedata.resourceUuid}
                               index={0}
                               readOnly={true}
                               styles={{cursor:'pointer', height: '200px', width: '132px'}}
        />
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar history={this.props.history}/>

                        <div className="card mb-3">
                            <div className="card-header bg-light">
                                <div className="row">
                                    <div className="col-sm-1">
                                        <h3><a href="#"><i className="fas fa-chevron-left"></i></a>
                                        </h3>
                                    </div>
                                    <div className="col-sm-10">
                                        <h3><span style={{textAlign: 'left'}}>Dossier Locataire  </span> <span
                                            style={{float:'right'}}>{isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.name}</span></h3>
                                    </div>
                                    <div>
                                        <div className="dropdown btn-reveal-trigger position-absolute top-0 end-0 m-3">
                                            <button
                                                className="btn btn-link btn-reveal text-600 btn-sm dropdown-toggle dropdown-caret-none"
                                                type="button" id="studentInfoDropdown" data-bs-toggle="dropdown"
                                                data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                                                <h3><span className="fas fa-ellipsis-h fs--2"></span></h3>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end border py-2"
                                                 aria-labelledby="studentInfoDropdown">
                                                <Link  href="#" className="dropdown-item" to={utils.contextualizePath(isEmpty(this.state.tenantDetails)?'#':('/tenant/corporation/' + this.state.tenantDetails.resourceUuid + '/edit'))}>Editer</Link>
                                                <Link  href="#" className="dropdown-item" to={utils.contextualizePath('/underconstruction')}>Supprimer</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0 ">
                                <div className="d-flex align-items-center px-x1 py-2 border-bottom border-200">
                                    <div className="card-body bg-light">
                                        <div className="row g-4 text-center text-md-start">
                                            <div className="col-md-auto">
                                                <div className="avatar avatar-4xl">
                                                    <label>Type : Entreprise</label>
                                                    {isEmpty(this.state.tenantDetails.picture)?'':this.displayTenantPicture(this.state.tenantDetails.picture)}
                                                </div>
                                            </div>
                                            <div className="col table-responsive">
                                                <table className="table table-sm">
                                                    <tbody>
                                                    <tr>
                                                        <th style={{width:'40%'}}>Dénomination</th>
                                                        <td>{isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Forme Juridique</th>
                                                        <td>{isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.legalStatus}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Capital social :</th>
                                                        <td>{isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.shareCapital} €</td>
                                                    </tr>
                                                    <tr>
                                                        <th>SIREN / SIRET</th>
                                                        <td>{isEmpty(this.state.tenantDetails.person)?'':(this.state.tenantDetails.person.siren + ' / ' + this.state.tenantDetails.person.siret)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Numéro de TVA</th>
                                                        <td>{isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.vatNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Domaine d'activité</th>
                                                        <td>{isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.activityField}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Site web</th>
                                                        <td>{isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.website}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center px-x1 py-2 border-bottom border-200">
                                <fieldset>
                                    <legend>
                                        <i className="far fa-building text-primary"></i>&nbsp; Informations de contact
                                    </legend>
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <tbody>
                                            <tr>
                                                <th>Interlocuteur</th>
                                                <td>{isEmpty(this.state.tenantDetails.representative)?'':
                                                    this.state.tenantDetails.representative.firstName + ' '
                                                    + this.state.tenantDetails.representative.surname}</td>
                                            </tr>
                                            <tr>
                                                <th style={{width:'50%'}}>Tel mobile</th>
                                                <td>{(isEmpty(this.state.tenantDetails.representative) || isEmpty(this.state.tenantDetails.representative.contacts))?'':
                                                    this.state.tenantDetails.representative.contacts[0].mobile}</td>
                                            </tr>
                                            <tr>
                                                <th>Tel Fixe</th>
                                                <td>{(isEmpty(this.state.tenantDetails.representative) || isEmpty(this.state.tenantDetails.representative.contacts))?'':
                                                    this.state.tenantDetails.representative.contacts[0].phone}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{(isEmpty(this.state.tenantDetails.representative) || isEmpty(this.state.tenantDetails.representative.contacts))?'':
                                                    this.state.tenantDetails.representative.contacts[0].email}</td>
                                            </tr>
                                            <tr>
                                                <th>Email secondaire</th>
                                                <td>{(isEmpty(this.state.tenantDetails.representative) || isEmpty(this.state.tenantDetails.representative.contacts))?'':
                                                    this.state.tenantDetails.representative.contacts[0].secondEmail}</td>
                                            </tr>
                                            <tr>
                                                <th>Adresse</th>
                                                <td>{(isEmpty(this.state.tenantDetails.representative) || isEmpty(this.state.tenantDetails.representative.contacts))?'':
                                                    this.state.tenantDetails.representative.contacts[0].address}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="d-flex align-items-center px-x1 py-2 border-bottom border-200">
                                <fieldset style={{width:': 100%'}}>
                                    <legend><i className="far fa-credit-card text-primary"></i> Informations bancaires
                                    </legend>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <th>
                                                    <a className="btn btn-falcon-default mt-2" data-bs-toggle="collapse"
                                                       href="#bankDetailsCollapse" role="button"
                                                       aria-expanded="false" aria-controls="bankDetailsCollapse">
                                                        <i className="fas fa-info-circle text-primary"></i>
                                                    </a> &nbsp;
                                                    Banque
                                                </th>
                                                <th>Iban</th>
                                                <th>BIC</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {(isEmpty(this.state.tenantDetails.person) || isEmpty(this.state.tenantDetails.person.bankAccounts))?'':
                                                        this.state.tenantDetails.person.bankAccounts[0].bankName}
                                                </td>
                                                <td>{(isEmpty(this.state.tenantDetails.person) || isEmpty(this.state.tenantDetails.person.bankAccounts))?'':
                                                    this.state.tenantDetails.person.bankAccounts[0].iban}</td>
                                                <td>{(isEmpty(this.state.tenantDetails.person) || isEmpty(this.state.tenantDetails.person.bankAccounts))?'':
                                                    this.state.tenantDetails.person.bankAccounts[0].bic}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="collapse" id="bankDetailsCollapse">
                                        <div className="border p-x1 rounded" style={{width:'100%'}}>
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <tbody>
                                                    <tr>
                                                        <th colSpan="2">Coordonnées bancaires</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{width:'20%'}}>Code banque</th>
                                                        <td>{this.state.bankAccountComponents.code}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Code guichet</th>
                                                        <td>{this.state.bankAccountComponents.office}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>N° compte</th>
                                                        <td>{this.state.bankAccountComponents.accountNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Clé</th>
                                                        <td>{this.state.bankAccountComponents.ribKey}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Adresse banque</th>
                                                        <td>{(!isEmpty(this.state.tenantDetails.person) && !isEmpty(this.state.tenantDetails.person.bankAccounts))?
                                                            this.state.tenantDetails.person.bankAccounts[0].address:''}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="d-flex align-items-center px-x1 py-2 border-bottom border-200">
                                <fieldset style={{width:'100%'}}>
                                    <legend><i className="fas fa-users text-primary"></i> Garants et contacts d'urgences
                                    </legend>
                                    <div className="table-responsive">
                                        {
                                            isEmpty(this.state.guarantorsAndEmergencyContacts)?'':
                                                <TenantDetailsPersonsPreview persons={this.state.guarantorsAndEmergencyContacts} />
                                        }
                                    </div>
                                </fieldset>
                            </div>
                            <div className="d-flex align-items-center px-x1 py-2">
                                <fieldset style={{width:'100%'}}>
                                    <legend><i className="far fa-folder-open text-primary"></i> Documents</legend>
                                    <div className="table-responsive">
                                        {
                                            isEmpty(this.state.documents)? '':
                                                <TenantDetailsDocumentsPreview
                                                    documents={this.state.documents}
                                                    download={this.downloadFile}
                                                />
                                        }
                                    </div>
                                </fieldset>
                            </div>
                            <div className="d-flex align-items-center px-x1 py-2">
                                <fieldset className="col-md-12" style={{width:'100%'}}>
                                    <legend><i className="far fa-comments text-primary"></i> Note privée</legend>
                                    <span><i>{this.state.tenantDetails.privateComment}</i></span>
                                    <textarea className="form-control-plaintext" disabled id="privateNote"
                                              name="privateNote" placeholder=""></textarea>
                                </fieldset>
                            </div>
                            <div className="d-flex align-items-center px-x1 py-2">
                                <fieldset className="col-md-12" style={{width:'100%'}}>
                                    <legend><i className="far fa-address-book text-primary"></i> Informations de Départ
                                    </legend>
                                    <span><i>{this.state.tenantDetails.followupAddress}</i></span><br/>
                                    <div className="mb-3"><label className="form-label" htmlFor="privateNote"></label>
                                        <textarea className="form-control-plaintext" id="exampleFormControlTextarea1"
                                                  readOnly></textarea>
                                    </div>
                                </fieldset>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        );
    };
}
export default CorporationTenantDetails;