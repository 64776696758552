import React, {Component} from 'react';

class ConfirmationBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            details:{}
        };
    }

    showModal = (details) => {
        this.setState({details: details});
        window.jQuery('#confirmationBox').modal("show");
    }

    hideModal = () => {
        window.jQuery('#confirmationBox').modal("hide");
    }

    executeConfirmationAction = () => {
        const promiseAction = new Promise((resolve, reject) => {
            resolve(this.state.details.action());
        });

        promiseAction.then((value) => {
            this.hideModal();
        });
    }

    render() {
        return (
            <>
                <div className="modal fade" id="confirmationBox" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document" >
                        <div className="modal-content position-relative">
                            <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                                <button
                                    className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="row justify-content-center g-0">
                                    <div className="card">
                                        <div className="card-header bg-circle-shape bg-shape text-center p-2"><a
                                            className="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light"
                                            href="../../../index.html" onClick={this.hideModal}>CI3.0</a></div>
                                        <div className="card-body p-4">
                                            <div className="row g-3 mb-3">
                                                <div className="col-md-12 ">
                                                    <h5>{this.state.details.message}</h5>
                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <div className="col-md-2 "></div>
                                                <div className="col-md-4 ">
                                                    <button className="btn btn-danger d-block w-100 mt-3"
                                                            onClick={this.executeConfirmationAction}
                                                            name="delete"
                                                    >Delete</button>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <button className="btn btn-secondary d-block w-100 mt-3"
                                                            onClick={this.hideModal}
                                                            name="cancel"
                                                    >Cancel</button>
                                                </div>
                                                <div className="col-md-2 "></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default ConfirmationBox;
