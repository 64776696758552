import React, {Component} from 'react';
import "../Home.css";
import {Link} from "react-router-dom";
import SignupController from "./SignupController";
import utils from "../utils";

class SignupResult extends Component {

    constructor(props) {
        super(props);
        this.signupController = new SignupController()
        this.state = {
            isEmailResendFailed:false,
            resultMessage:'',
            result:''
        };
    }

    componentDidMount() {
        if('error'!== this.props.match.params.result && 'success' !== this.props.match.params.result){
            this.props.history.push(utils.contextualizePath('/'));
        }
        this.setState({
            result: this.props.match.params.result,
            resultMessage:'success' === this.props.match.params.result?'You\'ve successfully subscribed! A validation mail is sent to you to activate your account.'
                :'Sounds like youre not the only one fun of this username, please choose another one'
        });
    }

    retrySubscription = () => {
        this.props.history.push(utils.contextualizePath('/signup'));
    }

    resendSubscriptionEmail = (event) => {
        this.setState({isSucceedSignup: false});
        event.preventDefault();
        this.signupController.resendSubscriptionEmail(this.props.match.params.subscriptionResourceUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        console.log("Subscription email resent succeeded !!");
                        this.setState({
                            result: 'success',
                            resultMessage: 'Activation email successfully sent'
                        });
                    } else {
                        response.json().then((data) => {
                            this.setState({
                                result: 'error',
                                resultMessage: 'Error occurred while resending activation email',
                                isEmailResendFailed:true
                            });
                        });
                    }
                },
                (error) => {
                    console.log("Error occurred during the call", error);
                    this.setState({
                        result: 'error',
                        resultMessage: 'Error occurred while resending activation email',
                        isEmailResendFailed:true
                    });
                }
            )

    }

    render() {
        return (

            <>
                <div className="container-fluid">
                    <div className="row min-vh-100 bg-100">
                        <div className="col-6 d-none d-lg-block position-relative">
                            <div className="bg-holder"
                                 style={{backgroundImage: 'url(../../../assets/img/generic/19.jpg)'}}>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                            <div className="row justify-content-center g-0">
                                <div className="col-lg-9 col-xl-8 col-xxl-6">
                                    <div>
                                        <h3 className={'success' === this.state.result ?'succeed-signup-message':'failed-signin-message'}>
                                            {this.state.resultMessage}
                                        </h3>
                                        <h3 style={{textAlign:'center'}}>
                                            <Link onClick={'success' === this.state.result || this.state.isEmailResendFailed ? this.resendSubscriptionEmail:this.retrySubscription}>
                                                {'success' === this.state.result || this.state.isEmailResendFailed ? 'Resend email':'Retry'}</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default SignupResult;
