import React, {Component} from 'react';
import {isEmpty} from "lodash";

class TenantDetailsDocumentsPreview extends Component{

    constructor(props) {
        super(props);
        this.state = {
            documents: isEmpty(this.props.documents)?[]:this.props.documents,
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th></th>
                        <th>Numéro</th>
                        <th>Description</th>
                        <th>Date d'expiration</th>
                        <th>Date d'ajout</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        this.state.documents.map((document, index) => {
                            return <tr key={index}>
                                <td>
                                    {document.type}
                                </td>
                                <td>
                                    <a onClick={() => this.props.download(document.fileDataUuid, document.fileName)} href="#" download=""><i className="fa fa-download"/></a>
                                </td>
                                <td>{document.number}</td>
                                <td>{document.description}</td>
                                <td>{document.expirationDate}</td>
                                <td>{document.uploadDate}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </>
        );
    };
}
export default TenantDetailsDocumentsPreview;