import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";
import AuthService from "./components/AuthService";
import utils from "./components/utils";
import "./components/profile/Profile.css";
import message from "./components/alertMessage";
import Signin from "./components/signin/Signin";
import Home from "./components/Home";
import NotFoundPage from "./components/NotFoundPage";
import Signup from "./components/signup/Signup";
import PasswordRenew from "./components/signin/PasswordRenew";
import PasswordReset from "./components/signin/PasswordReset";
import Dashboard from "./components/Dashboard";
import UnderConstruction from "./components/UnderConstruction";
import ToastAlertMessage from "./components/ToastAlertMessage"
import Subscription from "./components/signup/Subscription";
import RealEstate from "./components/bien/RealEstate";
import RealEstateDetails from "./components/bien/RealEstateDetails";
import RealEstateEdit from "./components/bien/RealEstateEdit";
import RealEstateOverview from "./components/bien/RealEstateOverview";
import Resignin from "./components/signin/Resignin";
import SignupResult from "./components/signup/SignupResult";
import AccountEdit from "./components/account/AccountEdit";
import AccountDetails from "./components/account/AccountDetails";
import ConfirmationBox from "./components/ConfirmationBox";
import TenantOverview from "./components/tenant/TenantOverview";
import Tenant from "./components/tenant/Tenant";
import IndividualTenantDetails from "./components/tenant/IndividualTenantDetails";
import CorporationTenantDetails from "./components/tenant/CorporationTenantDetails";

class App extends Component {

    constructor(props) {
        super(props);
        this.toastMessagesContainersRef = React.createRef();
        this.resigninRef = React.createRef();
        this.confirmBoxRef = React.createRef();
        this.state = {
            isAuthenticated: AuthService.isSignedIn(),
            isLandingPageRedirect: !AuthService.isSignedIn() || createBrowserHistory().location.pathname === utils.contextualizePath('/'),
            profileCreationStep: -1,
            isSuccessAction: false,
            successMessage: '',
            isErrorAction: false,
            errorMessage: '',
            isWarningAction: false,
            warningMessage: '',
            alertMessages: [],
            toastMessages: []
        };
    }

    componentDidMount() {
        const isFluid = JSON.parse(localStorage.getItem('isFluid'));
        if (isFluid) {
            let container = document.querySelector('[data-layout]');
            container.classList.remove('container');
            container.classList.add('container-fluid');
        }
    }

    showReSigninModal = () => {
        this.resigninRef.current.showModal();
    }

    showConfirmationBox = (details) => {
        this.confirmBoxRef.current.showModal(details);
    }

    addToastMessage = (...toastMessages) => {
        let messages = this.state.toastMessages;
        for (let i = 0; i < toastMessages.length; i++) {
            messages.push(toastMessages[i]);
        }
        this.setState({toastMessages: messages});
        this.scrollToToastMessages();
    }

    isAuthenticated = () => {
        return AuthService.isSignedIn();
    }

    handle40x = (errorCode) => {
        this.showAlertMessage({
            type: 'error', msg: message.getFr(errorCode), isDomElement: false
        });
    }

    handle20x = (successCode) => {
        this.showAlertMessage({
            type: 'success', msg: message.getFr(successCode), isDomElement: false
        });
    }

    handleUnexpectedError = () => {
        this.showAlertMessage({
            type: 'error', msg: message.getFr('ERR_GEN_UNEXPECTED'), isDomElement: false
        });
    }

    scrollToToastMessages = () => {
        const top = this.toastMessagesContainersRef.current.offsetTop
        console.log('Toast mesages container top is : ', top);
        window.scrollTo({top: top, behavior: 'smooth'});
    }

    render() {
        return (
            <BrowserRouter>
                <div ref={this.toastMessagesContainersRef}
                     className="position-absolute top-0 end-0 toast-messages-container" aria-live="polite"
                     aria-atomic="true">
                    {
                        this.state.toastMessages.map((msg, index) =>
                            (<ToastAlertMessage key={index} type={msg.type} title={msg.title}
                                                sendingTime={msg.sendingTime} message={msg.message}/>)
                        )
                    }
                </div>

                <Resignin ref={this.resigninRef}/>
                <ConfirmationBox ref={this.confirmBoxRef}/>
                <Switch>
                    <Route
                        exact
                        path={utils.contextualizePath("/")}
                        render={props => (<Home
                            {...props}
                            addToastMessage={(msg) => this.addToastMessage(msg)}
                            isAuthenticated={this.isAuthenticated}
                            showReSigninModal={this.showReSigninModal}
                            showConfirmationBox={(details) => this.showConfirmationBox(details)}
                        />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/dashboard")}
                           render={props => (<Dashboard
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/signin")}
                           render={props => (<Signin
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/signup")}
                           render={props => (<Signup
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/signup-result/:subscriptionResourceUuid/:result")}
                           render={props => (<SignupResult
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/password/renew")}
                           render={props => (<PasswordRenew
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/password/:passwordRequestUuid/reset")}
                           render={props => (<PasswordReset
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/underconstruction")}
                           render={props => (<UnderConstruction
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/subscription/:subscriptionUuid/validate")}
                           render={props => (<Subscription
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/bien/new")}
                           render={props => (<RealEstate
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/bien")}
                           render={props => (<RealEstateOverview
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/bien/:realestateUuid/details")}
                           render={props => (<RealEstateDetails
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />
                    <Route exact
                           path={utils.contextualizePath("/bien/:realestateUuid/edit")}
                           render={props => (<RealEstateEdit
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/account/edit")}
                           render={props => (<AccountEdit
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/account")}
                           render={props => (<AccountDetails
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/tenant")}
                           render={props => (<TenantOverview
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/tenant/new")}
                           render={props => (<Tenant
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/tenant/individual/:tenantUuid/details")}
                           render={props => (<IndividualTenantDetails
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/tenant/corporation/:tenantUuid/details")}
                           render={props => (<CorporationTenantDetails
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/tenant/individual/:tenantUuid/edit")}
                           render={props => (<Tenant
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route exact
                           path={utils.contextualizePath("/tenant/corporation/:tenantUuid/edit")}
                           render={props => (<Tenant
                               {...props}
                               addToastMessage={(msg) => this.addToastMessage(msg)}
                               isAuthenticated={this.isAuthenticated}
                               showReSigninModal={this.showReSigninModal}
                               showConfirmationBox={(details) => this.showConfirmationBox(details)}
                           />)}
                    />

                    <Route path={utils.contextualizePath("/404")} component={NotFoundPage}/>
                    <Redirect to={utils.contextualizePath("/404")}/>
                </Switch>
            </BrowserRouter>
        );
    };
}

export default App;
