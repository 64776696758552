import TenantService from "./TenantService";
import CommonService from "../CommonService";

class TenantController {

    constructor() {
        this.tenantService = new TenantService();
        this.commonService = new CommonService();
    }

    getUserTenants() {
        return this.tenantService.getUserTenants();
    }

    getIndividualTenantDetails = (resourceUuid) => {
        return this.tenantService.getIndividualTenantDetails(resourceUuid);
    }

    getCorporationTenantDetails = (resourceUuid) => {
        return this.tenantService.getCorporationTenantDetails(resourceUuid);
    }

    createIndividualTenant = (data) => {
        return this.tenantService.createIndividualTenant(data);
    }

    updateIndividualTenant = (resourceUuid, data) => {
        return this.tenantService.updateIndividualTenant(resourceUuid, data);
    }

    createCorporationTenant = (data) => {
        return this.tenantService.createCorporationTenant(data);
    }

    updateCorporationTenant = (resourceUuid, data) => {
        return this.tenantService.updateCorporationTenant(resourceUuid, data);
    }

    getLegalStatusesLike = () => {
        return this.commonService.getCompanyLegalStatusesLike('');
    }

    getCountriesLike = (input) => {
        return this.commonService.getCountriesLike(input);
    }

    getIdentificationDocumentTypes = () => {
        return this.commonService.getIdentificationDocumentTypes();
    }

    getDocumentTypes = () => {
        return this.commonService.getDocumentTypes();
    }

    getContractTypes = () => {
        return this.commonService.getContractTypes();
    }

    getFileAsByte = (url) => {
        return this.commonService.getFileAsByte(url);
    }

}
export default TenantController;