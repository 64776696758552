import React, {Component} from 'react';
import "./Account.css";
import utils from "../utils";
import {isEmpty} from "lodash";

class ContactEdit extends Component {

    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.mobileRef = React.createRef();
        this.phoneRef = React.createRef();
        this.addressRef = React.createRef();
        this.state = {
            isInvalidEmail:false,
            isInvalidMobile:false,
            isInvalidPhone:false,
            isInvalidAddress:false,
        }
    }

    componentDidMount() {
    }

    validateEmail = () => {
        this.setState({isInvalidEmail: this.isInValidEmail()});
        this.props.disableParentSubmitButton();
    }

    isInValidEmail = () => {
        return isEmpty(this.emailRef.current.value) || utils.fn_isInValidEmail(this.emailRef.current.value)
    }

    hideInvalidEmailMessage = () => {
        this.setState({isInvalidEmail: false});
        this.props.disableParentSubmitButton();
    }

    validateMobile = () => {
        this.setState({isInvalidMobile: this.isInValidMobile()});
        this.props.disableParentSubmitButton();
    }

    isInValidMobile = () => {
        return isEmpty(this.mobileRef.current.value) || utils.fn_isInValidMobile(this.mobileRef.current.value)
    }

    hideInvalidMobileMessage = () => {
        this.setState({isInvalidMobile: false});
        this.props.disableParentSubmitButton();
    }

    validatePhone = () => {
        this.setState({isInvalidPhone: this.isInValidPhone()});
        this.props.disableParentSubmitButton();
    }

    isInValidPhone = () => {
        return !!!isEmpty(this.phoneRef.current.value) && utils.fn_isInValidTel(this.phoneRef.current.value)
    }

    hideInvalidPhoneMessage = () => {
        this.setState({isInvalidPhone: false});
        this.props.disableParentSubmitButton();
    }

    containsAtLeastOneInvalidField = () => {
        console.log("containsAtLeastOneInvalidField", this.isInValidEmail())
        return this.isInValidEmail() || this.isInValidMobile() || this.isInValidPhone();
    }

    getContactData = () => {
        return {
            email:this.emailRef.current.value,
            mobile:this.mobileRef.current.value,
            phone:this.phoneRef.current.value,
            address:this.addressRef.current.value,
        }
    }

    render() {
        return (

            <>
                <div className="row g-3 mb-3">
                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Adresse email:</label>
                            <input className="form-control form-select-lg"
                                   id="email"
                                   name="email"
                                   ref={this.emailRef}
                                   onBlur={this.validateEmail}
                                   onFocus={this.hideInvalidEmailMessage}
                                   defaultValue={this.props.contact.email}
                                   placeholder="jean.dupont@gmail.com"/>
                            <p style={{display: this.state.isInvalidEmail?'':'none'}} className="failed-signin-message" >Invalid email address</p>
                        </div>
                    </div>

                    <div className="col-md-3 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Numéro de mobile :</label>
                            <input className="form-control form-select-lg"
                                   id="mobile"
                                   name="mobile"
                                   ref={this.mobileRef}
                                   onBlur={this.validateMobile}
                                   onFocus={this.hideInvalidMobileMessage}
                                   defaultValue={this.props.contact.mobile}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidMobile?'':'none'}} className="failed-signin-message" >Invalid mobile number</p>
                        </div>
                    </div>

                    <div className="col-md-3 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Numéro de ligne fixe:</label>
                            <input className="form-control form-select-lg"
                                   id="phone"
                                   name="phone"
                                   ref={this.phoneRef}
                                   onBlur={this.validatePhone}
                                   onFocus={this.hideInvalidPhoneMessage}
                                   defaultValue={this.props.contact.phone}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidPhone?'':'none'}} className="failed-signin-message" >Invalid phone number </p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Adresse:</label>
                            <input className="form-control form-select-lg"
                                   id="address"
                                   name="address"
                                   ref={this.addressRef}
                                   defaultValue={this.props.contact.address}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidAddress?'':'none'}} className="failed-signin-message" >Adress cannot be empty</p>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default ContactEdit;
