import utils from "./utils";
import constants from "../constants";

class AuthService {

    static getAccesToken = () => {
        let rawToken = JSON.parse(this.getRawToken());
        return rawToken['access_token'];
    }

    static getRawToken = () => {
        return localStorage.getItem(constants.common.TOKEN_STORAGE_KEY);
    }

    static isSignedIn = () => {
        const storedToken = this.getRawToken();
        return storedToken !== null && storedToken !== '' && storedToken !== undefined;
    };

    static getDecodedTokenPayload(){
        const payload = this.getRawToken().split('.')[1];
        const base64Token = payload.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64Token));
    }

    static hasOneOf = (...expectedRoles) => {
        const rawToken = this.getRawToken();
        if (rawToken === null || rawToken === '' || rawToken === undefined)
            return false;

        const token = this.getDecodedTokenPayload();
        if(this.isTokenExpired(token))
            return false;
        const roles = token['authorities'];
        // TODO: make sure this is realistic !!! seems like wierd ....
        if(expectedRoles === null || expectedRoles === undefined || expectedRoles.length === 0 || roles.length === 0)
            return false;
        // 'ROLE_GLOBAL_ADMIN' is one of the exempted role, not to check.
        return roles.includes('ROLE_GLOBAL_ADMIN') || expectedRoles.filter(role => roles.includes(role)).length !== 0;
    }

    static hasAllOf = (...expectedRoles) => {
        const rawToken = this.getRawToken();
        if (rawToken === null || rawToken === '' || rawToken === undefined)
            return false;

        const token = this.getDecodedTokenPayload();
        if(this.isTokenExpired(token))
            return false;
        const roles = token['authorities'];
        // TODO: make sure this is realistic !!! seems like wierd ....
        if(expectedRoles === null || expectedRoles === undefined || expectedRoles.length === 0 || roles.length === 0)
            return false;
        return roles.includes('ROLE_GLOBAL_ADMIN') || expectedRoles.filter(role => roles.includes(role)).length === expectedRoles.length;
    }

    static isTokenExpired(decodedToken){
        const expirationDate = decodedToken['exp'] * 1000;
        const isExpired = expirationDate === null || expirationDate === undefined || Date.now() > expirationDate;;
        if(isExpired)
            localStorage.removeItem(constants.common.TOKEN_STORAGE_KEY);
        return isExpired
    }

    static signin = (userData) => {

        let url = utils.proxify(constants.authenticationApiEndpoints.SIGNIN);
        let headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify(utils.formDataToJson(userData))
            });
    };

    static signout = () => {
        return localStorage.removeItem(constants.common.TOKEN_STORAGE_KEY);
    };
}

export default AuthService