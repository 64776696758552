import React, {Component} from 'react';
import "./Account.css";

class BankAccountEdit extends Component {

    constructor(props) {
        super(props);
        this.bankAccountRef = React.createRef();
        this.addressRef = React.createRef();
        this.bankCodeRef = React.createRef();
        this.bankOfficeRef = React.createRef();
        this.bankAccountNumberRef = React.createRef();
        this.ribRef = React.createRef();
        this.ibanRef = React.createRef();
        this.bicRef = React.createRef();

        this.state = {
            isInvalidBankName:false,
            isInvalidBankAddress:false,
            isInvalidBankCode:false,
            isInvalidBankOfficeCode:false,
            isInvalidBankAccountNumber:false,
            isInvalidRib:false,
            isInvalidIban:false,
            isInvalidBicSwift:false,
            bankAccount:{},
            bankCode:'',
            bankOfficeCode:'',
            bankAccountNumber:'',
            rib:''
        }
    }

    componentDidMount() {
        this.setState({bankAccount: this.props.bankAccount, bankCode: this.props.bankAccount.rib.substring(0, 5),
            bankOfficeCode: this.props.bankAccount.rib.substring(5, 10), bankAccountNumber:this.props.bankAccount.rib.substring(10, 21),
            rib:this.props.bankAccount.rib.substring(21, 23)});
    }

    getBankAccountData = () => {
        return {
            bankName: this.bankAccountRef.current.value,
            address: this.addressRef.current.value,
            rib: this.bankCodeRef.current.value + this.bankOfficeRef.current.value
                + this.bankAccountNumberRef.current.value + this.ribRef.current.value,
            iban: this.ibanRef.current.value,
            bic: this.bicRef.current.value
        }
    }


    render() {
        return (

            <>
                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Nom de la banque:</label>
                            <input className="form-control form-select-lg"
                                   id="bankName"
                                   name="bankName"
                                   ref={this.bankAccountRef}
                                   defaultValue={this.state.bankAccount.bankName}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidBankName?'':'none'}} className="failed-signin-message" >Adress cannot be empty</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Addresse de la banque:</label>
                            <input className="form-control form-select-lg"
                                   id="bankAddress"
                                   name="bankAddress"
                                   ref={this.addressRef}
                                   defaultValue={this.state.bankAccount.address}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidBankAddress?'':'none'}} className="failed-signin-message" >Adress cannot be empty</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-2 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Code banque:</label>
                            <input className="form-control form-select-lg"
                                   id="bankCode"
                                   name="bankCode"
                                   ref={this.bankCodeRef}
                                   defaultValue={this.state.bankCode}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidBankCode?'':'none'}} className="failed-signin-message" >Invalid bank code</p>
                        </div>
                    </div>

                    <div className="col-md-2 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Code guichet:</label>
                            <input className="form-control form-select-lg"
                                   id="bankOfficeCode"
                                   name="bankOfficeCode"
                                   ref={this.bankOfficeRef}
                                   defaultValue={this.state.bankOfficeCode}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidBankOfficeCode?'':'none'}} className="failed-signin-message" >Code guichet de banque invalid</p>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Numéro de compte:</label>
                            <input className="form-control form-select-lg"
                                   id="bankAccountNumber"
                                   name="bankAccountNumber"
                                   ref={this.bankAccountNumberRef}
                                   defaultValue={this.state.bankAccountNumber}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidBankAccountNumber?'':'none'}} className="failed-signin-message" >Invalid bank account number</p>
                        </div>
                    </div>

                    <div className="col-md-2 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Clé RIB:</label>
                            <input className="form-control form-select-lg"
                                   id="rib"
                                   name="rib"
                                   ref={this.ribRef}
                                   defaultValue={this.state.rib}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidRib?'':'none'}} className="failed-signin-message" >Invalid bank RIB</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">IBAN:</label>
                            <input className="form-control form-select-lg"
                                   id="iban"
                                   name="iban"
                                   ref={this.ibanRef}
                                   defaultValue={this.state.bankAccount.iban}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidIban?'':'none'}} className="failed-signin-message" >Invalid iban</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">BIC/SWIFT:</label>
                            <input className="form-control form-select-lg"
                                   id="bicSwift"
                                   name="bicSwift"
                                   ref={this.bicRef}
                                   defaultValue={this.state.bankAccount.bic}
                                   placeholder=""/>
                            <p style={{display: this.state.isInvalidBicSwift?'':'none'}} className="failed-signin-message" >Invalid BIC/SWIFT</p>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default BankAccountEdit;
