import utils from "../utils";
import constants from "../../constants";
class SignupService {

    signup = (userData) => {
        let url = utils.proxify(constants.authenticationApiEndpoints.USER);
        let headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: JSON.stringify(utils.formDataToJson(userData))
            });

    }

    resendSubscriptionEmail = (resourceUuid) => {
        let url = utils.proxify(constants.authenticationApiEndpoints.SUBSCRIPTION) + resourceUuid + '/resentEmail';
        let headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
            });
    }

    validateSubscription = (resourceUuid, data) => {
        let url = utils.proxify(constants.authenticationApiEndpoints.SUBSCRIPTION) + resourceUuid + '/validate';
        let headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: JSON.stringify(utils.formDataToJson(data))
            });
    }

    regenerateSubscriptionValidationLink = (formData) => {
        let url = utils.proxify(constants.authenticationApiEndpoints.SUBSCRIPTION);
        let headers = utils.getDefaultRequestHeaders;
        let userDataAsJson = utils.formDataToJsonObject(formData);
        url = utils.addQueryParameters(url, userDataAsJson)
        return fetch(url,
            {
                method: 'PUT',
                headers: headers,
                mode: 'cors',
                body: '{}'
            });
    }

    deleteAccount = () => {
        const url = utils.proxify(constants.authenticationApiEndpoints.USER);
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'DELETE',
                headers: headers,
                mode: 'cors'
            });
    }

}
export default SignupService;
