import React, {Component} from 'react';
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import "../UploadImagePreview.css";
import TenantController from "./TenantController";
import ReactDOM from "react-dom";
import DataTableNewRowButton from "../DataTableNewRowButton";
import utils from "../utils";
import {isEmpty} from "lodash";
import DataTableTenantIndividualName from "./DataTableTenantIndividualName";
import DataTableTenantCorporationName from "./DataTableTenantCorporationName";
import DataTableTenantContacts from "./DataTableTenantContacts";
import DataTableTenantIdentification from "./DataTableTenantIdentification";
import DataTableOverViewActions from "../DataTableOverViewActions";

class TenantOverview extends Component{

    constructor(props) {
        super(props);
        this.tenantController = new TenantController();
        this.dataTable = {};

        this.state = {
            currentUsersTenants: [],
        }
    }

    getActionsContainer = (resourceUuid) => {
        return "<div id='actions_" + resourceUuid + "'></div>"
    }

    componentDidMount() {
        this.getUsersTenants();
    }

    getNamesContainer = (resourceUuid) => {
        return "<div id='names_" + resourceUuid + "'></div>"
    }

    getContactsContainer = (resourceUuid) => {
        return "<div id='contacts_" + resourceUuid + "'></div>"
    }

    getIdentityContainer = (resourceUuid) => {
        return "<div id='identity_" + resourceUuid + "'></div>"
    }

    getDataTableConfig = (data) => {
        return {
            "processing": true,
            paging: false,
            destroy: true,
            data: data,
            columns: [
                { title: 'Réference' },
                { title: 'Name' },
                { title: 'Contacts' },
                { title: "Pièce d'identité", name:"identity" },
                { title: 'Actions',
                },
            ],
        }
    }

    deleteTenant = (resourceUuid) => {
        this.props.history.push(utils.contextualizePath('/underconstruction'));
    }

    getTenantDetails = (resourceUuid, type) => {
        if ('individual' === type){
            this.props.history.push(utils.contextualizePath('/tenant/individual/' + resourceUuid + '/details'));
        }else {
            this.props.history.push(utils.contextualizePath('/tenant/corporation/' + resourceUuid + '/details'));
        }
    }

    editTenant = (resourceUuid, type) => {
        if ('individual' === type){
            this.props.history.push(utils.contextualizePath('/tenant/individual/' + resourceUuid + '/edit'));
        }else {
            this.props.history.push(utils.contextualizePath('/tenant/corporation/' + resourceUuid + '/edit'));
        }
    }

    addNewTenant = () => {
        this.props.history.push(utils.contextualizePath('/tenant/new'));
    }

    displayDataTable = (data) => {
        let dataTableContent = [];
        for(let i=0; i<data.length; i++){
            let row = [];
            row.push(data[i]['reference']);
            row.push(this.getNamesContainer(data[i]['resourceUuid']));
            row.push(this.getContactsContainer(data[i]['resourceUuid']));
            row.push(this.getIdentityContainer(data[i]['resourceUuid']));
            row.push(this.getActionsContainer(data[i]['resourceUuid']));
            dataTableContent.push(row)
        }
        this.setState({currentUsersTenants: data});
        this.dataTable = window.jQuery("#tenant-datatable").DataTable(this.getDataTableConfig(dataTableContent));
        for(let j=0; j<data.length; j++){
            const resourceUuid = data[j]['resourceUuid'];
            const namesContainer = 'names_' + resourceUuid;
            const contactsContainer = 'contacts_' + resourceUuid;
            const identityContainer = 'identity_' + resourceUuid;

            for(let k=0; k<data.length; k++){
                const resourceUuid = data[k]['resourceUuid'];
                const actionsContainer = 'actions_' + resourceUuid;
                const type = (data[k]['person'] && ('INDIVIDUAL' === data[k]['person']['type']))?'individual':'corporation';
                ReactDOM.render(<DataTableOverViewActions history={this.props.history} delete={() => this.deleteTenant(resourceUuid)}
                    details={() => this.getTenantDetails(resourceUuid, type)} edit={() => this.editTenant(resourceUuid, type)}/>,
                    document.getElementById(actionsContainer));
            }

            if (data[j]['person'] && ('INDIVIDUAL' === data[j]['person']['type'])){
                const gender = data[j]['person']['gender'];
                const firstname = data[j]['person']['firstName'];
                const surname = data[j]['person']['surname'];
                const birthdate = data[j]['person']['birthDate'];
                ReactDOM.render(<DataTableTenantIndividualName history={this.props.history}
                    gender={gender} firstname={firstname} surname={surname} birthdate={birthdate}/>,
                    document.getElementById(namesContainer));
            } else if (data[j]['person'] && ('CORPORATION' === data[j]['person']['type'])){
                const status = data[j]['person']['legalStatus']
                const name = data[j]['person']['name']
                const siren = data[j]['person']['siren']
                const siret = data[j]['person']['siret']
                ReactDOM.render(<DataTableTenantCorporationName history={this.props.history}  status={status}
                    name={name} siren={siren} siret={siret}/>,
                    document.getElementById(namesContainer));
            }

            if(data[j]['person']){
                const contacts = data[j]['person']['contacts'];
                if (contacts && !isEmpty(contacts)){
                    const contact = contacts[0];
                    const email = contact['email'];
                    const mobile = contact['mobile'];
                    const address = contact['address'];
                    ReactDOM.render(<DataTableTenantContacts history={this.props.history} email={email} mobile={mobile}
                                                             address={address}/>,
                        document.getElementById(contactsContainer));
                }
            }

            const identificationDocument = data[j]['identificationDocument'];
            if (identificationDocument){
                const type = identificationDocument['type'];
                const registrationNumber = identificationDocument['registrationNumber'];
                const expirationDate = identificationDocument['expirationDate'];
                ReactDOM.render(<DataTableTenantIdentification history={this.props.history} type={type}
                    registrationNumber={registrationNumber} expirationDate={expirationDate}/>,
                    document.getElementById(identityContainer));
            }
        }
    }

    getUsersTenants = () => {
        this.tenantController.getUserTenants()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({currentUsersTenants: data});
                            this.displayDataTable(data);
                            window.jQuery("#tenant-datatable_filter > label").before("<div id='add-btn-container'></div>");
                            ReactDOM.render(<DataTableNewRowButton history={this.props.history} add={() => this.addNewTenant()}/>,
                                document.getElementById("add-btn-container"));
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal()
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(422 === response.status){
                        response.json().then((data) => {
                            const toastMsg = utils.computeToastMessage(data);
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(toastMsg.messageDate), title: 'Invalid input', message: toastMsg.messageDetails})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar history={this.props.history}/>

                        <div className="card mb-3">

                            <div className="card-header bg-light">
                                <h5 className="mb-0">My tenants</h5>
                            </div>
                            <div className="card-body bg-light">
                                <div className="tab-content">
                                    <div className="tab-pane preview-tab-pane active show" role="tabpanel">
                                        <div className="row g-3 mb-3">
                                            <div className="col-md-12 ">
                                                <div className="card-body px-0 pt-0">
                                                    <table className="table mb-0 fs--1" style={{width:'100%'}}
                                                           id="tenant-datatable">
                                                        <thead className="bg-200 text-900"></thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        );
    };
}
export default TenantOverview;