import React, {Component} from 'react';

class DataTableTenantContacts extends Component{

    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email,
            mobile: this.props.mobile,
            address: this.props.address,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <h5 className="fs-0 fw-normal">{this.state.email}</h5>
                <h5 className="fs-0 fw-normal">{this.state.mobile}</h5>
                {this.state.address}
            </>
        );
    };
}
export default DataTableTenantContacts;