import React, {Component} from 'react';
import "./Account.css";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact:{},
        }
    }

    componentDidMount() {
        this.setState({contact: this.props.contact});
    }

    render() {
        return (

            <>
                <div className="row g-3 mb-3">
                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Adresse email:</h6>
                            <p className="fs-0 mb-0"> {this.props.contact.email}</p>
                        </div>
                    </div>

                    <div className="col-md-3 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Numéro de mobile :</h6>
                            <p className="fs-0 mb-0"> {this.props.contact.mobile}</p>
                        </div>
                    </div>

                    <div className="col-md-3 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Numéro de ligne fixe:</h6>
                            <p className="fs-0 mb-0"> {this.props.contact.phone}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Adresse:</h6>
                            <p className="fs-0 mb-0"> {this.props.contact.address}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default Contact;
