import utils from "./utils";
import constants from "../constants";
class CommonService {

    getCompanyLegalStatusesLike = (input) => {
        const url = utils.proxify(constants.auxiliariesApiEndpoints.LOOKUP_LEGAL_STATUS_LIKE) + '?input=' + input;
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getCountriesLike = (input) => {
        const url = utils.proxify(constants.auxiliariesApiEndpoints.LOOKUP_TOWN_LIKE) + '?countryName=' + input;
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getIdentificationDocumentTypes = () => {
        const url = utils.proxify(constants.auxiliariesApiEndpoints.LOOKUP_IDENTIFICATION_DOCUMENT_TYPES);
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getDocumentTypes = () => {
        const url = utils.proxify(constants.auxiliariesApiEndpoints.LOOKUP_DOCUMENT_TYPES);
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getContractTypes = () => {
        const url = utils.proxify(constants.auxiliariesApiEndpoints.LOOKUP_CONTRACT_TYPES);
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getFileAsByte = (url) => {
        const headers = utils.getDefaultRequestHeaders;
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

}
export default CommonService;