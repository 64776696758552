import AccountService from "./AccountService";
import CommonService from "../CommonService";

class AccountController {

    constructor() {
        this.accountService = new AccountService();
        this.commonService = new CommonService();
    }

    getCountriesLike = (input) => {
       return this.commonService.getCountriesLike(input);
    }

    getLegalStatusesLike = () => {
        return this.commonService.getCompanyLegalStatusesLike('');
    }

    getAccountDetails = () => {
        return this.accountService.getAccountDetails();
    }

    getAccount = (resourceUuid, type) => {
        return this.accountService.getAccount(resourceUuid, type);
    }

    updateIndividualAccount = (resourceUuid, data) => {
        return this.accountService.updateIndividualAccount(resourceUuid, data);
    }

    updateCorporationAccount = (resourceUuid, data) => {
        return this.accountService.updateCorporationAccount(resourceUuid, data);
    }

}

export default AccountController;
