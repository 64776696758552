import utils from "../utils";
import constants from "../../constants";

class TenantService {

    getUserTenants = () => {
        const url = utils.proxify(constants.restApiEndpoints.TENANT);
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getIndividualTenantDetails = (resourceUuid) => {
        let url = utils.proxify(constants.restApiEndpoints.TENANT_INDIVIDUAL);
        url += "/" + resourceUuid;
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getCorporationTenantDetails = (resourceUuid) => {
        let url = utils.proxify(constants.restApiEndpoints.TENANT_CORPORATION);
        url += "/" + resourceUuid;
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    createIndividualTenant = (data) => {
        const url = utils.proxify(constants.restApiEndpoints.TENANT_INDIVIDUAL);
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

    updateIndividualTenant = (resourceUuid, data) => {
        const url = utils.proxify(constants.restApiEndpoints.TENANT_INDIVIDUAL) + '/' + resourceUuid;
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'PUT',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

    createCorporationTenant = (data) => {
        const url = utils.proxify(constants.restApiEndpoints.TENANT_CORPORATION);
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

    updateCorporationTenant = (resourceUuid, data) => {
        const url = utils.proxify(constants.restApiEndpoints.TENANT_CORPORATION) + '/' + resourceUuid;
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'PUT',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

}
export default TenantService;