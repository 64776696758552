import React, {Component} from 'react';
import {isEmpty} from "lodash";

class PersonsPreviewRow extends Component{

    constructor(props) {
        super(props);
        this.state = {
            person: isEmpty(this.props.person)?{}:this.props.person,
            type: this.props.type,
            contact: this.props.person && !isEmpty(this.props.person.contacts)?this.props.person.contacts[0]:{},
        }
    }

    componentDidMount() {
    }


    delete = () => {
        this.props.delete();
    }

    render() {
        return (
            <>
                <tr>
                    <td>
                        {
                            'individual' === this.state.type ? <span>{this.state.person.firstName} &nbsp;&nbsp; {this.state.person.surname}</span>:
                                <span>{this.state.person.name} &nbsp;&nbsp; ({this.state.person.legalStatus})</span>
                        }
                        </td>
                    <td>{this.state.contact.mobile}</td>
                    <td>{this.state.contact.phone}</td>
                    <td>{this.state.contact.email}</td>
                    <td>
                      <span onClick={() => this.delete()} style={{cursor: 'pointer'}}>
                       <i className="fa fa-trash text-danger"/>
                      </span>
                    </td>
                </tr>
            </>
        );
    };
}
export default PersonsPreviewRow;