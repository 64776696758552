import React, {Component} from 'react';
import "../UploadImagePreview.css";

class HabitableComponent extends Component{

    componentDidMount() {
    }

    delete = () => {
        this.props.delete(this.props.component);
    }

    render() {
        return (
            <>
                <div className="row g-3 mb-3" style={{paddingBottom:'0px', marginTop:'-50px'}}>
                    <div className="col-md-4 ">
                        <h6 className="mb-0">Label</h6>
                        <p className="mb-5">{this.props.component.label}</p>
                    </div>
                    <div className="col-md-3 ">
                        <h6 className="mb-0">Type</h6>
                        <p className="mb-5">{this.props.component.type} </p>
                    </div>
                    <div className="col-md-3 ">
                        <h6 className="mb-0">Surface</h6>
                        <p className="mb-5"> {this.props.component.surface}</p>
                    </div>
                    <div className="col-md-2 ">
                        <h6 className="mb-0">&nbsp;&nbsp;&nbsp;</h6>
                        <p className="mb-5">
                            <button
                                style={{display:false === this.props.readonly?'':'none'}}
                                onClick={this.delete}
                                className="btn btn-link p-0 ms-2" type="button" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Delete"><span
                                className="text-500 fas fa-trash-alt"></span></button>

                        </p>
                    </div>
                </div>
            </>
        );
    };
}
export default HabitableComponent;
