import React, {Component} from 'react';
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import utils from "../utils";
import "../UploadImagePreview.css";
import TenantController from "./TenantController";
import {isEmpty} from "lodash";
import UploadedImagePreview from "../UploadedImagePreview";
import PersonsPreviewRow from "./PersonsPreviewRow";
import DocumentsPreviewRow from "./DocumentsPreviewRow";
import FieldsValidityIconSuccess from "./FieldsValidityIconSuccess";
import FieldsValidityIconDanger from "./FieldsValidityIconDanger";
import constants from "../../constants";
import ImageFromBytes from "../bien/ImageFromBytes";

class Tenant extends Component{

    constructor(props) {
        super(props);
        this.tenantController = new TenantController();
        this.gallery = utils.getGligthBoxGallery();
        this.tenantTypeIndividualRef = React.createRef();
        this.tenantTypeCompanyRef = React.createRef();
        this.tenantReferenceRef = React.createRef();
        this.guarantorNationalityRef = React.createRef();
        this.guarantorTypeCorporationRef = React.createRef();
        this.guarantorTypeIndividualRef = React.createRef();
        this.emergencyContactTypeIndividualRef = React.createRef();
        this.emergencyContactTypeCorporationRef = React.createRef();
        this.tenantGenderRef = React.createRef();
        this.tenantLastNameRef = React.createRef();
        this.tenantFirstNameRef = React.createRef();
        this.tenantMiddleNameRef = React.createRef();
        this.tenantBirthDateRef = React.createRef();
        this.tenantBirthPlaceRef = React.createRef();
        this.tenantNationalityRef = React.createRef();
        this.tenantEmailRef = React.createRef();
        this.tenantSecondEmailRef = React.createRef();
        this.tenantMobileRef = React.createRef();
        this.tenantPhoneRef = React.createRef();
        this.tenantAddressRef = React.createRef();
        this.tenantIdentificationDocumentNumberRef = React.createRef();
        this.tenantIdentificationDocumentExpDateRef = React.createRef();
        this.tenantCorporationNameRef = React.createRef();
        this.tenantCorporationCapitalFundRef = React.createRef();
        this.tenantCorporationSiretRef = React.createRef();
        this.tenantCorporationSirenRef = React.createRef();
        this.tenantCorporationVatRef = React.createRef();
        this.tenantCorporationActivityRef = React.createRef();
        this.tenantCorporationWebsiteRef = React.createRef();
        this.tenantProfessionRef = React.createRef();
        this.tenantIncomesRef = React.createRef();
        this.guarantorGenderRef = React.createRef();
        this.guarantorLastnameRef = React.createRef();
        this.guarantorFirstnameRef = React.createRef();
        this.guarantorMobileRef = React.createRef();
        this.guarantorPhoneRef = React.createRef();
        this.guarantorEmailRef = React.createRef();
        this.guarantorBirthDateRef = React.createRef();
        this.guarantorBirthPlaceRef = React.createRef();
        this.guarantorCorporationNameRef = React.createRef();
        this.guarantorCorporationLegalStatusFundRef = React.createRef();
        this.guarantorCorporationCapitalFundRef = React.createRef();
        this.guarantorCorporationSirenRef = React.createRef();
        this.guarantorCorporationSiretRef = React.createRef();
        this.guarantorCorporationVatRef = React.createRef();
        this.individualEmergencyContactGenderRef = React.createRef();
        this.individualEmergencyContactFirstnameRef = React.createRef();
        this.individualEmergencyContactLastnameRef = React.createRef();
        this.individualEmergencyContactMobileRef = React.createRef();
        this.individualEmergencyContactPhoneRef = React.createRef();
        this.individualEmergencyContactEmailRef = React.createRef();
        this.individualEmergencyContactBirthDateRef = React.createRef();
        this.individualEmergencyContactBirthPlaceRef = React.createRef();
        this.individualEmergencyContactNationalityRef = React.createRef();
        this.corporationEmergencyContactNameRef = React.createRef();
        this.corporationEmergencyContactCapitalFundRef = React.createRef();
        this.corporationEmergencyContactLegalStatusRef = React.createRef();
        this.corporationEmergencyContactSirenRef = React.createRef();
        this.corporationEmergencyContactSiretRef = React.createRef();
        this.corporationEmergencyContactVatRef = React.createRef();
        this.tenantPrivateCommentRef = React.createRef();
        this.tenantFollowupAddressRef = React.createRef();
        this.guarantorAddressRef = React.createRef();
        this.tenantCorporationStatusRef = React.createRef();
        this.emergencyContactAddressRef = React.createRef();
        this.documentTypeRef = React.createRef();
        this.documentDescriptionRef = React.createRef();
        this.tenantIdentificationDocumentTypeRef = React.createRef();
        this.individualTenantEmployerRef = React.createRef();
        this.tenantEmployerAddressRef = React.createRef();
        this.individualTenantContractTypeRef = React.createRef();
        this.tenantBanknameRef = React.createRef();
        this.tenantBankaddressRef = React.createRef();
        this.tenantBankcodeRef = React.createRef();
        this.tenantBankBranchcodeRef = React.createRef();
        this.tenantBankAccountNumberRef = React.createRef();
        this.tenantBankribRef = React.createRef();
        this.tenantBankibanRef = React.createRef();
        this.tenantBankCompleteRibRef = React.createRef();
        this.tenantBankbicRef = React.createRef();

        this.state = {
            isDisabledSubmitButton: false,
            isDisabledGuarantorSubmitButton: false,
            isDisabledEmergencyContactSubmitButton: false,
            isAtLeastOneTenantPersonalInfosFieldInvalid: false,
            isAtLeastOneTenantFinancialInfosFieldInvalid: false,
            isAtLeastOneTenantDocumentInfosFieldInvalid: false,
            invalidUploadedFileMessage: '',
            invalidUploadedPictureFileMessage: '',
            individualInformationsTitle: 'Informations Personnelles',
            isIndividualTenant: true,
            isIndividualGuarantor: true,
            isIndividualEmergencyContact: true,
            isInvalidUploadedFile: false,
            isInvalidTenantPictureFile: false,
            isInvalidTenantGender: false,
            isInvalidTenantLastName: false,
            isInvalidTenantFirstName: false,
            isInvalidTenantBirthDate: false,
            isInvalidTenantBirthPlace: false,
            isInvalidTenantNationality: false,
            isInvalidTenantEmail: false,
            isInvalidTenantSecondEmail: false,
            isInvalidTenantMobile: false,
            isInvalidTenantPhone: false,
            isInvalidTenantAddress: false,
            isInvalidTenantIdentificationDocumentNumber: false,
            isInvalidTenantIdentificationDocumentExpDate: false,
            isInvalidTenantCorporationName: false,
            isInvalidTenantCorporationCapitalFund: false,
            isInvalidTenantCorporationSiret: false,
            isInvalidTenantCorporationSiren: false,
            isInvalidTenantCorporationVat: false,
            isInvalidTenantCorporationWebsite: false,
            isInvalidTenantProfession: false,
            isInvalidTenantIncomes: false,
            isInvalidGuarantorGender: false,
            isInvalidGuarantorLastname: false,
            isInvalidGuarantorFirstname: false,
            isInvalidGuarantorMobile: false,
            isInvalidGuarantorPhone: false,
            isInvalidGuarantorEmail: false,
            isInvalidGuarantorBirthDate: false,
            isInvalidGuarantorNationality: false,
            isInvalidGuarantorBirthPlace: false,
            isInvalidGuarantorCorporationName: false,
            isInvalidGuarantorCorporationCapitalFund: false,
            isInvalidGuarantorCorporationSiren: false,
            isInvalidGuarantorCorporationSiret: false,
            isInvalidGuarantorCorporationVat: false,
            isInvalidIndividualEmergencyContactGender: false,
            isInvalidIndividualEmergencyContactFirstname: false,
            isInvalidIndividualEmergencyContactLastname: false,
            isInvalidIndividualEmergencyContactMobile: false,
            isInvalidIndividualEmergencyContactPhone: false,
            isInvalidIndividualEmergencyContactEmail: false,
            isInvalidIndividualEmergencyContactBirthDate: false,
            isInvalidIndividualEmergencyContactBirthPlace: false,
            isInvalidIndividualEmergencyContactNationality: false,
            isInvalidCorporationEmergencyContactName: false,
            isInvalidCorporationEmergencyContactCapitalFund: false,
            isInvalidCorporationEmergencyContactSiren: false,
            isInvalidCorporationEmergencyContactSiret: false,
            isInvalidCorporationEmergencyContactVat: false,
            noGuarantorProvided: false,
            noEmergencyContactProvided: false,
            companyLegalStatuses: [],
            countries: [],
            contractTypes: [],
            identificationDocumentTypes: [],
            documentTypes: [],
            identificationDocumentsQueuedFiles: {},
            pictureQueuedFiles: {},
            tenantDetails: {},
            tenantPersonDetails: {},
            guarantors: [],
            emergencyContacts: [],
            documents: [],
            documentFiles: [],
        }
    }

    componentDidMount() {
        this.fetchCompanyLegalStatuses();
        this.fetchIdentificationDocumentTypes();
        this.fetchDocumentTypes();
        this.fetchContractTypes();
        this.displayTenantIndividualBirthDate();
        this.displayGuarantorIndividualBirthDate();
        this.displayEmergencyContactIndividualBirthDate();
        this.displayIdentityExpirationDate();
        this.displayIdentityDocumentUploader();
        this.displayTenantPictureUploader();
        this.fetchTenantDetails();
    }

    displayTenantIndividualBirthDate = () => {
        this.dataTable = window.jQuery("#birthDate").flatpickr({
            dateFormat: "d/m/Y",
            maxDate: "today",
        });
    }

    displayGuarantorIndividualBirthDate = () => {
        this.dataTable = window.jQuery("#guarantor-birthDate").flatpickr({
            dateFormat: "d/m/Y",
            maxDate: "today",
        });
    }

    displayEmergencyContactIndividualBirthDate = () => {
        this.dataTable = window.jQuery("#individual-emergency-contact-birthDate").flatpickr({
            dateFormat: "d/m/Y",
            maxDate: "today",
        });
    }

    displayIdentityExpirationDate = () => {
        this.dataTable = window.jQuery("#identityExpirationDate").flatpickr({
            dateFormat: "d/m/Y",
        });
    }

    displayIdentityDocumentUploader = () => {
        new window.Dropzone('#identification-document', {
            url: 'https://google.com',
            autoProcessQueue: false,
            addedfile: this.handleAddedFiles,
            disablePreviews:true,
        });
    }

    displayTenantPictureUploader = () => {
        new window.Dropzone('#tenant-picture-container', {
            url: 'https://google.com',
            autoProcessQueue: false,
            addedfile: this.handlePictureFile,
            disablePreviews:false,
        });
    }

    handleAddedFiles = (file) => {
        this.setState({isInvalidUploadedFile: false});
        if(!utils.isUploadedFileAnImage(file)){
            this.setState({isInvalidUploadedFile: true, invalidUploadedFileMessage: 'Your uploaded file is not supported'});
            this.disableSubmitButton();
            return;
        }
        if(this.isMaxFileTresholdReached()){
            this.setState({isInvalidUploadedFile: true, invalidUploadedFileMessage: 'You cant upload more than 1 pictures'});
            this.disableSubmitButton();
            return;
        }
        let oldIdentificationDocumentsQueuedFiles = this.state.identificationDocumentsQueuedFiles;
        this.setState({identificationDocumentsQueuedFiles: utils.updateQueuedFiles(file, oldIdentificationDocumentsQueuedFiles),
            isInvalidUploadedFile:oldIdentificationDocumentsQueuedFiles.length === 0});
        this.hideInvalidUploadedFileMessage();
        this.disableSubmitButton();
    }

    handlePictureFile = (file) => {
        this.setState({isInvalidTenantPictureFile: false});
        if(!utils.isUploadedFileAnImage(file)){
            this.setState({isInvalidTenantPictureFile: true, invalidUploadedPictureFileMessage: 'Your uploaded file is not supported, should be an image'});
            this.disableSubmitButton();
            return;
        }
        if(this.isMaxTenantPictureFileTresholdReached()){
            this.setState({isInvalidTenantPictureFile: true, invalidUploadedPictureFileMessage: 'You cant upload more than 1 pictures'});
            this.disableSubmitButton();
            return;
        }
        let oldPictureQueuedFiles = this.state.pictureQueuedFiles;
        this.setState({pictureQueuedFiles: utils.updateQueuedFiles(file, oldPictureQueuedFiles),
            isInvalidTenantPictureFile:oldPictureQueuedFiles.length === 0});
        //this.hideInvalidUploadedFileMessage();
        this.disableSubmitButton();
    }

    isMaxFileTresholdReached = () => {
        return 1 === Object.keys(this.state.identificationDocumentsQueuedFiles).length;
    }

    isMaxTenantPictureFileTresholdReached = () => {
        return 1 === Object.keys(this.state.pictureQueuedFiles).length;
    }

    fetchIdentificationDocumentTypes = () => {
        this.tenantController.getIdentificationDocumentTypes()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({identificationDocumentTypes: data});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            );
    };

    fetchDocumentTypes = () => {
        this.tenantController.getDocumentTypes()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({documentTypes: data});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            );
    };

    fetchCountries = (input) => {
        this.tenantController.getCountriesLike(input)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({countries: data});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            );
    };

    fetchCompanyLegalStatuses = () => {
        this.tenantController.getLegalStatusesLike()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({companyLegalStatuses: data});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            );
    };

    fetchContractTypes = () => {
        this.tenantController.getContractTypes()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({contractTypes: data});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            );
    };

    toggleTenantTypeFields = () => {
        if(this.tenantTypeIndividualRef.current.checked){
            window.jQuery("#companyFields").hide();
            window.jQuery("#notCompany").show();
            this.setState({individualInformationsTitle: "Informations Personnelles", isIndividualTenant:true});
        } else {
            window.jQuery("#companyFields").show();
            window.jQuery("#notCompany").hide();
            this.setState({individualInformationsTitle: "Informations du Représentant", isIndividualTenant:false});
        }
    }

    toggleButtonStyles = (activeButtonId, inactiveButtonId) => {
        // TODO: To implement in another USER STORY ==> The search of existing Tenant
        const activeButton = window.jQuery('#' + activeButtonId);
        const inactiveButton = window.jQuery('#' + inactiveButtonId);

        //activeButton.classList.remove('btn-secondary');
        //activeButton.classList.add('btn-primary');
        //inactiveButton.classList.remove('btn-primary');
        //inactiveButton.classList.add('btn-secondary');
    };

    toggleTenantGuarantorTypeFields = () => {
        if(this.guarantorTypeIndividualRef.current.checked){
            window.jQuery(".corporation-guarantor").hide();
            window.jQuery(".individual-guarantor").show();
            this.setState({isIndividualGuarantor: true});
        }else{
            window.jQuery(".corporation-guarantor").show();
            window.jQuery(".individual-guarantor").hide();
            this.setState({isIndividualGuarantor: false});
        }

    };

    toggleTenantEmergencyContactTypeFields = () => {
        if(this.emergencyContactTypeIndividualRef.current.checked){
            window.jQuery(".corporation-emergency-contact").hide();
            window.jQuery(".individual-emergency-contact").show();
            this.setState({isIndividualEmergencyContact: true});
        }else{
            window.jQuery(".corporation-emergency-contact").show();
            window.jQuery(".individual-emergency-contact").hide();
            this.setState({isIndividualEmergencyContact: false});
        }

    };

    deleteGuarantor = (index) => {
        let currentGuarantors = this.state.guarantors;
        delete currentGuarantors[index];
        this.setState({guarantors: currentGuarantors});
    }

    deleteEmergencyContact = (index) => {
        let currentEmergencyContacts = this.state.emergencyContacts;
        delete currentEmergencyContacts[index];
        this.setState({emergencyContacts: currentEmergencyContacts});
    }

    deleteDocument = (index) => {
        let currentDocuments = this.state.documents;
        delete currentDocuments[index];
        let oldDocumentFiles = this.state.documentFiles;
        delete oldDocumentFiles[index];
        this.setState({documents: currentDocuments, documentFiles:oldDocumentFiles});
    }

    addGuarantor = () => {
        let person = this.buildGuarantorCommonPerson();
        let guarantor = {person:person};
        if (this.state.isIndividualGuarantor){
            this.addIndividualGuarantorData(guarantor.person);
            guarantor['type'] = 'individual';
        } else{
            this.addCorporationGuarantorData(guarantor.person);
            guarantor['type'] = 'corporation';
        }

        const oldGuarantors = this.state.guarantors;
        oldGuarantors.push(guarantor);
        this.setState({guarantors: oldGuarantors});
        window.jQuery("#guarantor-modal-closer").click();
    }

    addIndividualGuarantorData = (person) => {
        person['gender'] = this.guarantorGenderRef.current.value;
        person['surname'] = this.guarantorLastnameRef.current.value;
        person['firstName'] = this.guarantorFirstnameRef.current.value;
        person['birthDate'] = this.guarantorBirthDateRef.current.value;
        person['placeOfBirth'] = this.guarantorBirthPlaceRef.current.value;
        person['nationality'] = this.guarantorNationalityRef.current.value;

    }

    addCorporationGuarantorData = (person) => {
        person['name'] = this.guarantorCorporationNameRef.current.value;
        person['legalStatus'] = this.guarantorCorporationLegalStatusFundRef.current.value;
        person['shareCapital'] = this.guarantorCorporationCapitalFundRef.current.value;
        person['siren'] = this.guarantorCorporationSirenRef.current.value;
        person['siret'] = this.guarantorCorporationSiretRef.current.value;
        person['vatNumber'] = this.guarantorCorporationVatRef.current.value;
    }

    buildGuarantorContacts =  () => {
        return [
            {
                mobile: this.guarantorMobileRef.current.value,
                phone: this.guarantorPhoneRef.current.value,
                email: this.guarantorEmailRef.current.value,
                address: this.guarantorAddressRef.current.value,
            }
        ]
    }

    buildGuarantorCommonPerson =  () => {
        return {
            contacts: this.buildGuarantorContacts()
        }
    }

    addEmergencyContact = () => {
        let person = this.buildEmergencyContactCommonPerson();
        let emergencyContact = {person:person};
        if (this.state.isIndividualEmergencyContact){
            this.addIndividualEmergencyContactData(emergencyContact.person);
            emergencyContact['type'] = 'individual';
        }else{
            this.addCorporationEmergencyContactData(emergencyContact.person);
            emergencyContact['type'] = 'corporation';
        }
        const oldEmergencyContacts = this.state.emergencyContacts;
        oldEmergencyContacts.push(emergencyContact);
        this.setState({emergencyContacts: oldEmergencyContacts});
        window.jQuery("#emergency-contact-modal-closer").click();
    }

    addIndividualEmergencyContactData = (person) => {
        person['gender'] = this.individualEmergencyContactGenderRef.current.value;
        person['surname'] = this.individualEmergencyContactLastnameRef.current.value;
        person['firstName'] = this.individualEmergencyContactFirstnameRef.current.value;
        person['birthDate'] = this.individualEmergencyContactBirthDateRef.current.value;
        person['placeOfBirth'] = this.individualEmergencyContactBirthPlaceRef.current.value;
        person['nationality'] = this.individualEmergencyContactNationalityRef.current.value;

    }

    addCorporationEmergencyContactData = (person) => {
        person['name'] = this.corporationEmergencyContactNameRef.current.value;
        person['legalStatus'] = this.corporationEmergencyContactLegalStatusRef.current.value;
        person['shareCapital'] = this.corporationEmergencyContactCapitalFundRef.current.value;
        person['siren'] = this.corporationEmergencyContactSirenRef.current.value;
        person['siret'] = this.corporationEmergencyContactSiretRef.current.value;
        person['vatNumber'] = this.corporationEmergencyContactVatRef.current.value;
    }

    buildEmergencyContactContacts =  () => {
        return [
            {
                mobile: this.individualEmergencyContactMobileRef.current.value,
                phone: this.individualEmergencyContactPhoneRef.current.value,
                email: this.individualEmergencyContactEmailRef.current.value,
                address: this.emergencyContactAddressRef.current.value,
            }
        ]
    }

    buildEmergencyContactCommonPerson =  () => {
        return {
            contacts: this.buildEmergencyContactContacts()
        }
    }

    addDocument = () => {
        const newDocument = this.buildDocument();
        let oldDocuments = this.state.documents;
        oldDocuments.push(newDocument)
        this.setState({documents: oldDocuments});
        window.jQuery('#document-model-closer').click();
    }

    getFormattedDate = () => {
        const d = new Date, dformat =
            [d.getMonth()+1,
                d.getDate(),
                d.getFullYear()].join('/')+' '+
            [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');
        return dformat;
    }

    buildDocument = () => {
        const file = window.jQuery('#document-file')[0].files[0];
        const filename = file.name
        this.state.documentFiles.push(file);
        return {
            type: this.documentTypeRef.current.value,
            description: this.documentDescriptionRef.current.value,
            metaData: {
                fileName: filename,
                fileUuid: filename,
            },
            uploaddate: this.getFormattedDate()
        }
    }

    buildIdentificationDocument = () => {
        const identificationDocumentsQueuedFile = isEmpty(this.state.identificationDocumentsQueuedFiles)?{}
        :this.state.identificationDocumentsQueuedFiles[0];
        return {
            type: this.tenantIdentificationDocumentTypeRef.current.value,
            registrationNumber: this.tenantIdentificationDocumentNumberRef.current.value,
            expirationDate: this.tenantIdentificationDocumentExpDateRef.current.value,
            metaData: {
                fileName: identificationDocumentsQueuedFile.name,
                fileUuid: identificationDocumentsQueuedFile.name,
            },
            uploaddate: this.getFormattedDate()
        }
    }

    searchGuarantorFunction = () => {
        // TODO: To implement in another USER STORY ==> The search of existing Tenant

    }

    searchEmergencyContact = () => {
        // TODO: To implement in another USER STORY ==> The search of existing Tenant
    }

    deleteIdentificationDocumentFile = (key) => {
        let oldIdentificationDocumentsQueuedFiles = this.state.identificationDocumentsQueuedFiles;
        delete oldIdentificationDocumentsQueuedFiles[key];
        this.setState({identificationDocumentsQueuedFiles: oldIdentificationDocumentsQueuedFiles,
            isInvalidUploadedFile: (oldIdentificationDocumentsQueuedFiles.length === 0)});
        this.disableSubmitButton();
    }

    displayIdentificationDocumentPreview = () => {
        let files = this.state.identificationDocumentsQueuedFiles;
        const deleteFileFunction = key => this.deleteIdentificationDocumentFile(key);
        return Object.keys(files).map(function(key, index) {
            return <UploadedImagePreview key={index} delete={() => deleteFileFunction(key)} file={files[key]} />
        })
    }

    deletePictureFile = (key) => {
        let oldPictureQueuedFiles = this.state.pictureQueuedFiles;
        delete oldPictureQueuedFiles[key];
        let oldTenantDetails = this.state.tenantDetails;
        if(this.state.tenantDetails.picture)
            oldTenantDetails.picture = null;
        this.setState({pictureQueuedFiles: oldPictureQueuedFiles, tenantDetails:oldTenantDetails,
            isInvalidTenantPictureFile: (oldPictureQueuedFiles.length === 0)});
        window.jQuery('#tenant-picture-container').show();
        this.disableSubmitButton();
    }


    displayTenantPicturePreview = () => {
        let files = this.state.pictureQueuedFiles;
        const deleteFileFunction = key => this.deletePictureFile(key);
        if (!isEmpty(this.state.pictureQueuedFiles) && this.state.pictureQueuedFiles.length !== 0)
            window.jQuery('#tenant-picture-container').hide();
        return Object.keys(files).map(function(key, index) {
            return <UploadedImagePreview key={index} delete={() => deleteFileFunction(key)} file={files[key]} />
        })
    }

    validateTenantGender =  () => {
        this.setState({isInvalidTenantGender:this.isInvalidTenantGender()});
        this.disableSubmitButton();
    }

    isInvalidTenantGender = () => {
        return isEmpty(this.tenantGenderRef.current.value)
    }

    hideInvalidTenantGenderMessage =  () => {
        this.setState({isInvalidTenantGender: false});
        this.disableSubmitButton();
    }

    validateTenantLastName =  () => {
        this.setState({isInvalidTenantLastName:this.isInvalidTenantLastName()});
        this.disableSubmitButton();
    }

    isInvalidTenantLastName = () => {
        return isEmpty(this.tenantLastNameRef.current.value)
    }

    hideInvalidTenantLastNameMessage =  () => {
        this.setState({isInvalidTenantLastName: false});
        this.disableSubmitButton();
    }

    validateTenantFirstName =  () => {
        this.setState({isInvalidTenantFirstName:this.isInvalidTenantFirstName()});
        this.disableSubmitButton();
    }

    isInvalidTenantFirstName = () => {
        return isEmpty(this.tenantFirstNameRef.current.value)
    }

    hideInvalidTenantFirstNameMessage =  () => {
        this.setState({isInvalidTenantFirstName: false});
        this.disableSubmitButton();
    }

    validateTenantBirthDate =  () => {
        this.setState({isInvalidTenantBirthDate:this.isInvalidTenantBirthDate()});
        this.disableSubmitButton();
    }

    isInvalidTenantBirthDate = () => {
        const birthDate = this.tenantBirthDateRef.current.value;
        if(isEmpty(birthDate))
            return true;

        let today = new Date();
        today.setHours(0,0,0,0);
        return utils.fn_isInValidDate(birthDate) || new Date(birthDate)>today;
    }

    hideInvalidTenantBirthDateMessage =  () => {
        this.setState({isInvalidTenantBirthDate: false});
        this.disableSubmitButton();
    }

    validateTenantBirthPlace =  () => {
        this.setState({isInvalidTenantBirthPlace:this.isInvalidTenantBirthPlace()});
        this.disableSubmitButton();
    }

    isInvalidTenantBirthPlace = () => {
        return isEmpty(this.tenantBirthPlaceRef.current.value)
    }

    hideInvalidTenantBirthPlaceMessage =  () => {
        this.setState({isInvalidTenantBirthPlace: false});
        this.disableSubmitButton();
    }

    validateTenantNationality =  () => {
        this.setState({isInvalidTenantNationality:this.isInvalidTenantNationality()});
        this.disableSubmitButton();
    }

    isInvalidTenantNationality = () => {
        return isEmpty(this.tenantNationalityRef.current.value)
    }

    hideInvalidTenantNationalityMessage =  () => {
        this.setState({isInvalidTenantNationality: false});
        this.disableSubmitButton();
    }

    validateTenantEmail =  () => {
        this.setState({isInvalidTenantEmail:this.isInvalidTenantEmail()});
        this.disableSubmitButton();
    }

    isInvalidTenantEmail = () => {
        return  utils.fn_isInValidEmail(this.tenantEmailRef.current.value);
    }

    hideInvalidTenantEmailMessage =  () => {
        this.setState({isInvalidTenantEmail: false});
        this.disableSubmitButton();
    }

    validateTenantSecondEmail =  () => {
        this.setState({isInvalidTenantSecondEmail:this.isInvalidTenantSecondEmail()});
        this.disableSubmitButton();
    }

    isInvalidTenantSecondEmail = () => {
        if(isEmpty(this.tenantSecondEmailRef.current.value))
            return false;
        return  utils.fn_isInValidEmail(this.tenantSecondEmailRef.current.value);
    }

    hideInvalidTenantSecondEmailMessage =  () => {
        this.setState({isInvalidTenantSecondEmail: false});
        this.disableSubmitButton();
    }

    validateTenantMobile =  () => {
        this.setState({isInvalidTenantMobile:this.isInvalidTenantMobile()});
        this.disableSubmitButton();
    }

    isInvalidTenantMobile = () => {
        return  utils.fn_isInValidMobile(this.tenantMobileRef.current.value);
    }

    hideInvalidTenantMobileMessage =  () => {
        this.setState({isInvalidTenantMobile: false});
        this.disableSubmitButton();
    }

    validateTenantPhone =  () => {
        this.setState({isInvalidTenantPhone:this.isInvalidTenantPhone()});
        this.disableSubmitButton();
    }

    isInvalidTenantPhone = () => {
        if(isEmpty(this.tenantPhoneRef.current.value))
            return false;
        return  utils.fn_isInValidTel(this.tenantPhoneRef.current.value);
    }

    hideInvalidTenantPhoneMessage =  () => {
        this.setState({isInvalidTenantPhone: false});
        this.disableSubmitButton();
    }

    validateTenantAddress =  () => {
        this.setState({isInvalidTenantAddress:this.isInvalidTenantAddress()});
        this.disableSubmitButton();
    }

    isInvalidTenantAddress = () => {
        return  isEmpty(this.tenantAddressRef.current.value);
    }

    hideInvalidTenantAddressMessage =  () => {
        this.setState({isInvalidTenantAddress: false});
        this.disableSubmitButton();
    }

    validateTenantIdentificationDocumentNumber =  () => {
        this.setState({isInvalidTenantIdentificationDocumentNumber:this.isInvalidTenantIdentificationDocumentNumber()});
        this.disableSubmitButton();
    }

    isInvalidTenantIdentificationDocumentNumber = () => {
        if(isEmpty(this.tenantIdentificationDocumentNumberRef.current.value))
            return true;
        // TODO: We need to implement here a validation of the identificationnumber base on the type of document type choosen
        return  false;
    }

    hideInvalidTenantIdentificationDocumentNumberMessage =  () => {
        this.setState({isInvalidTenantIdentificationDocumentNumber: false});
        this.disableSubmitButton();
    }

    validateTenantIdentificationDocumentExpDate =  () => {
        this.setState({isInvalidTenantIdentificationDocumentExpDate:this.isInvalidTenantIdentificationDocumentExpDate()});
        this.disableSubmitButton();
    }

    isInvalidTenantIdentificationDocumentExpDate = () => {
        const expirationDate = this.tenantIdentificationDocumentExpDateRef.current.value;
        if(isEmpty(expirationDate))
            return true;

        let today = new Date();
        today.setHours(0,0,0,0);
        return utils.fn_isInValidDate(expirationDate);
    }

    hideInvalidTenantIdentificationDocumentExpDateMessage =  () => {
        this.setState({isInvalidTenantIdentificationDocumentExpDate: false});
        this.disableSubmitButton();
    }

    isInvalidUploadedFile = () => {
        return this.state.isInvalidUploadedFile;
    }

    hideInvalidUploadedFileMessage =  () => {
        this.setState({isInvalidUploadedFile: false});
        this.disableSubmitButton();
    }

    validateTenantCorporationName =  () => {
        this.setState({isInvalidTenantCorporationName:this.isInvalidTenantCorporationName()});
        this.disableSubmitButton();
    }

    isInvalidTenantCorporationName = () => {
        return isEmpty(this.tenantCorporationNameRef.current.value);
    }

    hideInvalidTenantCorporationNameMessage =  () => {
        this.setState({isInvalidTenantCorporationName: false});
        this.disableSubmitButton();
    }

    validateTenantCorporationCapitalFund =  () => {
        this.setState({isInvalidTenantCorporationCapitalFund:this.isInvalidTenantCorporationCapitalFund()});
        this.disableSubmitButton();
    }

    isInvalidTenantCorporationCapitalFund = () => {
        if (isEmpty(this.tenantCorporationCapitalFundRef.current.value))
            return false;
        return isNaN(this.tenantCorporationCapitalFundRef.current.value);
    }

    hideInvalidTenantCorporationCapitalFundMessage =  () => {
        this.setState({isInvalidTenantCorporationCapitalFund: false});
        this.disableSubmitButton();
    }

    validateTenantCorporationSiret =  () => {
        this.setState({isInvalidTenantCorporationSiret:this.isInvalidTenantCorporationSiret()});
        this.disableSubmitButton();
    }

    isInvalidTenantCorporationSiret = () => {
        if (isEmpty(this.tenantCorporationSiretRef.current.value))
            return false;
        return !utils.fn_isValidSiret(this.tenantCorporationSiretRef.current.value);
    }

    hideInvalidTenantCorporationSiretMessage =  () => {
        this.setState({isInvalidTenantCorporationSiret: false});
        this.disableSubmitButton();
    }

    validateTenantCorporationSiren =  () => {
        this.setState({isInvalidTenantCorporationSiren:this.isInvalidTenantCorporationSiren()});
        this.disableSubmitButton();
    }

    isInvalidTenantCorporationSiren = () => {
        if (isEmpty(this.tenantCorporationSirenRef.current.value))
            return false;
        return !utils.fn_isValidSiren(this.tenantCorporationSirenRef.current.value);
    }

    hideInvalidTenantCorporationSirenMessage =  () => {
        this.setState({isInvalidTenantCorporationSiren: false});
        this.disableSubmitButton();
    }

    validateTenantCorporationVat =  () => {
        this.setState({isInvalidTenantCorporationVat:this.isInvalidTenantCorporationVat()});
        this.disableSubmitButton();
    }

    isInvalidTenantCorporationVat = () => {
        if (isEmpty(this.tenantCorporationVatRef.current.value))
            return false;
        return !utils.fn_isValidVat(this.tenantCorporationVatRef.current.value);
    }

    hideInvalidTenantCorporationVatMessage =  () => {
        this.setState({isInvalidTenantCorporationVat: false});
        this.disableSubmitButton();
    }

    validateTenantCorporationWebsite =  () => {
        this.setState({isInvalidTenantCorporationWebsite:this.isInvalidTenantCorporationWebsite()});
        this.disableSubmitButton();
    }

    isInvalidTenantCorporationWebsite = () => {
        if (isEmpty(this.tenantCorporationWebsiteRef.current.value))
            return false;
        return !utils.fn_isValidUrl(this.tenantCorporationWebsiteRef.current.value);
    }

    hideInvalidTenantCorporationWebsiteMessage =  () => {
        this.setState({isInvalidTenantCorporationWebsite: false});
        this.disableSubmitButton();
    }

    validateTenantProfession =  () => {
        this.setState({isInvalidTenantProfession:this.isInvalidTenantProfession()});
        this.disableSubmitButton();
    }

    isInvalidTenantProfession = () => {
        return isEmpty(this.tenantProfessionRef.current.value);
    }

    hideInvalidTenantProfessionMessage =  () => {
        this.setState({isInvalidTenantProfession: false});
        this.disableSubmitButton();
    }

    validateTenantIncomes =  () => {
        this.setState({isInvalidTenantIncomes:this.isInvalidTenantIncomes()});
        this.disableSubmitButton();
    }

    isInvalidTenantIncomes = () => {
        if (isEmpty(this.tenantIncomesRef.current.value))
            return false;
        return isNaN(this.tenantIncomesRef.current.value);
    }

    hideInvalidTenantIncomesMessage =  () => {
        this.setState({isInvalidTenantIncomes: false});
        this.disableSubmitButton();
    }

    validateGuarantorGender =  () => {
        this.setState({isInvalidGuarantorGender:this.isInvalidGuarantorGender()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorGender = () => {
        return isEmpty(this.guarantorGenderRef.current.value);
    }

    hideInvalidGuarantorGenderMessage =  () => {
        this.setState({isInvalidGuarantorGender: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorLastname =  () => {
        this.setState({isInvalidGuarantorLastname:this.isInvalidGuarantorLastname()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorLastname = () => {
        return isEmpty(this.guarantorLastnameRef.current.value);
    }

    hideInvalidGuarantorLastnameMessage =  () => {
        this.setState({isInvalidGuarantorLastname: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorFirstname =  () => {
        this.setState({isInvalidGuarantorFirstname:this.isInvalidGuarantorFirstname()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorFirstname = () => {
        return isEmpty(this.guarantorFirstnameRef.current.value);
    }

    hideInvalidGuarantorFirstnameMessage =  () => {
        this.setState({isInvalidGuarantorFirstname: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorMobile =  () => {
        this.setState({isInvalidGuarantorMobile:this.isInvalidGuarantorMobile()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorMobile = () => {
        return  utils.fn_isInValidMobile(this.guarantorMobileRef.current.value);
    }

    hideInvalidGuarantorMobileMessage =  () => {
        this.setState({isInvalidGuarantorMobile: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorPhone =  () => {
        this.setState({isInvalidGuarantorPhone:this.isInvalidGuarantorPhone()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorPhone = () => {
        if(isEmpty(this.guarantorPhoneRef.current.value))
            return false;
        return  utils.fn_isInValidTel(this.guarantorPhoneRef.current.value);
    }

    hideInvalidGuarantorPhoneMessage =  () => {
        this.setState({isInvalidGuarantorPhone: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorEmail =  () => {
        this.setState({isInvalidGuarantorEmail:this.isInvalidGuarantorEmail()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorEmail = () => {
        return  utils.fn_isInValidEmail(this.guarantorEmailRef.current.value);
    }

    hideInvalidGuarantorEmailMessage =  () => {
        this.setState({isInvalidGuarantorEmail: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorBirthDate =  () => {
        this.setState({isInvalidGuarantorBirthDate:this.isInvalidGuarantorBirthDate()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorBirthDate = () => {
        const birthDate = this.guarantorBirthDateRef.current.value;
        if(isEmpty(birthDate))
            return true;

        let today = new Date();
        today.setHours(0,0,0,0);
        return utils.fn_isInValidDate(birthDate) || new Date(birthDate)>today;
    }

    hideInvalidGuarantorBirthDateMessage =  () => {
        this.setState({isInvalidGuarantorBirthDate: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorNationality =  () => {
        this.setState({isInvalidGuarantorNationality:this.isInvalidGuarantorNationality()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorNationality = () => {
        return isEmpty(this.guarantorNationalityRef.current.value);
    }

    hideInvalidGuarantorNationalityMessage =  () => {
        this.setState({isInvalidGuarantorNationality: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorBirthPlace =  () => {
        this.setState({isInvalidGuarantorBirthPlace:this.isInvalidGuarantorBirthPlace()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorBirthPlace = () => {
        return isEmpty(this.guarantorBirthPlaceRef.current.value);
    }

    hideInvalidGuarantorBirthPlaceMessage =  () => {
        this.setState({isInvalidGuarantorBirthPlace: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorCorporationName =  () => {
        this.setState({isInvalidGuarantorCorporationName:this.isInvalidGuarantorCorporationName()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorCorporationName = () => {
        return isEmpty(this.guarantorCorporationNameRef.current.value);
    }

    hideInvalidGuarantorCorporationNameMessage =  () => {
        this.setState({isInvalidGuarantorCorporationName: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorCorporationCapitalFund =  () => {
        this.setState({isInvalidGuarantorCorporationCapitalFund:this.isInvalidGuarantorCorporationCapitalFund()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorCorporationCapitalFund = () => {
        if(isEmpty(this.guarantorCorporationCapitalFundRef.current.value))
            return false;
        return isNaN(this.guarantorCorporationCapitalFundRef.current.value);
    }

    hideInvalidGuarantorCorporationCapitalFundMessage =  () => {
        this.setState({isInvalidGuarantorCorporationCapitalFund: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorCorporationSiren =  () => {
        this.setState({isInvalidGuarantorCorporationSiren:this.isInvalidGuarantorCorporationSiren()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorCorporationSiren = () => {
        if (isEmpty(this.guarantorCorporationSirenRef.current.value))
            return false;
        return !utils.fn_isValidSiren(this.guarantorCorporationSirenRef.current.value);
    }

    hideInvalidGuarantorCorporationSirenMessage =  () => {
        this.setState({isInvalidGuarantorCorporationSiren: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorCorporationSiret =  () => {
        this.setState({isInvalidGuarantorCorporationSiret:this.isInvalidGuarantorCorporationSiret()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorCorporationSiret = () => {
        if (isEmpty(this.guarantorCorporationSiretRef.current.value))
            return false;
        return !utils.fn_isValidSiret(this.guarantorCorporationSiretRef.current.value);
    }

    hideInvalidGuarantorCorporationSiretMessage =  () => {
        this.setState({isInvalidGuarantorCorporationSiret: false});
        this.disableGuarantorSubmitButton();
    }

    validateGuarantorCorporationVat =  () => {
        this.setState({isInvalidGuarantorCorporationVat:this.isInvalidGuarantorCorporationVat()});
        this.disableGuarantorSubmitButton();
    }

    isInvalidGuarantorCorporationVat = () => {
        if (isEmpty(this.guarantorCorporationVatRef.current.value))
            return false;
        return !utils.fn_isValidVat(this.guarantorCorporationVatRef.current.value);
    }

    hideInvalidGuarantorCorporationVatMessage =  () => {
        this.setState({isInvalidGuarantorCorporationVat: false});
        this.disableGuarantorSubmitButton();
    }

    validateIndividualEmergencyContactGender =  () => {
        this.setState({isInvalidIndividualEmergencyContactGender:this.isInvalidIndividualEmergencyContactGender()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactGender = () => {
        return isEmpty(this.individualEmergencyContactGenderRef.current.value);
    }

    hideInvalidIndividualEmergencyContactGenderMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactGender: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactFirstname =  () => {
        this.setState({isInvalidIndividualEmergencyContactFirstname:this.isInvalidIndividualEmergencyContactFirstname()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactFirstname = () => {
        return isEmpty(this.individualEmergencyContactFirstnameRef.current.value);
    }

    hideInvalidIndividualEmergencyContactFirstnameMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactFirstname: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactLastname =  () => {
        this.setState({isInvalidIndividualEmergencyContactLastname:this.isInvalidIndividualEmergencyContactLastname()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactLastname = () => {
        return isEmpty(this.individualEmergencyContactLastnameRef.current.value);
    }

    hideInvalidIndividualEmergencyContactLastnameMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactLastname: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactMobile =  () => {
        this.setState({isInvalidIndividualEmergencyContactMobile:this.isInvalidIndividualEmergencyContactMobile()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactMobile = () => {
        return utils.fn_isInValidMobile(this.individualEmergencyContactMobileRef.current.value);
    }

    hideInvalidIndividualEmergencyContactMobileMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactMobile: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactPhone =  () => {
        this.setState({isInvalidIndividualEmergencyContactPhone:this.isInvalidIndividualEmergencyContactPhone()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactPhone = () => {
        if(isEmpty(this.individualEmergencyContactPhoneRef.current.value))
            return false;

        return utils.fn_isInValidTel(this.individualEmergencyContactPhoneRef.current.value);
    }

    hideInvalidIndividualEmergencyContactPhoneMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactPhone: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactEmail =  () => {
        this.setState({isInvalidIndividualEmergencyContactEmail:this.isInvalidIndividualEmergencyContactEmail()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactEmail = () => {
        if(isEmpty(this.individualEmergencyContactEmailRef.current.value))
            return false;

        return utils.fn_isInValidEmail(this.individualEmergencyContactEmailRef.current.value);
    }

    hideInvalidIndividualEmergencyContactEmailMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactEmail: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactBirthDate =  () => {
        this.setState({isInvalidIndividualEmergencyContactBirthDate:this.isInvalidIndividualEmergencyContactBirthDate()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactBirthDate = () => {
        const birthDate = this.individualEmergencyContactBirthDateRef.current.value;
        if(isEmpty(birthDate))
            return true;

        let today = new Date();
        today.setHours(0,0,0,0);
        return utils.fn_isInValidDate(birthDate) || new Date(birthDate)>today;
    }

    hideInvalidIndividualEmergencyContactBirthDateMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactBirthDate: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactBirthPlace =  () => {
        this.setState({isInvalidIndividualEmergencyContactBirthPlace:this.isInvalidIndividualEmergencyContactBirthPlace()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactBirthPlace = () => {
        return isEmpty(this.individualEmergencyContactBirthPlaceRef.current.value);
    }

    hideInvalidIndividualEmergencyContactBirthPlaceMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactBirthPlace: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateIndividualEmergencyContactNationality =  () => {
        this.setState({isInvalidIndividualEmergencyContactNationality:this.isInvalidIndividualEmergencyContactNationality()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidIndividualEmergencyContactNationality = () => {
        return isEmpty(this.individualEmergencyContactNationalityRef.current.value);
    }

    hideInvalidIndividualEmergencyContactNationalityMessage =  () => {
        this.setState({isInvalidIndividualEmergencyContactNationality: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateCorporationEmergencyContactName =  () => {
        this.setState({isInvalidCorporationEmergencyContactName:this.isInvalidCorporationEmergencyContactName()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidCorporationEmergencyContactName = () => {
        return isEmpty(this.corporationEmergencyContactNameRef.current.value);
    }

    hideInvalidCorporationEmergencyContactNameMessage =  () => {
        this.setState({isInvalidCorporationEmergencyContactName: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateCorporationEmergencyContactCapitalFund =  () => {
        this.setState({isInvalidCorporationEmergencyContactCapitalFund:this.isInvalidCorporationEmergencyContactCapitalFund()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidCorporationEmergencyContactCapitalFund = () => {
        if(isEmpty(this.corporationEmergencyContactCapitalFundRef.current.value))
            return false;
        return isNaN(this.corporationEmergencyContactCapitalFundRef.current.value);
    }

    hideInvalidCorporationEmergencyContactCapitalFundMessage =  () => {
        this.setState({isInvalidCorporationEmergencyContactCapitalFund: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateCorporationEmergencyContactSiren =  () => {
        this.setState({isInvalidCorporationEmergencyContactSiren:this.isInvalidCorporationEmergencyContactSiren()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidCorporationEmergencyContactSiren = () => {
        if(isEmpty(this.corporationEmergencyContactSirenRef.current.value))
            return false;
        return !utils.fn_isValidSiren(this.corporationEmergencyContactSirenRef.current.value);
    }

    hideInvalidCorporationEmergencyContactSirenMessage =  () => {
        this.setState({isInvalidCorporationEmergencyContactSiren: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateCorporationEmergencyContactSiret =  () => {
        this.setState({isInvalidCorporationEmergencyContactSiret:this.isInvalidCorporationEmergencyContactSiret()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidCorporationEmergencyContactSiret = () => {
        if(isEmpty(this.corporationEmergencyContactSiretRef.current.value))
            return false;
        return !utils.fn_isValidSiret(this.corporationEmergencyContactSiretRef.current.value);
    }

    hideInvalidCorporationEmergencyContactSiretMessage =  () => {
        this.setState({isInvalidCorporationEmergencyContactSiret: false});
        this.disableEmergencyContactSubmitButton();
    }

    validateCorporationEmergencyContactVat =  () => {
        this.setState({isInvalidCorporationEmergencyContactVat:this.isInvalidCorporationEmergencyContactVat()});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidCorporationEmergencyContactVat = () => {
        if(isEmpty(this.corporationEmergencyContactVatRef.current.value))
            return false;
        return !utils.fn_isValidVat(this.corporationEmergencyContactVatRef.current.value);
    }

    hideInvalidCorporationEmergencyContactVatMessage =  () => {
        this.setState({isInvalidCorporationEmergencyContactVat: false});
        this.disableEmergencyContactSubmitButton();
    }

    isInvalidGuarantors = () => {
        return true === this.state.noGuarantorProvided;
    }

    isInvalidEmergencyContact = () => {
        return true === this.state.noEmergencyContactProvided;
    }

    isAtLeastOneIndividualEmergencyContactFieldsInvalid = () => {
        return this.state.isIndividualEmergencyContact && (this.isInvalidIndividualEmergencyContactGender()
            || this.isInvalidIndividualEmergencyContactFirstname() || this.isInvalidIndividualEmergencyContactLastname()
            || this.isInvalidIndividualEmergencyContactMobile() || this.isInvalidIndividualEmergencyContactPhone()
            || this.isInvalidIndividualEmergencyContactEmail() || this.isInvalidIndividualEmergencyContactBirthDate()
            || this.isInvalidIndividualEmergencyContactBirthPlace() || this.isInvalidIndividualEmergencyContactNationality()
            || this.isInvalidCorporationEmergencyContactVat()
        );
    }

    isAtLeastOneCorporationEmergencyContactFieldsInvalid = () => {
        return !this.state.isIndividualEmergencyContact && (this.isInvalidCorporationEmergencyContactName()
            || this.isInvalidIndividualEmergencyContactMobile() || this.isInvalidIndividualEmergencyContactPhone()
            || this.isInvalidIndividualEmergencyContactEmail() || this.isInvalidCorporationEmergencyContactCapitalFund()
            || this.isInvalidCorporationEmergencyContactSiren() || this.isInvalidCorporationEmergencyContactSiret()
        );
    }

    disableEmergencyContactSubmitButton = () =>{
        this.setState({
            isDisabledEmergencyContactSubmitButton: this.isAtLeastOneIndividualEmergencyContactFieldsInvalid()
            || this.isAtLeastOneCorporationEmergencyContactFieldsInvalid()
        });
    }

    isAtLeastOneIndividualGuarantorFieldsInvalid = () => {
        return this.state.isIndividualGuarantor && (this.isInvalidGuarantorGender() || this.isInvalidGuarantorLastname()
            || this.isInvalidGuarantorFirstname() || this.isInvalidGuarantorMobile() || this.isInvalidGuarantorPhone()
            || this.isInvalidGuarantorEmail() || this.isInvalidGuarantorBirthDate() || this.isInvalidGuarantorNationality()
            || this.isInvalidGuarantorBirthPlace());
    }

    isAtLeastOneCorporationGuarantorFieldsInvalid = () => {
        return !this.state.isIndividualGuarantor && (this.isInvalidGuarantorCorporationName()
        || this.isInvalidGuarantorMobile() || this.isInvalidGuarantorPhone()
            || this.isInvalidGuarantorEmail() || this.isInvalidGuarantorCorporationCapitalFund()
        || this.isInvalidGuarantorCorporationSiren() || this.isInvalidGuarantorCorporationSiret()
        || this.isInvalidGuarantorCorporationVat());
    }

    disableGuarantorSubmitButton = () =>{
        this.setState({
            isDisabledGuarantorSubmitButton: this.isAtLeastOneIndividualGuarantorFieldsInvalid()
            || this.isAtLeastOneCorporationGuarantorFieldsInvalid()
        });
    }

    isAtLeastOneTenantPersonalInfosFieldInvalid = () => {
        const isAtLeastOneTenantPersonalInfosFieldInvalid = this.isAtLeastOneIndividualTenantPersonalInfosFieldInvalid()
        || this.isAtLeastOneCorporationTenantPersonalInfosFieldInvalid();
        this.setState({isAtLeastOneTenantPersonalInfosFieldInvalid: isAtLeastOneTenantPersonalInfosFieldInvalid});
        return isAtLeastOneTenantPersonalInfosFieldInvalid;
    }

    isAtLeastOneIndividualTenantPersonalInfosFieldInvalid = () => {
        return this.state.isIndividualTenant
            &&(this.isInvalidTenantGender() || this.isInvalidTenantLastName() || this.isInvalidTenantFirstName()
                || this.isInvalidTenantBirthDate() || this.isInvalidTenantBirthPlace() || this.isInvalidTenantNationality()
                || this.isInvalidTenantEmail() || this.isInvalidTenantSecondEmail() || this.isInvalidTenantMobile()
                || this.isInvalidTenantPhone() || this.isInvalidTenantAddress() || this.isInvalidTenantIdentificationDocumentNumber()
                || this.isInvalidTenantIdentificationDocumentExpDate() || this.isInvalidUploadedFile())
    }

    isAtLeastOneCorporationTenantPersonalInfosFieldInvalid = () => {
        return !this.state.isIndividualTenant
            &&(this.isInvalidTenantCorporationName() || this.isInvalidTenantCorporationCapitalFund()|| this.isInvalidTenantCorporationSiret()
                || this.isInvalidTenantCorporationSiren() || this.isInvalidTenantCorporationVat()) || this.isInvalidTenantCorporationWebsite();
    }

    isAtLeastOneTenantFinancialInfosFieldInvalid = () => {
        const isAtLeastOneTenantFinancialInfosFieldInvalid = this.isInvalidGuarantors() ||
            (this.state.isIndividualTenant && (this.isInvalidTenantProfession() || this.isInvalidTenantIncomes()));
        this.setState({isAtLeastOneTenantFinancialInfosFieldInvalid: isAtLeastOneTenantFinancialInfosFieldInvalid});
        return isAtLeastOneTenantFinancialInfosFieldInvalid;
    }

    isAtLeastOneTenantDocumentInfosFieldInvalid = () => {
        const isAtLeastOneTenantDocumentInfosFieldInvalid = this.isInvalidEmergencyContact();
        this.setState({isAtLeastOneTenantDocumentInfosFieldInvalid: isAtLeastOneTenantDocumentInfosFieldInvalid});
        return isAtLeastOneTenantDocumentInfosFieldInvalid;
    }

    disableSubmitButton = () =>{
        this.setState({isDisabledSubmitButton:
        this.isAtLeastOneTenantPersonalInfosFieldInvalid()
        || this.isAtLeastOneTenantFinancialInfosFieldInvalid()
        || this.isAtLeastOneTenantDocumentInfosFieldInvalid()});
    }


    handleTenantSubmit = (event) => {
        event.preventDefault();
        if (this.state.identificationDocumentsQueuedFiles.length === 0){
            this.state.invalidUploadedFileMessage = "You must provide an identification document";
            this.setState({isInvalidUploadedFile: true});
            return;
        }
        if (0 === this.state.guarantors.length){
            this.setState({noGuarantorProvided: true});
            this.disableSubmitButton();
            return;
        }
        if (0 === this.state.emergencyContacts.length){
            this.setState({noEmergencyContactProvided: true});
            this.disableSubmitButton();
            return;
        }

        let formData = new FormData();
        const commonTenantData = this.buildCommonTenantData();
        commonTenantData['guarantors'] = this.state.guarantors;
        commonTenantData['emergencyContacts'] = this.state.emergencyContacts;
        commonTenantData['documents'] = this.state.documents;
        commonTenantData['identificationDocument'] = this.buildIdentificationDocument();
        if (this.state.isIndividualTenant){
            commonTenantData['type'] = 'individual';
            commonTenantData['employer'] = this.individualTenantEmployerRef.current.value;
            commonTenantData['employerAddress'] = this.tenantEmployerAddressRef.current.value;
            commonTenantData['contractType'] = this.individualTenantContractTypeRef.current.value;
            commonTenantData['monthlyIncome'] = this.tenantIncomesRef.current.value;
            commonTenantData['person'] = this.buildIndividualTenantData();
        }else{
            commonTenantData['type'] = 'corporation';
            commonTenantData['person'] = this.buildCorporationTenantData();
            commonTenantData['representative'] = this.buildCorporationTenantRepresentative();
            if(this.state.tenantDetails && this.state.tenantDetails.representative)
                commonTenantData['representative']['resourceUuid'] = this.state.tenantDetails.representative.resourceUuid;
        }
        if (this.state.tenantDetails && this.state.tenantDetails.person)
            commonTenantData['person']['resourceUuid'] = this.state.tenantDetails.person.resourceUuid;

        this.addTenantData(formData, commonTenantData);
        this.addTenantIdentificationDocumentFile(formData);
        this.addTenantDocumentsFiles(formData);
        this.addTenantPictureFile(formData);
        let tenantCreationPromise;
        const isTenantEdit = this.props.match.params.tenantUuid;
        if (this.state.isIndividualTenant){
            tenantCreationPromise = isTenantEdit?this.tenantController.updateIndividualTenant(this.state.tenantDetails.resourceUuid, formData)
                :this.tenantController.createIndividualTenant(formData);
        } else{
            tenantCreationPromise = isTenantEdit?this.tenantController.updateCorporationTenant(this.state.tenantDetails.resourceUuid, formData)
                :this.tenantController.createCorporationTenant(formData);
        }

        tenantCreationPromise
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            if (this.state.isIndividualTenant){
                                this.props.history.push(utils.contextualizePath('/tenant/individual/' + data.resourceUuid + '/details'));
                            }else {
                                this.props.history.push(utils.contextualizePath('/tenant/corporation/' + data.resourceUuid + '/details'));
                            }
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(422 === response.status){
                        response.json().then((data) => {
                            const toastMsg = utils.computeToastMessage(data);
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(toastMsg.messageDate), title: 'Invalid input', message: toastMsg.messageDetails})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            );
    }

    addTenantData = (formData, tenantData) => {
        formData.append('tenant', new Blob([JSON.stringify(tenantData)], {
            type: "application/json"
        }));
    }

    addTenantIdentificationDocumentFile = (formData) => {
        formData.append("identificationDocument", this.state.identificationDocumentsQueuedFiles[0]);
    }

    addTenantDocumentsFiles = (formData) => {
        for (let i = 0; i< this.state.documentFiles.length;i++){
            formData.append("documents", this.state.documentFiles[i]);
        }
    }

    addTenantPictureFile = (formData) => {
        formData.append("picture", this.state.pictureQueuedFiles[0]);
    }

    buildIndividualTenantData = () => {
        return {
            contacts: this.buildIndividualTenantContact(),
            bankAccounts: this.buildTenantBankAccount(),
            gender: this.tenantGenderRef.current.value,
            firstName: this.tenantFirstNameRef.current.value,
            surname: this.tenantLastNameRef.current.value,
            middlename: this.tenantMiddleNameRef.current.value,
            birthDate: this.tenantBirthDateRef.current.value,
            placeOfBirth: this.tenantBirthPlaceRef.current.value,
            nationality: this.tenantNationalityRef.current.value,
            profession: this.tenantProfessionRef.current.value,
        }
    }

    buildIndividualTenantContact = () => {
        return [
            {
                email : this.tenantEmailRef.current.value,
                secondEmail : this.tenantSecondEmailRef.current.value,
                mobile : this.tenantMobileRef.current.value,
                phone : this.tenantPhoneRef.current.value,
                address : this.tenantAddressRef.current.value,
            }
        ]
    }

    buildCorporationTenantContact = () => {
        return [
            {
                address : this.tenantEmployerAddressRef.current.value,
                contactInit : true,
            }
        ]
    }

    buildTenantBankAccount = () => {
        return [
            {
                bankName : this.tenantBanknameRef.current.value,
                address : this.tenantBankaddressRef.current.value,
                rib : this.tenantBankCompleteRibRef.current.value,
                iban : this.tenantBankibanRef.current.value,
                bic : this.tenantBankbicRef.current.value,
            }
        ]
    }

    buildCorporationTenantRepresentative = () => {
        return {
            contacts: this.buildIndividualTenantContact(),
            gender: this.tenantGenderRef.current.value,
            firstName: this.tenantFirstNameRef.current.value,
            surname: this.tenantLastNameRef.current.value,
            middle: this.tenantMiddleNameRef.current.value,
            birthDate: this.tenantBirthDateRef.current.value,
            placeOfBirth: this.tenantBirthPlaceRef.current.value,
            nationality: this.tenantNationalityRef.current.value,
        }
    }

    buildCorporationTenantData = () => {
        return {
            contacts: this.buildCorporationTenantContact(),
            bankAccounts: this.buildTenantBankAccount(),
            website:this.tenantCorporationWebsiteRef.current.value,
            name:this.tenantCorporationNameRef.current.value,
            legalStatus:this.tenantCorporationStatusRef.current.value,
            shareCapital:this.tenantCorporationCapitalFundRef.current.value,
            siren:this.tenantCorporationSirenRef.current.value,
            siret:this.tenantCorporationSiretRef.current.value,
            vatNumber:this.tenantCorporationVatRef.current.value,
            activityField:this.tenantCorporationActivityRef.current.value,
        }
    }

    buildCommonTenantData = () => {
        return  {
            privateComment: this.tenantPrivateCommentRef.current.value,
            followupAddress: this.tenantFollowupAddressRef.current.value,
            reference: this.tenantReferenceRef.current.value,
        }
    }

    closeTenantCreation = () => {
        this.props.history.goBack();
    }

    fetchTenantDetails = () => {
        const isIndividualTenantEdit = this.props.match.params.tenantUuid;
        if (isIndividualTenantEdit){
            const isIndividualTenant = window.location.href.indexOf('individual') !== -1;
            if (true === isIndividualTenant){
                this.getIndividualTenantDetails();
                window.jQuery('#isIndividual').click();
            } else {
                this.getCorporationTenantDetails();
                window.jQuery('#isCompany').click();
            }
        }
    }

    getIndividualTenantDetails = () => {
        this.tenantController.getIndividualTenantDetails(this.props.match.params.tenantUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({
                                tenantDetails: data,
                                isIndividualTenant:true,
                                guarantors:data.guarantors,
                                tenantPersonDetails:data.person,
                                emergencyContacts:data.emergencyContacts,
                                documents:this.buildDocumentsForUpdate(data.documents)
                            });
                            this.updateIdentificationDocumentQueue(data.identificationDocument);
                            this.updateTenantPictureQueue(data);
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        response.json().then((data) => {
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No Tenant with id : ' + this.props.match.params.tenantUuid + ' found.'})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing your request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing your request. Details are"})
                }
            )
    }

    getCorporationTenantDetails = () => {
        this.tenantController.getCorporationTenantDetails(this.props.match.params.tenantUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({
                                tenantDetails: data,
                                isIndividualTenant:false,
                                guarantors:data.guarantors,
                                tenantPersonDetails:data.representative,
                                emergencyContacts:data.emergencyContacts,
                                documents:this.buildDocumentsForUpdate(data.documents)
                            });
                            this.updateIdentificationDocumentQueue(data.identificationDocument);
                            this.updateTenantPictureQueue(data);
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        response.json().then((data) => {
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No Tenant with id : ' + this.props.match.params.tenantUuid + ' found.'})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing your request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing your request. Details are"})
                }
            )
    }

    updateTenantPictureQueue = (data) => {
        const fileData = data.picture?data.picture:{}
        if (isEmpty(fileData))
            return;
        const url = utils.proxify(constants.restApiEndpoints.FILE_DATA) + fileData.resourceUuid;
        this.tenantController.getFileAsByte(url)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.arrayBuffer().then((data) => {
                            let pictureQueuedFiles = {};
                            let file = new File([new Uint8Array(data)], fileData.label);
                            utils.updateQueuedFiles(file, pictureQueuedFiles);
                            this.setState({
                                pictureQueuedFiles:pictureQueuedFiles
                            });
                        });
                    } else {
                        return undefined;
                    }
                },
                (error) => {
                    return undefined;
                }
            );
    }

    updateIdentificationDocumentQueue = (identificationDocument) => {
        const fileData = identificationDocument.fileData?identificationDocument.fileData:{}
        const url = utils.proxify(constants.restApiEndpoints.FILE_DATA) + fileData.resourceUuid;
        this.tenantController.getFileAsByte(url)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.arrayBuffer().then((data) => {
                            let identificationDocumentsQueuedFiles = {};
                            let file = new File([new Uint8Array(data)], fileData.label);
                            utils.updateQueuedFiles(file, identificationDocumentsQueuedFiles);
                            this.setState({
                                identificationDocumentsQueuedFiles:identificationDocumentsQueuedFiles
                            });
                        });
                    } else {
                        return undefined;
                    }
                },
                (error) => {
                    return undefined;
                }
            );
    }

    buildDocumentsForUpdate = (documents) => {
        let result = [];
        for(let i=0; i<documents.length; i++){
            const fileData = documents[i].fileData?documents[i].fileData:{};
            const row = {
                resourceUuid: documents[i].resourceUuid,
                type: documents[i].type,
                description: documents[i].description,
                uploaddate: documents[i].uploadDate,
                metaData: {
                    fileName: fileData.label,
                    fileUuid: fileData.resourceUuid,
                }
            }
            result.push(row);
            this.updateDocumentFiles(fileData);
        }
        return result;
    }

    updateDocumentFiles = (fileData) => {
        const url = utils.proxify(constants.restApiEndpoints.FILE_DATA) + fileData.resourceUuid;
        this.tenantController.getFileAsByte(url)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.arrayBuffer().then((data) => {
                            let oldDocumentFiles = [];
                            oldDocumentFiles = this.state.documentFiles;
                            let file = new File([new Uint8Array(data)], fileData.label);
                            oldDocumentFiles.push(file);
                            this.setState({documentFiles:oldDocumentFiles});
                        });
                    } else {
                        return undefined;
                    }
                },
                (error) => {
                    return undefined;
                }
            );
    }

    deleteOldIdentificationDocument = () => {
        let tenantDetails = this.state.tenantDetails;
        let oldIdentificationDocumentsQueueFiles =  this.state.identificationDocumentsQueuedFiles;
        tenantDetails.identificationDocument.fileData = null;
        delete oldIdentificationDocumentsQueueFiles[0];
        this.setState({identificationDocumentsQueuedFiles:oldIdentificationDocumentsQueueFiles, tenantDetails:tenantDetails});
    }

    displayIdentificationDocument =  (identificationDocumentData) => {
        const gallery = this.gallery;
        const url = constants.restApiEndpoints.FILE_DATA;
        return <ImageFromBytes url={url} gallery={gallery}
                               key={identificationDocumentData.label}
                               name={identificationDocumentData.label}
                               resourceUuid={identificationDocumentData.resourceUuid}
                               index={0} readOnly={false}
                               delete ={this.deleteOldIdentificationDocument}
        />
    }

    displayTenantPicture =  (pictureData) => {
        const gallery = this.gallery;
        const url = constants.restApiEndpoints.FILE_DATA;
        const deleteFileFunction = key => this.deletePictureFile(key);
        if (!isEmpty(this.state.tenantDetails.picture))
            window.jQuery('#tenant-picture-container').hide();
        return <ImageFromBytes url={url} gallery={gallery}
                               key={pictureData.label}
                               name={pictureData.label}
                               resourceUuid={pictureData.resourceUuid}
                               index={0} readOnly={false}
                               delete ={() => deleteFileFunction(0)}
        />
    }

    downloadFile = (resourceUuid, fileName, index) => {
        if(isEmpty(resourceUuid)){
            const file = isEmpty(this.state.documentFiles)?{}:this.state.documentFiles[index];
            const downloadUrl = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(downloadUrl);
        } else {
            const url = utils.proxify(constants.restApiEndpoints.FILE_DATA) + resourceUuid;
            this.tenantController.getFileAsByte(url)
                .then(
                    (response) => {
                        if (200 === response.status) {
                            response.blob().then((data) => {
                                const downloadUrl = URL.createObjectURL(data);
                                const link = document.createElement("a");
                                link.href = downloadUrl;
                                link.download = fileName;
                                link.click();
                                URL.revokeObjectURL(downloadUrl);
                            });
                        } else {
                            return undefined;
                        }
                    },
                    (error) => {
                        return undefined;
                    }
                );
        }
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar history={this.props.history}/>

                        <form  onSubmit={this.handleTenantSubmit}
                        >
                        <div className="card mb-3">
                            <div className="card-body d-flex flex-wrap flex-between-center">
                                <div>
                                    <h5 className="mb-0 text-primary"> <i className="far fa-user-circle"/> Création nouveau Locataire</h5>
                                </div>
                            </div>
                        </div>
                                <div className="card overflow-hidden mt-3">
                                    <div className="card-header p-0 bg-light scrollbar-overlay">
                                        <ul className="nav nav-tabs border-0 tab-tickets-status flex-nowrap" id="in-depth-chart-tab" role="tablist">
                                            <li className="nav-item text-nowrap" role="presentation">
                                                <a className="nav-link mb-0 d-flex align-items-center gap-2 py-3 px-x1 active"
                                                   id="tenant-main-infos-tab" data-bs-toggle="tab" href="#tenant-main-infos" role="tab" aria-controls="tenant-main-infos" aria-selected="true">
                                                    <span className="nav-item-circle"/>
                                                    <span className="fas fa-user"/> <span/>
                                                    <h6 className="mb-0 text-600">Informations personnelles</h6>
                                                    {
                                                        this.state.isAtLeastOneTenantPersonalInfosFieldInvalid?<span><FieldsValidityIconDanger/></span>:""
                                                    }
                                                    {
                                                        !this.state.isAtLeastOneTenantPersonalInfosFieldInvalid?<span><FieldsValidityIconSuccess/></span>:""
                                                    }
                                                </a>
                                        </li>
                                        <li className="nav-item text-nowrap" role="presentation">
                                            <a className="nav-link mb-0 d-flex align-items-center gap-2 py-3 px-x1" id="tenant-financial-infos-tab" data-bs-toggle="tab" href="#tenant-financial-infos" role="tab" aria-controls="tenant-financial-infos" aria-selected="false">
                                                <span className="nav-item-circle-parent">
                                                <span className="nav-item-circle">
                                                <span className="fas fa-money-bill-wave"/>
                                                </span>
                                                </span>
                                                <h6 className="mb-0 text-600">Situation financière</h6>
                                                {
                                                    this.state.isAtLeastOneTenantFinancialInfosFieldInvalid?<span><FieldsValidityIconDanger/></span>:""
                                                }
                                                {
                                                    !this.state.isAtLeastOneTenantFinancialInfosFieldInvalid?<span><FieldsValidityIconSuccess/></span>:""
                                                }
                                            </a>
                                        </li>
                                        <li className="nav-item text-nowrap" role="presentation">
                                            <a className="nav-link mb-0 d-flex align-items-center gap-2 py-3 px-x1" id="tenant-docs-and-contacts-tab" data-bs-toggle="tab" href="#tenant-docs-and-contacts" role="tab" aria-controls="tenant-docs-and-contacts" aria-selected="false">
                                                    <span className="nav-item-circle-parent">
                                                    <span className="nav-item-circle">
                                                    <span className="fas fa-folder"/>
                                                    </span>
                                                    </span>
                                                <h6 className="mb-0 text-600">Documents et contacts</h6>
                                                {
                                                    this.state.isAtLeastOneTenantDocumentInfosFieldInvalid?<span><FieldsValidityIconDanger/></span>:""
                                                }
                                                {
                                                    !this.state.isAtLeastOneTenantDocumentInfosFieldInvalid?<span><FieldsValidityIconSuccess/></span>:""
                                                }
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body p-0">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tenant-main-infos" role="tabpanel" aria-labelledby="tenant-main-infos-tab">
                                            <div className="row mx-0">
                                            <div className="mb-3 mt-3" >
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <input type="radio" id="isIndividual" name="type" value="particulier"
                                                               ref={this.tenantTypeIndividualRef}
                                                               onChange={this.toggleTenantTypeFields}
                                                               checked={this.state.isIndividualTenant}
                                                        />
                                                        <label htmlFor="isIndividual" style={{marginRight: '20pt'}}>Particulier</label>
                                                        <input type="radio" id="isCompany" name="type" value="entreprise"
                                                               ref={this.tenantTypeCompanyRef}
                                                               onChange={this.toggleTenantTypeFields}
                                                               checked={!this.state.isIndividualTenant}
                                                        />
                                                        <label htmlFor="isCompany">Entreprise</label>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label htmlFor="tenant-reference">&nbsp;Référence</label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <input type="text" className="form-control"
                                                                       id="tenant-reference"
                                                                       name="tenantReference"
                                                                       ref={this.tenantReferenceRef}
                                                                       defaultValue={isEmpty(this.state.tenantDetails)?'':this.state.tenantDetails.reference}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <fieldset id="companyFields" style={{display:'none'}} >
                                                <legend>Informations de l'Entreprise</legend>
                                                <div className="mb-3">
                                                    <label htmlFor="companyName">Dénomination :</label>
                                                    <input type="text" className="form-control" id="companyName"
                                                           name="companyName"
                                                           ref={this.tenantCorporationNameRef}
                                                           onBlur={this.validateTenantCorporationName}
                                                           onFocus={this.hideInvalidTenantCorporationNameMessage}
                                                           defaultValue={this.state.tenantDetails.person?this.state.tenantDetails.person.name: ''}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantCorporationName?'':'none'}} className="failed-signin-message" >Invalid tenant company name</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="legalForm">Forme Juridique :</label>
                                                    <select className="form-control"
                                                            id="legalForm"
                                                            name="legalForm"
                                                            ref={this.tenantCorporationStatusRef}
                                                    >
                                                        {
                                                            this.state.companyLegalStatuses.map(status => {
                                                                return <option key={status}
                                                                               value={status}
                                                                               selected={this.state.tenantDetails.person &&
                                                                               (this.state.tenantDetails.person.legalStatus === status)}
                                                                > {status} </option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="capital">Capital social :</label>
                                                    <input type="text" className="form-control" id="capital"
                                                           name="capital"
                                                           ref={this.tenantCorporationCapitalFundRef}
                                                           onBlur={this.validateTenantCorporationCapitalFund}
                                                           onFocus={this.hideInvalidTenantCorporationCapitalFundMessage}
                                                           defaultValue={this.state.tenantDetails.person?this.state.tenantDetails.person.shareCapital: ''}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantCorporationCapitalFund?'':'none'}} className="failed-signin-message" >Invalid tenant company capital fund</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="siren">SIREN :</label>
                                                    <input type="text" className="form-control" id="siren"
                                                           name="siren"
                                                           ref={this.tenantCorporationSirenRef}
                                                           onBlur={this.validateTenantCorporationSiren}
                                                           onFocus={this.hideInvalidTenantCorporationSirenMessage}
                                                           defaultValue={this.state.tenantDetails.person?this.state.tenantDetails.person.siren: ''}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantCorporationSiren?'':'none'}} className="failed-signin-message" >Invalid tenant company siren</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="siret">SIRET :</label>
                                                    <input type="text" className="form-control" id="siret"
                                                           name="siret"
                                                           ref={this.tenantCorporationSiretRef}
                                                           onBlur={this.validateTenantCorporationSiret}
                                                           onFocus={this.hideInvalidTenantCorporationSiretMessage}
                                                           defaultValue={this.state.tenantDetails.person?this.state.tenantDetails.person.siret: ''}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantCorporationSiret?'':'none'}} className="failed-signin-message" >Invalid tenant company siret</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="vatNumber">Numéro de TVA :</label>
                                                    <input type="text" className="form-control" id="vatNumber"
                                                           name="vatNumber"
                                                           ref={this.tenantCorporationVatRef}
                                                           onBlur={this.validateTenantCorporationVat}
                                                           onFocus={this.hideInvalidTenantCorporationVatMessage}
                                                           defaultValue={this.state.tenantDetails.person?this.state.tenantDetails.person.vatNumber: ''}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantCorporationVat?'':'none'}} className="failed-signin-message" >Invalid tenant company VAT</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="activity">Domaine d'activité :</label>
                                                    <input type="activity" className="form-control"
                                                           id="activity"
                                                           name="activity"
                                                           ref={this.tenantCorporationActivityRef}
                                                           defaultValue={this.state.tenantDetails.person?this.state.tenantDetails.person.activityField: ''}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="website">Site web :</label>
                                                    <input type="url" className="form-control" id="website"
                                                           name="website"
                                                           ref={this.tenantCorporationWebsiteRef}
                                                           onBlur={this.validateTenantCorporationWebsite}
                                                           onFocus={this.hideInvalidTenantCorporationWebsiteMessage}
                                                           defaultValue={isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.website}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantCorporationWebsite?'':'none'}} className="failed-signin-message" >Invalid tenant company website</p>
                                                </div>
                                                <hr/>
                                            </fieldset>
                                            <fieldset>
                                                <legend>{this.state.individualInformationsTitle}</legend>
                                                <div className="row">
                                                    <div className="mb-3 col-md-9">
                                                        <div className="mb-3">
                                                            <label htmlFor="civility">Civilité :</label>
                                                            <select className="form-control" id="civility" name="civility"
                                                                    ref={this.tenantGenderRef}
                                                                    onBlur={this.validateTenantGender}
                                                                    onFocus={this.hideInvalidTenantGenderMessage}
                                                                    defaultValue={isEmpty(this.state.tenantPersonDetails)?'':
                                                                        this.state.tenantPersonDetails.gender}
                                                            >
                                                                <option
                                                                    value=""
                                                                    selected={!isEmpty(this.state.tenantPersonDetails)
                                                                    && this.state.tenantPersonDetails.gender === ''}
                                                                />
                                                                <option
                                                                    value="MR"
                                                                    selected={!isEmpty(this.state.tenantPersonDetails)
                                                                        && this.state.tenantPersonDetails.gender === 'MR'}
                                                                >M.</option>
                                                                <option
                                                                    value="MS"
                                                                    selected={!isEmpty(this.state.tenantPersonDetails)
                                                                    && this.state.tenantPersonDetails.gender === 'MS'}
                                                                >Mlle</option>
                                                                <option
                                                                    value="MRS"
                                                                    selected={!isEmpty(this.state.tenantPersonDetails)
                                                                    && this.state.tenantPersonDetails.gender === 'MRS'}
                                                                >Mme</option>
                                                            </select>
                                                            <p style={{display: this.state.isInvalidTenantGender?'':'none'}} className="failed-signin-message" >
                                                                {this.state.isIndividualTenant?'Invalid tenant gender':'Invalid tenant representative gender'}
                                                            </p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="lastName">Nom de famille :</label>
                                                            <input type="text" className="form-control" id="lastName"
                                                                   name="lastName"
                                                                   ref={this.tenantLastNameRef}
                                                                   onBlur={this.validateTenantLastName}
                                                                   onFocus={this.hideInvalidTenantLastNameMessage}
                                                                   defaultValue={!isEmpty(this.state.tenantPersonDetails)?
                                                                       this.state.tenantPersonDetails.surname:''}
                                                            />
                                                            <p style={{display: this.state.isInvalidTenantLastName?'':'none'}} className="failed-signin-message" >
                                                                {this.state.isIndividualTenant?'Invalid tenant last name':'Invalid tenant representative last name'}
                                                            </p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="firstName">Prénom :</label>
                                                            <input type="text" className="form-control" id="firstName"
                                                                   name="firstName"
                                                                   ref={this.tenantFirstNameRef}
                                                                   onBlur={this.validateTenantFirstName}
                                                                   onFocus={this.hideInvalidTenantFirstNameMessage}
                                                                   defaultValue={!isEmpty(this.state.tenantPersonDetails)?
                                                                       this.state.tenantPersonDetails.firstName:''}
                                                            />
                                                            <p style={{display: this.state.isInvalidTenantFirstName?'':'none'}} className="failed-signin-message" >
                                                                {this.state.isIndividualTenant?'Invalid tenant first name':'Invalid tenant representative first name'}
                                                            </p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="middleName">Deuxième prénom :</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   id="middleName"
                                                                   name="middleName"
                                                                   ref={this.tenantMiddleNameRef}
                                                                   defaultValue={isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.middlename}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="card mb-3 col-md-3 notCompany">
                                                        <div className="card-header">
                                                            <h5 className="mb-0">Ajouter une Photo
                                                                <span data-bs-toggle="tooltip" data-bs-placement="top" title="Ajouter une photo">
                                                                    <span className="fas fa-info-circle text-primary fs-0 ms-2"/> </span>
                                                            </h5>
                                                        </div>

                                                        <div>
                                                            {isEmpty(this.state.tenantDetails.picture)?this.displayTenantPicturePreview():this.displayTenantPicture(this.state.tenantDetails.picture)}
                                                        </div>

                                                        <div className="card-body bg-light">
                                                            <div id="tenant-picture-container" className="dropzone">
                                                            </div>
                                                            <p style={{display: this.state.isInvalidTenantPictureFile?'':'none'}} className="failed-signin-message" >{this.state.invalidUploadedPictureFileMessage}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row notCompany">
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label" htmlFor="birthDate">Date de naissance :</label>
                                                        <input className="form-control datetimepicker" id="birthDate"
                                                               name="birthDate"
                                                               type="text" placeholder="jj/mm/aaaa"
                                                               data-options='{"disableMobile":true}'
                                                               ref={this.tenantBirthDateRef}
                                                               onBlur={this.validateTenantBirthDate}
                                                               onFocus={this.hideInvalidTenantBirthDateMessage}
                                                               defaultValue={!isEmpty(this.state.tenantPersonDetails)?
                                                                   this.state.tenantPersonDetails.birthDate:''}
                                                        />
                                                        <p style={{display: this.state.isInvalidTenantBirthDate?'':'none'}} className="failed-signin-message" >
                                                            {this.state.isIndividualTenant?'Invalid tenant birth date':'Invalid tenant representative birth date'}
                                                        </p>
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label htmlFor="birthPlace">Lieu de naissance :</label>
                                                        <input type="text" className="form-control" id="birthPlace"
                                                               name="birthPlace"
                                                               ref={this.tenantBirthPlaceRef}
                                                               onBlur={this.validateTenantBirthPlace}
                                                               onFocus={this.hideInvalidTenantBirthPlaceMessage}
                                                               defaultValue={!isEmpty(this.state.tenantPersonDetails)?
                                                                   this.state.tenantPersonDetails.placeOfBirth:''}
                                                        />
                                                        <p style={{display: this.state.isInvalidTenantBirthPlace?'':'none'}} className="failed-signin-message" >
                                                            {this.state.isIndividualTenant?'Invalid tenant birth place':'Invalid tenant representative birth place'}
                                                        </p>
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label htmlFor="nationality">Nationalité :</label>
                                                        <input className="form-control"
                                                               id="country"
                                                               name="country"
                                                               list="nationalities"
                                                               onInput={() => this.fetchCountries(this.tenantNationalityRef.current.value)}
                                                               autoComplete="off"
                                                               placeholder="France"
                                                               ref={this.tenantNationalityRef}
                                                               onBlur={this.validateTenantNationality}
                                                               onFocus={this.hideInvalidTenantNationalityMessage}
                                                               defaultValue={!isEmpty(this.state.tenantPersonDetails)?
                                                                   this.state.tenantPersonDetails.nationality:''}
                                                        />
                                                        <p style={{display: this.state.isInvalidTenantNationality?'':'none'}} className="failed-signin-message" >
                                                            {this.state.isIndividualTenant?'Invalid tenant nationality':'Invalid tenant representative nationality'}
                                                        </p>
                                                        <datalist
                                                            id="nationalities"
                                                            className="form-label"
                                                        >
                                                            <option value=""/>
                                                            {
                                                                this.state.countries.map(country => {
                                                                    return <option key={country.codeIso3}
                                                                                   value={country.frName}
                                                                                   defaultValue={!isEmpty(this.state.tenantPersonDetails) && this.state.tenantPersonDetails.nationality}
                                                                    > {country.frName} </option>
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <hr/>
                                                    <legend>Coordonnées</legend>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="email">Adresse e-mail  :</label>
                                                            <input type="email" className="form-control" id="email"
                                                                   name="email"
                                                                   ref={this.tenantEmailRef}
                                                                   onBlur={this.validateTenantEmail}
                                                                   onFocus={this.hideInvalidTenantEmailMessage}
                                                                   defaultValue={(!isEmpty(this.state.tenantPersonDetails)
                                                                   &&!isEmpty(this.state.tenantPersonDetails.contacts))?
                                                                       this.state.tenantPersonDetails.contacts[0].email:''}
                                                            />
                                                            <p style={{display: this.state.isInvalidTenantEmail?'':'none'}} className="failed-signin-message" >
                                                                {this.state.isIndividualTenant?'Invalid tenant email':'Invalid tenant representative email'}
                                                            </p>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="email">Adresse e-mail alternative :</label>
                                                            <input type="email" className="form-control" id="email"
                                                                   name="email"
                                                                   ref={this.tenantSecondEmailRef}
                                                                   onBlur={this.validateTenantSecondEmail}
                                                                   onFocus={this.hideInvalidTenantSecondEmailMessage}
                                                                   defaultValue={(!isEmpty(this.state.tenantPersonDetails)
                                                                       &&!isEmpty(this.state.tenantPersonDetails.contacts))?
                                                                       this.state.tenantPersonDetails.contacts[0].secondEmail:''}
                                                            />
                                                            <p style={{display: this.state.isInvalidTenantSecondEmail?'':'none'}} className="failed-signin-message" >
                                                                {this.state.isIndividualTenant?'Invalid tenant second email':'Invalid tenant representative second email'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="mobileNumber">Numéro de mobile  :</label>
                                                            <input type="tel" className="form-control" id="mobileNumber"
                                                                   name="mobileNumber"
                                                                   ref={this.tenantMobileRef}
                                                                   onBlur={this.validateTenantMobile}
                                                                   onFocus={this.hideInvalidTenantMobileMessage}
                                                                   defaultValue={(!isEmpty(this.state.tenantPersonDetails)
                                                                       &&!isEmpty(this.state.tenantPersonDetails.contacts))?
                                                                       this.state.tenantPersonDetails.contacts[0].mobile:''}
                                                            />
                                                            <p style={{display: this.state.isInvalidTenantMobile?'':'none'}} className="failed-signin-message" >
                                                                {this.state.isIndividualTenant?'Invalid tenant mobile number':'Invalid tenant representative mobile number'}
                                                            </p>
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="landlineNumber">Numéro de ligne fixe  :</label>
                                                            <input type="tel" className="form-control" id="landlineNumber"
                                                                   name="landlineNumber"
                                                                   ref={this.tenantPhoneRef}
                                                                   onBlur={this.validateTenantPhone}
                                                                   onFocus={this.hideInvalidTenantPhoneMessage}
                                                                   defaultValue={(!isEmpty(this.state.tenantPersonDetails)
                                                                       &&!isEmpty(this.state.tenantPersonDetails.contacts))?
                                                                       this.state.tenantPersonDetails.contacts[0].phone:''}
                                                            />
                                                            <p style={{display: this.state.isInvalidTenantPhone?'':'none'}} className="failed-signin-message" >
                                                                {this.state.isIndividualTenant?'Invalid tenant phone number':'Invalid tenant representative phone number'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="address">Adresse  :</label>
                                                        <input className="form-control" id="address"
                                                               name="address"
                                                               ref={this.tenantAddressRef}
                                                               onBlur={this.validateTenantAddress}
                                                               onFocus={this.hideInvalidTenantAddressMessage}
                                                               defaultValue={(!isEmpty(this.state.tenantPersonDetails)
                                                                   &&!isEmpty(this.state.tenantPersonDetails.contacts))?
                                                                   this.state.tenantPersonDetails.contacts[0].address:''}
                                                        />
                                                        <p style={{display: this.state.isInvalidTenantAddress?'':'none'}} className="failed-signin-message" >
                                                            {this.state.isIndividualTenant?'Invalid tenant address':'Invalid tenant representative address'}
                                                        </p>
                                                    </div>
                                            </fieldset>
                                            <fieldset className="notCompany">
                                                <hr/>
                                                    <legend>Pièce d'identité</legend>
                                                    <div className="mb-3">
                                                        <label htmlFor="identityType">Type de pièce d'identité :</label>
                                                        <select className="form-control"
                                                                id="identityType"
                                                                name="identityType"
                                                                ref={this.tenantIdentificationDocumentTypeRef}
                                                        >
                                                            {
                                                                this.state.identificationDocumentTypes.map((identificationDocument, index) => {
                                                                    return <option
                                                                        key={index}
                                                                        value={identificationDocument}
                                                                        selected={isEmpty(this.state.tenantDetails.identificationDocument)?'':
                                                                            this.state.tenantDetails.identificationDocument.type === identificationDocument }
                                                                    > {utils.i18n(identificationDocument, 'en' )} </option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="identityNumber">Numéro de la pièce d'identité :</label>
                                                        <input type="text" className="form-control" id="identityNumber"
                                                               name="identityNumber"
                                                               ref={this.tenantIdentificationDocumentNumberRef}
                                                               onBlur={this.validateTenantIdentificationDocumentNumber}
                                                               onFocus={this.hideInvalidTenantIdentificationDocumentNumberMessage}
                                                               defaultValue={isEmpty(this.state.tenantDetails.identificationDocument)?'':
                                                                   this.state.tenantDetails.identificationDocument.registrationNumber}
                                                        />
                                                        <p style={{display: this.state.isInvalidTenantIdentificationDocumentNumber?'':'none'}} className="failed-signin-message" >
                                                            {this.state.isIndividualTenant?'Invalid tenant identification document number':'Invalid tenant representative identification document number'}
                                                        </p>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="identityExpiry">Date d'expiration de la pièce d'identité :</label>
                                                        <input className="form-control datetimepicker" id="identityExpirationDate"
                                                               name="identityExpirationDate"
                                                               type="text" placeholder="jj/mm/aaaa"
                                                               data-options='{"disableMobile":true}'
                                                               ref={this.tenantIdentificationDocumentExpDateRef}
                                                               onBlur={this.validateTenantIdentificationDocumentExpDate}
                                                               onFocus={this.hideInvalidTenantIdentificationDocumentExpDateMessage}
                                                               defaultValue={isEmpty(this.state.tenantDetails.identificationDocument)?'':
                                                                   this.state.tenantDetails.identificationDocument.expirationDate}
                                                        />
                                                        <p style={{display: this.state.isInvalidTenantIdentificationDocumentExpDate?'':'none'}} className="failed-signin-message" >
                                                            {this.state.isIndividualTenant?'Invalid tenant identification document expiration date':'Invalid tenant representative identification document expiration date'}
                                                        </p>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="identification-document">Fichier de la pièce d'identité</label>
                                                        <p style={{display: this.state.isInvalidUploadedFile?'':'none'}} className="failed-signin-message" >{this.state.invalidUploadedFileMessage}</p>
                                                        <div id="identification-document" className="col-md-12 dropzone">
                                                            {this.state.tenantDetails.identificationDocument && this.state.tenantDetails.identificationDocument.fileData?
                                                             this.displayIdentificationDocument(this.state.tenantDetails.identificationDocument.fileData):this.displayIdentificationDocumentPreview()}
                                                        </div>
                                                    </div>
                                            </fieldset>
                                            <fieldset>
                                                <hr/>
                                                    <legend>Notes</legend>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="privateNote">Note privée :</label>
                                                        <br/>
                                                            <span><i>Vous pouvez ajouter des informations concernant le locataire</i></span>
                                                            <textarea className="form-control"
                                                                      id="privateNote"
                                                                      name="privateNote"
                                                                      ref={this.tenantPrivateCommentRef}
                                                                      rows="10"
                                                                      placeholder="Exemple : Jeune diplômé, très sérieux"
                                                                      defaultValue={this.state.tenantDetails.privateComment}
                                                            />
                                                    </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tenant-financial-infos" role="tabpanel" aria-labelledby="tenant-financial-infos-tab">
                                        <div className="row mx-0">
                                            <fieldset className="mt-3 notCompany">
                                                <legend>Informations Professionnelles</legend>
                                                <div className="mb-3">
                                                    <label htmlFor="profession">Profession :</label>
                                                    <input disabled={!this.state.isIndividualTenant} type="text" className="form-control" id="profession"
                                                           name="profession"
                                                           ref={this.tenantProfessionRef}
                                                           onBlur={this.validateTenantProfession}
                                                           onFocus={this.hideInvalidTenantProfessionMessage}
                                                           defaultValue={isEmpty(this.state.tenantDetails.person)?'':this.state.tenantDetails.person.profession}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantProfession?'':'none'}} className="failed-signin-message" > Invalid tenant profession</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="contractType">Type de contrat :</label>
                                                    <select disabled={!this.state.isIndividualTenant}
                                                            className="form-control"
                                                            id="contractType"
                                                            name="contractType"
                                                            ref={this.individualTenantContractTypeRef}
                                                    >
                                                        {
                                                            this.state.contractTypes.map((contractType, index) => {
                                                                return <option key={index}
                                                                               value={contractType}
                                                                               selected={contractType === this.state.tenantDetails.contractType}
                                                                > {utils.i18n(contractType, 'en' )} </option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="monthlyIncome">Revenus mensuels :</label>
                                                    <input disabled={!this.state.isIndividualTenant} type="text" className="form-control" id="monthlyIncome"
                                                           name="monthlyIncome"
                                                           ref={this.tenantIncomesRef}
                                                           onBlur={this.validateTenantIncomes}
                                                           onFocus={this.hideInvalidTenantIncomesMessage}
                                                           defaultValue={this.state.tenantDetails.monthlyIncome}
                                                    />
                                                    <p style={{display: this.state.isInvalidTenantIncomes?'':'none'}} className="failed-signin-message" > Invalid tenant incomes</p>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="employer">Employeur :</label>
                                                    <input disabled={!this.state.isIndividualTenant}
                                                           type="text"
                                                           id="employer"
                                                           name="employer"
                                                           className="form-control"
                                                           ref={this.individualTenantEmployerRef}
                                                           defaultValue={this.state.tenantDetails.employer}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="professionalAddress">Adresse professionnelle :</label>
                                                    <input disabled={!this.state.isIndividualTenant}
                                                           type="text"
                                                           className="form-control"
                                                           id="professionalAddress"
                                                           name="professionalAddress"
                                                           ref={this.tenantEmployerAddressRef}
                                                           defaultValue={this.state.tenantDetails.employerAddress}
                                                    />
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <hr/>
                                                    <legend>Compte Bancaire</legend>
                                                    <div className="mb-3">
                                                        <label htmlFor="bankName">Nom de la banque :</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               id="bankName"
                                                               name="bankName"
                                                               ref={this.tenantBanknameRef}
                                                               defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts
                                                               && !isEmpty(this.state.tenantDetails.person.bankAccounts)?this.state.tenantDetails.person.bankAccounts[0].bankName:''}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="bankAddress">Adresse de la banque :</label>
                                                        <input
                                                            className="form-control"
                                                            id="bankAddress"
                                                            name="bankAddress"
                                                            ref={this.tenantBankaddressRef}
                                                            defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts
                                                            && !isEmpty(this.state.tenantDetails.person.bankAccounts)? this.state.tenantDetails.person.bankAccounts[0].address:''}
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-3 col-md-2">
                                                            <label htmlFor="bankCode">Code banque :</label>
                                                            <input disabled={true} type="text"
                                                                   className="form-control"
                                                                   id="bankCode"
                                                                   name="bankCode"
                                                                   ref={this.tenantBankcodeRef}
                                                                   defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts && !isEmpty(this.state.tenantDetails.person.bankAccounts)?
                                                                       utils.fn_getBankAccountComponents(this.state.tenantDetails.person.bankAccounts[0].rib)['code']:''}
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-md-2">
                                                            <label htmlFor="branchCode">Code guichet :</label>
                                                            <input disabled={true}
                                                                   type="text"
                                                                   className="form-control"
                                                                   id="branchCode"
                                                                   name="branchCode"
                                                                   ref={this.tenantBankBranchcodeRef}
                                                                   defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts && !isEmpty(this.state.tenantDetails.person.bankAccounts)?
                                                                       utils.fn_getBankAccountComponents(this.state.tenantDetails.person.bankAccounts[0].rib)['office']:''}
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label htmlFor="accountNumber">Numéro de compte :</label>
                                                            <input disabled={true}
                                                                   type="text"
                                                                   className="form-control"
                                                                   id="accountNumber"
                                                                   name="accountNumber"
                                                                   ref={this.tenantBankAccountNumberRef}
                                                                   defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts && !isEmpty(this.state.tenantDetails.person.bankAccounts)?
                                                                       utils.fn_getBankAccountComponents(this.state.tenantDetails.person.bankAccounts[0].rib)['accountNumber']:''}
                                                            />
                                                        </div>
                                                        <div className="mb-3 col-md-2">
                                                            <label htmlFor="ribKey">Clé RIB :</label>
                                                            <input disabled={true}
                                                                   type="text"
                                                                   className="form-control"
                                                                   id="ribKey"
                                                                   name="ribKey"
                                                                   ref={this.tenantBankribRef}
                                                                   defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts && !isEmpty(this.state.tenantDetails.person.bankAccounts)?
                                                                       utils.fn_getBankAccountComponents(this.state.tenantDetails.person.bankAccounts[0].rib)['ribKey']:''}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="iban">RIB :</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               id="rib"
                                                               name="rib"
                                                               ref={this.tenantBankCompleteRibRef}
                                                               defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts && !isEmpty(this.state.tenantDetails.person.bankAccounts)?
                                                                   this.state.tenantDetails.person.bankAccounts[0].rib:''}
                                                        />
                                                    </div>

                                                <div className="mb-3">
                                                    <label htmlFor="iban">IBAN :</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="iban"
                                                           name="iban"
                                                           ref={this.tenantBankibanRef}
                                                           defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts && !isEmpty(this.state.tenantDetails.person.bankAccounts)?
                                                               this.state.tenantDetails.person.bankAccounts[0].iban:''}
                                                    />
                                                </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="bicSwift">BIC/SWIFT :</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               id="bicSwift"
                                                               name="bicSwift"
                                                               ref={this.tenantBankbicRef}
                                                               defaultValue={this.state.tenantDetails.person && this.state.tenantDetails.person.bankAccounts && !isEmpty(this.state.tenantDetails.person.bankAccounts)?
                                                                   this.state.tenantDetails.person.bankAccounts[0].bic:''}
                                                        />
                                                    </div>
                                            </fieldset>
                                            <div className="" id="guarantorsCard">
                                                <hr/>
                                                    <div className="">
                                                        <fieldset id="guarantorsSection">
                                                            <legend>Garants</legend>
                                                            <div className="container" data-layout="container">
                                                                <table id="guarantorsTable" className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Nom</th>
                                                                        <th>Mobile</th>
                                                                        <th>Fixe</th>
                                                                        <th>Email</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        isEmpty(this.state.guarantors)?'':this.state.guarantors.map((guarantor, index) => {
                                                                            return <PersonsPreviewRow
                                                                                key={index}
                                                                                person={guarantor.person}
                                                                                type={guarantor.type}
                                                                                delete={() => this.deleteGuarantor(index)}
                                                                            />
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <p style={{display: this.state.noGuarantorProvided?'':'none'}} className="failed-signin-message" >You must provide at least one guarantor</p>
                                                            <button type="button" className="btn btn-primary mb-3" data-bs-toggle="modal"
                                                                    data-bs-target="#guarantormodal" >Ajouter un Garant</button>
                                                        </fieldset>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tenant-docs-and-contacts" role="tabpanel" aria-labelledby="tenant-docs-and-contacts-tab">
                                        <div className="row mx-0 ">
                                            <div className=" mt-3" id="documentsCard">
                                                <div className="">
                                                    <fieldset id="documentsSection">
                                                        <legend>Documents</legend>

                                                        <div className="container" data-layout="container">
                                                            <table id="documentsTable" className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>Type</th>
                                                                    <th>Description</th>
                                                                    <th>Fichier</th>
                                                                    <th>Date ajout</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                                {
                                                                    this.state.documents.map((document, index) => {
                                                                        const filename = isEmpty(document.metaData)?'':document.metaData.fileName;
                                                                        const resourceUuid = isEmpty(document.metaData)?'':document.metaData.fileUuid;
                                                                        return <DocumentsPreviewRow
                                                                            key={index}
                                                                            delete={() => this.deleteDocument(index)}
                                                                            document={document}
                                                                            download = {() => this.downloadFile(resourceUuid, filename, index)}
                                                                        />
                                                                    })
                                                                }
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <button type="button" className="btn btn-primary mb-3" data-bs-toggle="modal" id="ajoutDoc" data-bs-target="#documentModal" >Ajouter un Document</button>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="" id="emergencyContactsCard">
                                                <hr/>
                                                    <div className="">
                                                        <fieldset id="emergencyContactsSection">
                                                            <legend>Contacts d'Urgence</legend>
                                                            <div className="container" data-layout="container">
                                                                <table id="emergency-contact-table" className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Nom</th>
                                                                        <th>Mobile</th>
                                                                        <th>Fixe</th>
                                                                        <th>Email</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        this.state.emergencyContacts.map((guarantor, index) => {
                                                                            return <PersonsPreviewRow
                                                                                key={index}
                                                                                person={guarantor.person}
                                                                                type={guarantor.type}
                                                                                delete={() => this.deleteEmergencyContact(index)}
                                                                            />
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <p style={{display: this.state.noEmergencyContactProvided?'':'none'}} className="failed-signin-message" >You must provide at least one emergency contact</p>
                                                            <button type="button" className="btn btn-primary mb-3"
                                                                    data-bs-toggle="modal" data-bs-target="#emergency-contact-modal"
                                                            >Ajouter un Contact d'Urgence</button>
                                                        </fieldset>
                                                    </div>
                                            </div>
                                            <fieldset>
                                                <hr/>
                                                    <legend>Informations de Départ</legend>
                                                    <div className="form-group">
                                                        <span><i>Adresse du locataire lorsque sa location s'achève</i></span>
                                                        <textarea type="text"
                                                               className="form-control"
                                                               id="departureAdress"
                                                               name="departureAdress"
                                                               rows="10"
                                                               ref={this.tenantFollowupAddressRef}
                                                               defaultValue={this.state.tenantDetails.followupAddress}
                                                        />
                                                    </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="card-footer bg-light py-2">
                            <hr/>
                                <div className="col-auto ">
                                    <button disabled={this.state.isDisabledSubmitButton} type="submit" className="btn btn-primary btn-lg" style={{marginRight: '10pt'}}>Enregistrer</button>
                                    <button className="btn btn-secondary btn-lg" style={{marginRight: '10pt'}} onClick={this.closeTenantCreation}>Annuler</button>
                                </div>
                        </div>
                    </div>
                        </form>

                            <div className="modal fade" id="guarantormodal" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
                                    <div className="modal-content position-relative">
                                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                                            <input type="button" className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"/>
                                        </div>
                                        <div className="modal-body p-0">
                                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                                <h5 className="mb-1"  id="guarantorModalLabel">Ajouter un Garant</h5>
                                            </div>
                                            <div className="p-4 pb-0">
                                                <div className="col-auto ms-auto">
                                                    <div className="btn-group" role="tablist">
                                                        <button className="btn btn-primary active" data-bs-toggle="pill" data-bs-target="#dom-guarantorinfo" type="button" role="tab"
                                                                aria-controls="dom-guarantorinfo" aria-selected="true" id="tab-dom-guarantorinfo"
                                                                onClick={this.toggleButtonStyles('tab-dom-guarantorinfo', 'tab-dom-searchguarantor')}>Nouveau</button>
                                                        <button className="btn btn-secondary" data-bs-toggle="pill" data-bs-target="#dom-searchguarantor" type="button" role="tab"
                                                                aria-controls="dom-searchguarantor" aria-selected="false" id="tab-dom-searchguarantor"
                                                                onClick={this.toggleButtonStyles('tab-dom-searchguarantor', 'tab-dom-guarantorinfo')}>Rechercher</button>
                                                    </div>
                                                </div>
                                                <div className="card-body bg-light mb-3 mt-3">
                                                    <div className="tab-content">
                                                        <div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-guarantorinfo" id="dom-guarantorinfo">
                                                            <div id="guarantorinfo">
                                                                <div className="mb-3">
                                                                    <label>Type de garant :</label>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio"
                                                                               name="guarantorType" id="individualGuarantor"
                                                                               value="individual" defaultChecked={true}
                                                                               ref={this.guarantorTypeIndividualRef}
                                                                               onChange={this.toggleTenantGuarantorTypeFields}/>
                                                                            <label className="form-check-label" htmlFor="individualGuarantor">Particulier</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio"
                                                                               name="guarantorType" id="companyGuarantor"
                                                                               value="company" defaultChecked={false}
                                                                               ref={this.guarantorTypeCorporationRef}
                                                                               onChange={this.toggleTenantGuarantorTypeFields}/>
                                                                            <label className="form-check-label" htmlFor="companyGuarantor">Entreprise</label>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 individual-guarantor" id="individualFieldsGuarantor1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="guarantorFirstName">Civilité :</label>
                                                                            <select className="form-control form-control-sm d-inline-block"
                                                                                    style={{width:'100%'}} id="guarantorcivility"
                                                                                    name="guarantorCivility[]"
                                                                                    ref={this.guarantorGenderRef}
                                                                                    onBlur={this.validateGuarantorGender}
                                                                                    onFocus={this.hideInvalidGuarantorGenderMessage}>
                                                                                <option value=""/>
                                                                                <option value="MR">M.</option>
                                                                                <option value="MS">Mlle</option>
                                                                                <option value="MRS">Mme</option>
                                                                            </select>
                                                                            <p style={{display: this.state.isInvalidGuarantorGender?'':'none'}} className="failed-signin-message" > Invalid guarantor gender</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="guarantorName">Nom :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="guarantorLastname"
                                                                                   name="guarantorLastname"
                                                                                   ref={this.guarantorLastnameRef}
                                                                                   onBlur={this.validateGuarantorLastname}
                                                                                   onFocus={this.hideInvalidGuarantorLastnameMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidGuarantorLastname?'':'none'}} className="failed-signin-message" > Invalid guarantor last name</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="guarantorFirstName">Prénom :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="guarantorFirstName"
                                                                                   name="guarantorFirstname"
                                                                                   ref={this.guarantorFirstnameRef}
                                                                                   onBlur={this.validateGuarantorFirstname}
                                                                                   onFocus={this.hideInvalidGuarantorFirstnameMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidGuarantorFirstname?'':'none'}} className="failed-signin-message" > Invalid guarantor first name</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 corporation-guarantor" style={{display:'none'}}>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <label htmlFor="guarantorCompanyName">Dénomination :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="guarantor-corporation-name"
                                                                                   name="guarantorCorporationName"
                                                                                   ref={this.guarantorCorporationNameRef}
                                                                                   onBlur={this.validateGuarantorCorporationName}
                                                                                   onFocus={this.hideInvalidGuarantorCorporationNameMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidGuarantorCorporationName?'':'none'}} className="failed-signin-message" > Invalid guarantor corporation name</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label htmlFor="guarantorMobile">Numéro de mobile :</label>
                                                                        <input type="tel" className="form-control"
                                                                               id="guarantor-mobile"
                                                                               name="guarantorMobile"
                                                                               ref={this.guarantorMobileRef}
                                                                               onBlur={this.validateGuarantorMobile}
                                                                               onFocus={this.hideInvalidGuarantorMobileMessage}
                                                                        />
                                                                        <p style={{display: this.state.isInvalidGuarantorMobile?'':'none'}} className="failed-signin-message" > Invalid guarantor mobile</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label htmlFor="guarantorFixed">Numéro de fixe :</label>
                                                                        <input type="tel" className="form-control"
                                                                               id="guarantor-phone"
                                                                               name="guarantorPhone"
                                                                               ref={this.guarantorPhoneRef}
                                                                               onBlur={this.validateGuarantorPhone}
                                                                               onFocus={this.hideInvalidGuarantorPhoneMessage}
                                                                        />
                                                                        <p style={{display: this.state.isInvalidGuarantorPhone?'':'none'}} className="failed-signin-message" > Invalid guarantor phone</p>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="guarantorEmail">Email :</label>
                                                                    <input type="email" className="form-control"
                                                                           id="guarantor-email"
                                                                           name="guarantorEmail"
                                                                           ref={this.guarantorEmailRef}
                                                                           onBlur={this.validateGuarantorEmail}
                                                                           onFocus={this.hideInvalidGuarantorEmailMessage}
                                                                    />
                                                                    <p style={{display: this.state.isInvalidGuarantorEmail?'':'none'}} className="failed-signin-message" > Invalid guarantor email</p>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="guarantorAddress">Adresse :</label>
                                                                    <input type="text"
                                                                           className="form-control"
                                                                           name="guarantorAddress"
                                                                           ref={this.guarantorAddressRef}
                                                                    />
                                                                </div>
                                                                <div className="mb-3 individual-guarantor" id="individualFieldsGuarantor">
                                                                    <hr/>
                                                                        <br/>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label className="form-label" htmlFor="guarantorBirthDate">Date de naissance :</label>
                                                                                    <input className="form-control datetimepicker"
                                                                                           id="guarantor-birthDate"
                                                                                           name="guarantorBirthDate[]"
                                                                                           type="text" placeholder="jj/mm/aaaa"
                                                                                           data-options='{"disableMobile":true}'
                                                                                           ref={this.guarantorBirthDateRef}
                                                                                           onBlur={this.validateGuarantorBirthDate}
                                                                                           onFocus={this.hideInvalidGuarantorBirthDateMessage}
                                                                                    />
                                                                                    <p style={{display: this.state.isInvalidGuarantorBirthDate?'':'none'}} className="failed-signin-message" > Invalid guarantor birth date </p>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor="guarantorBirthPlace">Lieu de naissance :</label>
                                                                                    <input type="text" className="form-control"
                                                                                           id="guarantor-birth-place"
                                                                                           name="guarantorBirthPlace"
                                                                                           ref={this.guarantorBirthPlaceRef}
                                                                                           onBlur={this.validateGuarantorBirthPlace}
                                                                                           onFocus={this.hideInvalidGuarantorBirthPlaceMessage}
                                                                                    />
                                                                                    <p style={{display: this.state.isInvalidGuarantorBirthPlace?'':'none'}} className="failed-signin-message" > Invalid guarantor birth place </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor="guarantorNationality">Nationalité :</label>
                                                                                    <input className="form-control"
                                                                                           id="guarantor-nationality"
                                                                                           name="guarantor-nationality"
                                                                                           list="guarantor-nationalities"
                                                                                           ref={this.guarantorNationalityRef}
                                                                                           onInput={() => this.fetchCountries(this.guarantorNationalityRef.current.value)}
                                                                                           autoComplete="off"
                                                                                           placeholder="France"
                                                                                           onBlur={this.validateGuarantorNationality}
                                                                                           onFocus={this.hideInvalidGuarantorNationalityMessage}
                                                                                    />
                                                                                    <p style={{display: this.state.isInvalidGuarantorNationality?'':'none'}} className="failed-signin-message" > Invalid guarantor nationality </p>
                                                                                    <datalist
                                                                                        id="guarantor-nationalities"
                                                                                        className="form-label"
                                                                                    >
                                                                                        <option value=""/>
                                                                                        {
                                                                                            this.state.countries.map(country => {
                                                                                                return <option key={country.codeIso3} value={country.frName}> {country.frName} </option>
                                                                                            })
                                                                                        }
                                                                                    </datalist>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor="guarantorProfession">Profession :</label>
                                                                                    <input type="text" className="form-control" name="guarantorProfession[]" />
                                                                                </div>
                                                                            </div>
                                                                </div>
                                                                <div className="mb-3 corporation-guarantor" id="companyFieldsGuarantor" style={{display:'none'}}>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="guarantorLegalForm">Forme juridique :</label>
                                                                            <select className="form-control"
                                                                                    name="guarantorLegalForm[]"
                                                                                    ref={this.guarantorCorporationLegalStatusFundRef}
                                                                            >
                                                                                {
                                                                                    this.state.companyLegalStatuses.map((status, index) => {
                                                                                        return <option key={index}
                                                                                                       value={status}
                                                                                        > {status} </option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="guarantorCapital">Capital :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="guarantor-capital"
                                                                                   name="guarantorCapital"
                                                                                   ref={this.guarantorCorporationCapitalFundRef}
                                                                                   onBlur={this.validateGuarantorCorporationCapitalFund}
                                                                                   onFocus={this.hideInvalidGuarantorCorporationCapitalFundMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidGuarantorCorporationCapitalFund?'':'none'}} className="failed-signin-message" > Invalid guarantor corporation capital fund</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="guarantorSIREN">SIREN :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="guarantor-siren"
                                                                                   name="guarantorSiren"
                                                                                   ref={this.guarantorCorporationSirenRef}
                                                                                   onBlur={this.validateGuarantorCorporationSiren}
                                                                                   onFocus={this.hideInvalidGuarantorCorporationSirenMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidGuarantorCorporationSiren?'':'none'}} className="failed-signin-message" > Invalid guarantor corporation SIREN</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="guarantorSIRET">SIRET :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="guarantor-siret"
                                                                                   name="guarantorSiret"
                                                                                   ref={this.guarantorCorporationSiretRef}
                                                                                   onBlur={this.validateGuarantorCorporationSiret}
                                                                                   onFocus={this.hideInvalidGuarantorCorporationSiretMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidGuarantorCorporationSiret?'':'none'}} className="failed-signin-message" > Invalid guarantor corporation SIRET</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <label htmlFor="guarantorVATNumber">Numéro de TVA :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="guarantor-vat-number"
                                                                                   name="guarantorVATNumber"
                                                                                   ref={this.guarantorCorporationVatRef}
                                                                                   onBlur={this.validateGuarantorCorporationVat}
                                                                                   onFocus={this.hideInvalidGuarantorCorporationVatMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidGuarantorCorporationVat?'':'none'}} className="failed-signin-message" > Invalid guarantor corporation VAT</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane code-tab-pane" role="tabpanel" aria-labelledby="tab-dom-searchguarantor" id="dom-searchguarantor">
                                                            <div id="searchGuarantor" >
                                                                <div className="mb-3">
                                                                    <input type="text" className="form-control" id="searchGuarantor1"
                                                                           placeholder="Entrer le nom ou l'email" onKeyUp={this.searchGuarantorFunction} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" id="guarantor-modal-closer" data-bs-dismiss="modal">Fermer</button>
                                            <button disabled={this.state.isDisabledGuarantorSubmitButton} type="button" className="btn btn-primary" onClick={this.addGuarantor}>Ajouter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="emergency-contact-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
                                    <div className="modal-content position-relative">
                                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                                            <input type="button" className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"/>
                                        </div>
                                        <div className="modal-body p-0">
                                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                                <h5 className="mb-1"  id="emergency-contact-modal-label">Ajouter un contact d'urgence</h5>
                                            </div>
                                            <div className="p-4 pb-0">
                                                <div className="col-auto ms-auto">
                                                    <div className="btn-group" role="tablist">
                                                        <button className="btn btn-primary active" data-bs-toggle="pill" data-bs-target="#dom-emergency-contact-info" type="button" role="tab"
                                                                aria-controls="dom-emergency-contact-info" aria-selected="true" id="tab-dom-emergency-contact-info"
                                                                onClick={this.toggleButtonStyles('tab-dom-emergency-contact-info', 'tab-dom-search-emergency-contact')}>Nouveau</button>
                                                        <button className="btn btn-secondary" data-bs-toggle="pill" data-bs-target="#dom-search-emergency-contact" type="button" role="tab"
                                                                aria-controls="dom-search-emergency-contact" aria-selected="false" id="tab-dom-search-emergency-contact"
                                                                onClick={this.toggleButtonStyles('tab-dom-search-emergency-contact', 'tab-dom-emergency-contact-info')}>Rechercher</button>
                                                    </div>
                                                </div>
                                                <div className="card-body bg-light mb-3 mt-3">
                                                    <div className="tab-content">
                                                        <div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-emergency-contact-info" id="dom-emergency-contact-info">
                                                            <div id="emergency-contact-info">
                                                                <div className="mb-3">
                                                                    <label>Type de garant :</label>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio"
                                                                               name="emergencyContactType" id="individual-emergency-contact"
                                                                               value="individual" defaultChecked={true}
                                                                               ref={this.emergencyContactTypeIndividualRef}
                                                                               onChange={this.toggleTenantEmergencyContactTypeFields}/>
                                                                        <label className="form-check-label" htmlFor="individual-emergency-contact">Particulier</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="radio"
                                                                               name="emergencyContactType" id="corporation-emergency-contact"
                                                                               value="company" defaultChecked={false}
                                                                               ref={this.emergencyContactTypeCorporationRef}
                                                                               onChange={this.toggleTenantEmergencyContactTypeFields}/>
                                                                        <label className="form-check-label" htmlFor="corporation-emergency-contact">Entreprise</label>
                                                                    </div>

                                                                </div>
                                                                <div className="mb-3 individual-emergency-contact" id="individual-emergency-contact-1">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="guarantorFirstName">Civilité :</label>
                                                                            <select className="form-control form-control-sm d-inline-block"
                                                                                    style={{width:'100%'}}
                                                                                    id="indeividual-emergency-contact-gender"
                                                                                    name="emergencyContactCivility"
                                                                                    ref={this.individualEmergencyContactGenderRef}
                                                                                    onBlur={this.validateIndividualEmergencyContactGender}
                                                                                    onFocus={this.hideInvalidIndividualEmergencyContactGenderMessage}>
                                                                            >
                                                                                <option value=""/>
                                                                                <option value="MR">M.</option>
                                                                                <option value="MS">Mlle</option>
                                                                                <option value="MRS">Mme</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <p style={{display: this.state.isInvalidIndividualEmergencyContactGender?'':'none'}} className="failed-signin-message" > Invalid emergency contact gender</p>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="individual-emergency-contact-name">Nom :</label>
                                                                            <input id="individual-emergency-contact-lastname"
                                                                                   name="emergencyContactLastname"
                                                                                   type="text"
                                                                                   className="form-control"
                                                                                   ref={this.individualEmergencyContactLastnameRef}
                                                                                   onBlur={this.validateIndividualEmergencyContactLastname}
                                                                                   onFocus={this.hideInvalidIndividualEmergencyContactLastnameMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidIndividualEmergencyContactLastname?'':'none'}} className="failed-signin-message" > Invalid emergency contact last name</p>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <label htmlFor="individual-emergency-contact-firstname">Prénom :</label>
                                                                            <input type="text" className="form-control"
                                                                                   id="individual-emergency-contact-firstname"
                                                                                   name="emergencyContactFirstname"
                                                                                   ref={this.individualEmergencyContactFirstnameRef}
                                                                                   onBlur={this.validateIndividualEmergencyContactFirstname}
                                                                                   onFocus={this.hideInvalidIndividualEmergencyContactFirstnameMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidIndividualEmergencyContactFirstname?'':'none'}} className="failed-signin-message" > Invalid emergency contact first name</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 corporation-emergency-contact" style={{display:'none'}}>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <label htmlFor="corporation-emergency-contact-name">Dénomination :</label>
                                                                            <input id="corporation-emergency-contact-name"
                                                                                   type="text"
                                                                                    className="form-control"
                                                                                   id="corporation-emergency-contactcompany-name"
                                                                                   name="emergencyContactCompanyName"
                                                                                   ref={this.corporationEmergencyContactNameRef}
                                                                                   onBlur={this.validateCorporationEmergencyContactName}
                                                                                   onFocus={this.hideInvalidCorporationEmergencyContactNameMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidCorporationEmergencyContactName?'':'none'}} className="failed-signin-message" > Invalid emergency contact corporation name</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label htmlFor="emergency-contact-mobile">Numéro de mobile :</label>
                                                                        <input id="emergency-contact-mobile"
                                                                               type="tel"
                                                                               className="form-control"
                                                                               name="emergencyContactMobile[]"
                                                                               ref={this.individualEmergencyContactMobileRef}
                                                                               onBlur={this.validateIndividualEmergencyContactMobile}
                                                                               onFocus={this.hideInvalidIndividualEmergencyContactMobileMessage}
                                                                        />
                                                                        <p style={{display: this.state.isInvalidIndividualEmergencyContactMobile?'':'none'}} className="failed-signin-message" > Invalid emergency contact mobile</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label htmlFor="emergency-contact-tel">Numéro de fixe :</label>
                                                                        <input id="emergency-contact-tel"
                                                                               type="tel"
                                                                               className="form-control"
                                                                               name="emergencyContactPhone"
                                                                               ref={this.individualEmergencyContactPhoneRef}
                                                                               onBlur={this.validateIndividualEmergencyContactPhone}
                                                                               onFocus={this.hideInvalidIndividualEmergencyContactPhoneMessage}
                                                                        />
                                                                        <p style={{display: this.state.isInvalidIndividualEmergencyContactPhone?'':'none'}} className="failed-signin-message" > Invalid emergency contact phone</p>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="corporation-emergency-contact-email">Email :</label>
                                                                    <input id="emergency-contact-email"
                                                                           type="email"
                                                                           className="form-control"
                                                                           name="emergencyContactEmail"
                                                                           ref={this.individualEmergencyContactEmailRef}
                                                                           onBlur={this.validateIndividualEmergencyContactEmail}
                                                                           onFocus={this.hideInvalidIndividualEmergencyContactEmailMessage}
                                                                    />
                                                                    <p style={{display: this.state.isInvalidIndividualEmergencyContactEmail?'':'none'}} className="failed-signin-message" > Invalid emergency contact email</p>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="corporation-emergency-contact-address">Adresse :</label>
                                                                    <input id="corporation-emergency-contact-address"
                                                                           type="text"
                                                                           className="form-control"
                                                                           name="guarantorAddress[]"
                                                                           ref={this.emergencyContactAddressRef}
                                                                    />
                                                                </div>
                                                                <div className="mb-3 individual-emergency-contact" id="individual-fields-emergency-contact">
                                                                    <hr/>
                                                                    <br/>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label className="form-label" htmlFor="individual-emergency-contact-birthDate">Date de naissance :</label>
                                                                            <input className="form-control datetimepicker"
                                                                                   id="individual-emergency-contact-birthDate"
                                                                                   name="emergencyContactBirthDate"
                                                                                   type="text" placeholder="jj/mm/aaaa"
                                                                                   data-options='{"disableMobile":true}'
                                                                                   ref={this.individualEmergencyContactBirthDateRef}
                                                                                   onBlur={this.validateIndividualEmergencyContactBirthDate}
                                                                                   onFocus={this.hideInvalidIndividualEmergencyContactBirthDateMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidIndividualEmergencyContactBirthDate?'':'none'}} className="failed-signin-message" > Invalid emergency contact birth date</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="individual-emergency-contact-birth-place">Lieu de naissance :</label>
                                                                            <input id="individual-emergency-contact-birth-place"
                                                                                   type="text" className="form-control"
                                                                                   name="emergencyContactBirthPlace"
                                                                                   ref={this.individualEmergencyContactBirthPlaceRef}
                                                                                   onBlur={this.validateIndividualEmergencyContactBirthPlace}
                                                                                   onFocus={this.hideInvalidIndividualEmergencyContactBirthPlaceMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidIndividualEmergencyContactBirthPlace?'':'none'}} className="failed-signin-message" > Invalid emergency birth place </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="individual-emergency-contact-nationality">Nationalité :</label>
                                                                            <input className="form-control"
                                                                                   id="individual-emergency-contact-nationality"
                                                                                   name="emergencyContactNationality"
                                                                                   list="individual-emergency-contact-nationalities"
                                                                                   onInput={() => this.fetchCountries(this.individualEmergencyContactNationalityRef.current.value)}
                                                                                   autoComplete="off"
                                                                                   placeholder="France"
                                                                                   ref={this.individualEmergencyContactNationalityRef}
                                                                                   onBlur={this.validateIndividualEmergencyContactNationality}
                                                                                   onFocus={this.hideInvalidIndividualEmergencyContactNationalityMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidIndividualEmergencyContactNationality?'':'none'}} className="failed-signin-message" > Invalid emergency nationality </p>
                                                                            <datalist
                                                                                id="individual-emergency-contact-nationalities"
                                                                                className="form-label"
                                                                            >
                                                                                <option value=""/>
                                                                                {
                                                                                    this.state.countries.map(country => {
                                                                                        return <option key={country.codeIso3} value={country.frName}> {country.frName} </option>
                                                                                    })
                                                                                }
                                                                            </datalist>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="individual-emergency-contact-profession">Profession :</label>
                                                                            <input id="individual-emergency-contact-profession" type="text"
                                                                                   className="form-control" name="guarantorProfession[]" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 corporation-emergency-contact" id="companyFieldsGuarantor" style={{display:'none'}}>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="corporation-emergency-contact-legalform">Forme juridique :</label>
                                                                            <select id="corporation-emergency-contact-legalform"
                                                                                    className="form-control"
                                                                                    name="guarantorLegalForm[]"
                                                                                    ref={this.corporationEmergencyContactLegalStatusRef}
                                                                            >
                                                                                {
                                                                                    this.state.companyLegalStatuses.map(status => {
                                                                                        return <option key={status}
                                                                                                       value={status}> {status} </option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="corporation-emergency-contact-capital-fund">Capital :</label>
                                                                            <input id="corporation-emergency-contact-capital-fund"
                                                                                   name="corporationEmergencyContactCapitalFund"
                                                                                   type="text"
                                                                                   className="form-control"
                                                                                   ref={this.corporationEmergencyContactCapitalFundRef}
                                                                                   onBlur={this.validateCorporationEmergencyContactCapitalFund}
                                                                                   onFocus={this.hideInvalidCorporationEmergencyContactCapitalFundMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidCorporationEmergencyContactCapitalFund?'':'none'}} className="failed-signin-message" > Invalid emergency contact corporation capital fund</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="corporation-emergency-contact-siren">SIREN :</label>
                                                                            <input id="corporation-emergency-contact-siren"
                                                                                   name="corporationEmergencyContactSiren"
                                                                                   type="text"
                                                                                   className="form-control"
                                                                                   ref={this.corporationEmergencyContactSirenRef}
                                                                                   onBlur={this.validateCorporationEmergencyContactSiren}
                                                                                   onFocus={this.hideInvalidCorporationEmergencyContactSirenMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidCorporationEmergencyContactSiren?'':'none'}} className="failed-signin-message" > Invalid emergency contact corporation SIREN</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="corporation-emergency-contact-siret" >SIRET :</label>
                                                                            <input id="corporation-emergency-contact-siret"
                                                                                   name="corporationEmergencyContactSiret"
                                                                                   type="text"
                                                                                   className="form-control"
                                                                                   ref={this.corporationEmergencyContactSiretRef}
                                                                                   onBlur={this.validateCorporationEmergencyContactSiret}
                                                                                   onFocus={this.hideInvalidCorporationEmergencyContactSiretMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidCorporationEmergencyContactSiret?'':'none'}} className="failed-signin-message" > Invalid emergency contact corporation SIRET</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <label htmlFor="corporation-emergency-contact-vat-number">Numéro de TVA :</label>
                                                                            <input id="corporation-emergency-contact-vat-number"
                                                                                   name="corporationEmergencyContactVAT"
                                                                                   type="text"
                                                                                   className="form-control"
                                                                                   ref={this.corporationEmergencyContactVatRef}
                                                                                   onBlur={this.validateCorporationEmergencyContactVat}
                                                                                   onFocus={this.hideInvalidCorporationEmergencyContactVatMessage}
                                                                            />
                                                                            <p style={{display: this.state.isInvalidCorporationEmergencyContactVat?'':'none'}} className="failed-signin-message" > Invalid emergency contact corporation VAT</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane code-tab-pane" role="tabpanel" aria-labelledby="tab-dom-search-emergency-contact" id="dom-search-emergency-contact">
                                                            <div id="search-emergency-contact" >
                                                                <div className="mb-3">
                                                                    <input type="text" className="form-control" id="search-emergency-contact1"
                                                                           placeholder="Entrer le nom ou l'email" onKeyUp={this.searchEmergencyContact} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button id="emergency-contact-modal-closer" type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                            <button disabled={this.state.isDisabledEmergencyContactSubmitButton} type="button" className="btn btn-primary" onClick={this.addEmergencyContact}>Ajouter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade" id="documentModal" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
                                    <div className="modal-content position-relative">
                                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                                            <input type="button" className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                                                    data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body p-0">
                                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                                <h5 className="mb-1"  id="documentModalLabel">Ajouter un Document</h5>
                                            </div>
                                            <div className="p-4 pb-0">
                                                <div className="mb-3">
                                                    <label htmlFor="documentType">Type de document :</label>
                                                    <select className="form-control"
                                                            id="documentType"
                                                            name="identityType"
                                                            ref={this.documentTypeRef}
                                                    >
                                                        {
                                                            this.state.documentTypes.map((documentType, index) => {
                                                                return <option key={index} value={documentType}> {utils.i18n(documentType, 'en' )} </option>
                                                            })
                                                        }
                                                    </select>

                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="documentDescription">Description :</label>
                                                    <textarea
                                                        ref={this.documentDescriptionRef}
                                                        className="form-control"
                                                        name="documentDescription"
                                                        id="documentDescription"
                                                        rows="10" />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="documentFile">Fichier   :</label>
                                                    <input
                                                        className="form-control"
                                                        id="document-file"
                                                        type="file"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button id="document-model-closer" type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                            <button type="button" className="btn btn-primary" onClick={this.addDocument}>Ajouter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                    </div>
                </div>
            </>
        );
    };
}
export default Tenant;