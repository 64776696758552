import React, {Component} from 'react';
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import RealEstateController from "./RealEstateController";
import utils from "../utils";
import "../UploadImagePreview.css";
import UploadedImagePreview from "../UploadedImagePreview"
import {isEmpty} from "lodash";
import ImageFromBytes from "./ImageFromBytes";
import HabitableComponent from "./HabitableComponent";

class RealEstateEdit extends Component{

    constructor(props) {
        super(props);
        this.labelRef = React.createRef();
        this.typeRef = React.createRef();
        this.addressRef = React.createRef();
        this.surfaceRef = React.createRef();
        this.constructionYearRef = React.createRef();
        this.acquisitionDateRef = React.createRef();
        this.measureUnityM2Ref = React.createRef();
        this.measureUnityHaRef = React.createRef();
        this.habitableComponentTypeRef = React.createRef();
        this.habitableComponentSurfaceRef = React.createRef();
        this.habitableComponentLabelRef = React.createRef();
        this.condominiumTrusteeNameRef = React.createRef();
        this.condominiumTrusteePhoneRef = React.createRef();
        this.condominiumTrusteeEmailRef = React.createRef();
        this.condominiumTrusteeRealEstateTenthRef = React.createRef();
        this.condominiumTrusteeTenthRef = React.createRef();
        this.realEstateController = new RealEstateController();
        this.gallery = utils.getGligthBoxGallery();

        this.state = {
            realEstateTypes:[],
            realEstateDetails: {
                habitable:{
                    habitableComponents:[]
                }
            },
            oldHabitableComponents: [],
            isDisabledSubmitButton: false,
            queuedFiles: {},
            oldPictures: [],
            realEstateComponentTypes: [],
            isInvalidUploadImage: false,
            invalidUploadImageMessage: '',
            isInvalidName: false,
            isInvalidAddress: false,
            isInvalidSurface: false,
            isInvalidConstructionYear: false,
            isInvalidacquisitionDate: false,
            isInvalidHabitableComponentType: false,
            isInvalidHabitableComponentSurface: false,
            isHabitableComponentAddComponentEnabled: false,
            isHabitableRealEstate: false,
            isManagedByCondominiumTrustee: false,
            isInvalidCondominiumTrusteeName: false,
            isInvalidCondominiumTrusteePhone: false,
            isInvalidCondominiumTrusteeEmail: false,
            isInvalidCondominiumTrusteeRealEstateTenth: false,
            isInvalidCondominiumTrusteeTenth: false,
        }
    }

    componentDidMount() {
        new window.Dropzone('#realestate-photos', {
            url: 'https://ggle.ciom',
            autoProcessQueue: false,
            addedfile: this.handleAddedFiles,
            disablePreviews:true,
        });
        this.getRealEstateDetails();
        this.getRealEstateTypes();
        this.getHabitableRealEstateTypes();
        this.dataTable = window.jQuery("#acquisitionDate").flatpickr({
            dateFormat: "d/m/Y",
            maxDate: "today",
        });
    }

    handleRealEstateTypeChange = () => {
        this.setState({isHabitableRealEstate : utils.getHabitableRealEstateTypes().includes(this.typeRef.current.value),
            oldHabitableComponents:[]});
    }

    handleAddedFiles = (file) => {
        this.setState({isInvalidUploadImage: false});
        if(!utils.isUploadedFileAnImage(file)){
            this.setState({isInvalidUploadImage: true, invalidUploadImageMessage: 'Your uploaded file is not supported'});
            this.disableSubmitButton();
            return;
        }
        if(this.isMaxImageTresholdReached()){
            this.setState({isInvalidUploadImage: true, invalidUploadImageMessage: 'You cant upload more than 5 pictures'});
            this.disableSubmitButton();
            return;
        }
        this.setState({queuedFiles: utils.updateQueuedFiles(file, this.state.queuedFiles)});
        this.disableSubmitButton();
    }

    isMaxImageTresholdReached = () => {
        return 5 === Object.keys(this.state.queuedFiles).length + this.state.oldPictures.length;
    }

    deleteFile = (key) => {
        delete this.state.queuedFiles[key];
        this.setState({isInvalidUploadImage: false});
        this.disableSubmitButton();
    }

    disableSubmitButton = () => {
        this.setState({
            isDisabledSubmitButton: this.isInValidName() || this.isInValidAddress() || this.isInvalidSurface()
            || this.isInvalidConstructionYear() || this.isInvalidacquisitionDate()
                || this.hasAtLeastOneInvalidHabitableComponent() || this.hasAtLeastOneCodominiumTrusteeElement()
        });
    }

    hasAtLeastOneInvalidHabitableComponent =  () => {
        return this.state.isHabitableRealEstate && ((this.state.oldHabitableComponents.length === 0 &&
            (this.isInvalidHabitableComponentType() || this.isInvalidHabitableComponentSurface()))
            || this.state.oldHabitableComponents.length === 0
        )
    }

    validateName = () => {
        this.setState({isInvalidName: this.isInValidName()});
        this.disableSubmitButton();
    }

    isInValidName = () => {
        return isEmpty(this.labelRef.current.value)
    }

    hideInvalidNameMessage = () => {
        this.setState({isInvalidName: false});
        this.disableSubmitButton();
    }

    validateAddress = () => {
        this.setState({isInvalidAddress: this.isInValidAddress()});
        this.disableSubmitButton();
    }

    isInValidAddress = () => {
        return isEmpty(this.addressRef.current.value)
    }

    hideInvalidAddressMessage = () => {
        this.setState({isInvalidAddress: false});
        this.disableSubmitButton();
    }

    validateSurface = () => {
        this.setState({isInvalidSurface: this.isInvalidSurface()});
        this.disableSubmitButton();
    }

    isInvalidSurface = () => {
        const surface = this.surfaceRef.current.value;
        if(isEmpty(surface))
            return true;
        const matched = surface.match(/[0-9]+/)
        return !(matched && surface === matched[0]);
    }

    hideInvalidSurfaceMessage = () => {
        this.setState({isInvalidSurface: false});
        this.disableSubmitButton();
    }

    validateConstructionYear = () => {
        this.setState({isInvalidConstructionYear: this.isInvalidConstructionYear()});
        this.disableSubmitButton();
    }

    isInvalidConstructionYear = () => {
        const constructionYear = this.constructionYearRef.current.value;
        if(isEmpty(constructionYear))
            return false;

        let year = new Date().getFullYear();
        const matched = constructionYear.match(/(14|15|16|17|18|19|20)+[0-9]{2,2}/)
        return !(matched && constructionYear === matched[0]) || (year < parseInt(constructionYear));
    }

    hideInvalidConstructionYearMessage = () => {
        this.setState({isInvalidConstructionYear: false});
        this.disableSubmitButton();
    }

    validateacquisitionDate = () => {
        this.setState({isInvalidacquisitionDate: this.isInvalidacquisitionDate()});
        this.disableSubmitButton();
    }

    isInvalidacquisitionDate = () => {
        const acquisitionDate = this.acquisitionDateRef.current.value;
        if(isEmpty(acquisitionDate))
            return false;
        return utils.fn_isInValidDate(acquisitionDate);
    }

    hideInvalidacquisitionDateMessage = () => {
        this.setState({isInvalidacquisitionDate: false});
        this.disableSubmitButton();
    }

    appendMeasureUnity = () => {
        if(this.measureUnityM2Ref.current.checked)
            return this.measureUnityM2Ref.current.value;
        return this.measureUnityHaRef.current.value;
    }

    displayPreviewImages = () => {
        let files = this.state.queuedFiles;
        const deleteFileFunction = key => this.deleteFile(key);
        return Object.keys(files).map(function(key, index) {
            return <UploadedImagePreview key={key} delete={() => deleteFileFunction(key)} file={files[key]} />
        })
    }

    getRealEstateTypes = () => {
        this.realEstateController.getRealEstateTypes()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({realEstateTypes: data});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }

    getHabitableRealEstateTypes = () => {
        this.realEstateController.getHabitableRealEstateTypes()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({realEstateComponentTypes: data});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }

    deleteOldPicture  = (resourceUuid) => {
        let pictures;
        let  oldPictures;
        let realEstateDetails = this.state.realEstateDetails;
        if(!realEstateDetails.pictures){
            pictures = []
            oldPictures = []
        }else {
            pictures = realEstateDetails.pictures;
            oldPictures = this.state.oldPictures;
        }
        let index = pictures.map(picture => picture['resourceUuid']).indexOf(resourceUuid);
        pictures.splice(index, 1);
        realEstateDetails[pictures] = pictures;
        oldPictures.splice(index, 1)
        this.setState({realEstateDetails: realEstateDetails, oldPictures:oldPictures});
    }


    displayPreviewImagesFromBytes = () => {
        let pictures;
        if(!this.state.realEstateDetails.pictures){
            pictures = []
        }else {
            pictures = this.state.realEstateDetails.pictures;
        }
        const gallery = this.gallery;
        const delete_ = this.deleteOldPicture;
        return pictures.map(function(key, index) {
            return <ImageFromBytes gallery={gallery} key={key['label'] + index} name={key['label']}
                                   resourceUuid={key['resourceUuid']} index={index} readOnly={false}
                                   delete={delete_}
            />
        })
    }

    deleteHabitableComponent = (input) => {
        let realEstateDetails = this.state.realEstateDetails;
        const habitableComponents = this.filterHabitableComponents(realEstateDetails.habitable.habitableComponents, input);
        realEstateDetails.habitable.habitableComponents = habitableComponents;
        const oldHabitableComponents = this.filterHabitableComponents(this.state.oldHabitableComponents, input);
        this.setState({realEstateDetails: realEstateDetails, oldHabitableComponents:oldHabitableComponents});
        this.disableSubmitButton();
    }

    filterHabitableComponents(habitableComponent, input) {
        return habitableComponent.filter(component => !(component.label === input.label && component.type === input.type && component.surface === input.surface));
    }

    addNewHabitableComponent = () => {
        let habitableComponent = this.state.oldHabitableComponents;
        let realEstateDetails = this.state.realEstateDetails;
        const component = {
            type:this.habitableComponentTypeRef.current.value,
            surface:this.habitableComponentSurfaceRef.current.value,
            label:this.habitableComponentLabelRef.current.value
        };
        habitableComponent.push(component);
        this.habitableComponentSurfaceRef.current.value =  '';
        this.habitableComponentLabelRef.current.value =  '';
        if(!realEstateDetails.habitable)
            realEstateDetails.habitable = {};
        if(!realEstateDetails.habitable.habitableComponents)
            realEstateDetails.habitable.habitableComponents = []
        realEstateDetails.habitable.habitableComponents.push(component);
        this.setState({oldHabitableComponents: habitableComponent, realEstateDetails:realEstateDetails, isHabitableComponentAddComponentEnabled:false});
        this.disableSubmitButton();
    }

    validateHabitableComponentType = () => {
        this.setState({isInvalidHabitableComponentType: this.isInvalidHabitableComponentType()});
        this.disableSubmitButton();
        this.validateHabitableComponentAddComponent();
    }

    isInvalidHabitableComponentType = () => {
        return isEmpty(this.habitableComponentTypeRef.current.value);
    }

    hideInvalidHabitableComponentTypeMessage = () => {
        this.setState({isInvalidHabitableComponentType: false});
        this.disableSubmitButton();
        this.validateHabitableComponentAddComponent();
    }

    validateHabitableComponentSurface = () => {
        this.setState({isInvalidHabitableComponentSurface: this.isInvalidHabitableComponentSurface()});
        this.disableSubmitButton();
        this.validateHabitableComponentAddComponent();
    }

    isInvalidHabitableComponentSurface = () => {
        return utils.fn_validateSurface(this.habitableComponentSurfaceRef.current.value);
    }

    hideInvalidHabitableComponentSurfaceMessage = () => {
        this.setState({isInvalidHabitableComponentSurface: false});
        this.disableSubmitButton();
        this.validateHabitableComponentAddComponent();
    }

    validateHabitableComponentAddComponent = () => {
        this.setState({isHabitableComponentAddComponentEnabled:(false === this.isInvalidHabitableComponentSurface() && false === this.isInvalidHabitableComponentType())});
    }

    validateCondominiumTrusteeName =  () => {
        this.setState({isInvalidCondominiumTrusteeName:this.isInvalidCondominiumTrusteeName()});
        this.disableSubmitButton();
    }

    isInvalidCondominiumTrusteeName = () => {
        return isEmpty(this.condominiumTrusteeNameRef.current.value)
    }

    hideInvalidCodominiumNameMessage =  () => {
        this.setState({isInvalidCondominiumTrusteeName: false});
        this.disableSubmitButton();
    }

    validateCondominiumTrusteePhone = () => {
        this.setState({isInvalidCondominiumTrusteePhone: this.isInvalidCondominiumTrusteePhone()});
        this.disableSubmitButton();
    }

    isInvalidCondominiumTrusteePhone = () => {
        return this.condominiumTrusteePhoneRef.current.value &&  utils.fn_isInValidTel(this.condominiumTrusteePhoneRef.current.value);
    }

    hideInvalidCodominiumPhoneMessage =  () => {
        this.setState({isInvalidCondominiumTrusteePhone: false});
        this.disableSubmitButton();
    }

    validateCondominiumTrusteeEmail = () => {
        this.setState({isInvalidCondominiumTrusteeEmail: this.isInvalidCondominiumTrusteeEmail()});
        this.disableSubmitButton();
    }

    isInvalidCondominiumTrusteeEmail = () => {
        return !isEmpty(this.condominiumTrusteeEmailRef.current.value) && utils.fn_isInValidEmail(this.condominiumTrusteeEmailRef.current.value);
    }

    hideInvalidCodominiumEmailMessage =  () => {
        this.setState({isInvalidCondominiumTrusteeEmail: false});
        this.disableSubmitButton();
    }

    isInvalidTenth = (input) => {
        return  isEmpty(input) ||isNaN(input);
    }

    validateCondominiumTrusteeRealEstateTenth = () => {
        this.setState({isInvalidCondominiumTrusteeRealEstateTenth: this.isInvalidCondominiumTrusteeRealEstateTenth()});
        this.disableSubmitButton();
    }

    isInvalidCondominiumTrusteeRealEstateTenth = () => {
        return this.isInvalidTenth(this.condominiumTrusteeRealEstateTenthRef.current.value);
    }

    hideInvalidCodominiumRealEsateTenthMessage =  () => {
        this.setState({isInvalidCondominiumTrusteeRealEstateTenth: false});
        this.disableSubmitButton();
    }

    validateCondominiumTrusteeTenth = () => {
        this.setState({isInvalidCondominiumTrusteeTenth: this.isInvalidCondominiumTrusteeTenth()});
        this.disableSubmitButton();
    }

    isInvalidCondominiumTrusteeTenth = () => {
        return this.isInvalidTenth(this.condominiumTrusteeTenthRef.current.value);
    }

    hideInvalidCodominiumTenthMessage = () => {
        this.setState({isInvalidCondominiumTrusteeTenth: false});
        this.disableSubmitButton();
    }

    hasAtLeastOneCodominiumTrusteeElement = () => {
        return this.state.isManagedByCondominiumTrustee && (this.isInvalidCondominiumTrusteeName()
            || this.isInvalidCondominiumTrusteePhone() || this.isInvalidCondominiumTrusteeEmail()
            || this.isInvalidCondominiumTrusteeRealEstateTenth() || this.isInvalidCondominiumTrusteeTenth())
    }

    getRealEstateDetails =  () => {
        this.realEstateController.getRealEstateDetails(this.props.match.params.realestateUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.measureUnityM2Ref.current.checked = 'm2' === data.measureUnity;
                            this.measureUnityHaRef.current.checked = 'ha' === data.measureUnity;
                            let oldPictures = this.state.oldPictures;
                            const oldHabitableComponents = data.habitable === null?[]:data.habitable.habitableComponents;
                            data.pictures.forEach(picture => {oldPictures.push(picture.resourceUuid)});
                            let realEstateDetails = data;
                            if(realEstateDetails.habitable){
                                realEstateDetails.habitable.habitableComponents = [];
                            }
                            this.setState({isHabitableRealEstate: data.habitable !== null,
                                isManagedByCondominiumTrustee: data.condominiumTrustee !== null, realEstateDetails: realEstateDetails,
                                oldHabitableComponents: oldHabitableComponents, oldPictures:oldPictures});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No Real estate with id : ' + this.props.match.params.realestateUuid + ' found.'})
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }

    handleRealEstateSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();
        const commondata = this.buildCommonData()
        this.addOldPicltures(commondata);
        this.addHabitableComponentsToCommonData(commondata);
        this.addOldHabitableComponentsToCommonData(commondata);
        this.addCondominiunToCommonData(commondata)
        this.addCommoDataToForm(formData, commondata);
        this.addNewPicturesToForm(formData);

        this.realEstateController.updateRealEstate(this.props.match.params.realestateUuid, formData)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.props.history.push(utils.contextualizePath('/bien/' + data.resourceUuid + '/details'));
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(422 === response.status){
                        response.json().then((data) => {
                            const toastMsg = utils.computeToastMessage(data);
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(toastMsg.messageDate), title: 'Invalid input', message: toastMsg.messageDetails})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }

    addHabitableComponentsToCommonData(commondata) {
        if(!this.state.realEstateDetails.habitable || 0 === this.state.realEstateDetails.habitable.habitableComponents.length)
            return;
        let habitableComponents = []
        this.state.realEstateDetails.habitable.habitableComponents.forEach(component => habitableComponents.push({
            label: component.label,
            type: component.type,
            surface: component.surface
        }))
        commondata.habitable = {
            habitableComponents:habitableComponents
        };
    }

    addOldHabitableComponentsToCommonData(commondata) {
        if(!this.state.oldHabitableComponents || 0 === this.state.oldHabitableComponents.length)
            return;
        let oldHabitableComponents = [];
        this.state.oldHabitableComponents.forEach(component => oldHabitableComponents.push(component['resourceUuid']));
        if(!commondata.habitable)
            commondata.habitable = {};
        commondata.habitable['oldHabitableComponents'] = oldHabitableComponents
    }

    addCondominiunToCommonData(commondata) {
        if (!this.isCondominiumRealEstate())
            return;
        commondata.condominiumTrustee = {
            name: this.condominiumTrusteeNameRef.current.value,
            phone: this.condominiumTrusteePhoneRef.current.value,
            email: this.condominiumTrusteeEmailRef.current.value,
            realestatetenth: this.condominiumTrusteeRealEstateTenthRef.current.value,
            condominiumtrusteetenth: this.condominiumTrusteeTenthRef.current.value
        };
    }

    isCondominiumRealEstate = () => {
        return '' !== this.condominiumTrusteeNameRef.current.value
            && '' !== this.condominiumTrusteeRealEstateTenthRef.current.value
            && '' !== this.condominiumTrusteeTenthRef.current.value;
    }

    resetCondominiumRealEstateInputs = () => {
        this.condominiumTrusteeNameRef.current.value = '';
        this.condominiumTrusteePhoneRef.current.value = '';
        this.condominiumTrusteeEmailRef.current.value = '';
        this.condominiumTrusteeRealEstateTenthRef.current.value = '';
        this.condominiumTrusteeTenthRef.current.value = '';
    }

    addNewPicturesToForm(formData) {
        if (Object.keys(this.state.queuedFiles).length !== 0) {
            for (const key in this.state.queuedFiles) {
                if (this.state.queuedFiles.hasOwnProperty(key)) {
                    formData.append("pictures", this.state.queuedFiles[key]);
                }
            }
        }
    }

    addCommoDataToForm(formData, commondata) {
        formData.append('commondata', new Blob([JSON.stringify(commondata)], {
            type: "application/json"
        }));
    }

    addOldPicltures(commondata) {
        for (let i = 0; i < this.state.oldPictures.length; i++) {
            commondata.oldPictures.push(this.state.oldPictures[i]);
        }
    }

    buildCommonData() {
        return {
            label: this.labelRef.current.value,
            type: this.typeRef.current.value,
            address: this.addressRef.current.value,
            surface: this.surfaceRef.current.value,
            constructionYear: this.constructionYearRef.current.value,
            acquisitionDate: this.acquisitionDateRef.current.value,
            measureUnity: this.appendMeasureUnity(),
            oldPictures: []
        };
    }

    handleManagedByCondominiumTrusteeChange =  (event) => {
        this.setState({isManagedByCondominiumTrustee: event.target.checked});
        this.resetCondominiumRealEstateInputs();
        this.disableSubmitButton();
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar history={this.props.history}/>
                        <div className="card mb-3">

                            <div className="card-header bg-light">
                                <h5 className="mb-0">General Information</h5>
                            </div>
                            <div className="card-body bg-light">
                                <div className="tab-content">
                                    <form  onSubmit={this.handleRealEstateSubmit}
                                    >
                                        <div className="tab-pane preview-tab-pane active show" role="tabpanel">
                                            <div className="row g-3 mb-3">
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="name">Name</label>
                                                        <input className="form-control form-select-lg"
                                                               id="name"
                                                               name="commondata.label"
                                                               defaultValue={this.state.realEstateDetails.label}
                                                               ref={this.labelRef}
                                                               onBlur={this.validateName}
                                                               onFocus={this.hideInvalidNameMessage}
                                                               placeholder="Real estate name"/>
                                                        <p style={{display: this.state.isInvalidName?'':'none'}} className="failed-signin-message" >Name cannot be empty</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="type">Type</label>

                                                        <select
                                                            className="form-select form-select-lg mb-3"
                                                            aria-label=".form-select-lg example"
                                                            id="type"
                                                            name="commondata.type"
                                                            ref={this.typeRef}
                                                            onChange={this.handleRealEstateTypeChange}
                                                        >
                                                            {
                                                                this.state.realEstateTypes.map((type, index) =>
                                                                    <option selected={this.state.realEstateDetails.type === type} key={index} value={type}>{utils.i18n(type, 'en' )} </option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <div className="col-md-12 ">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="address">Address</label>
                                                        <input className="form-control form-select-lg"
                                                               id="address"
                                                               name="commondata.address"
                                                               defaultValue={this.state.realEstateDetails.address}
                                                               placeholder="address"
                                                               ref={this.addressRef}
                                                               onBlur={this.validateAddress}
                                                               onFocus={this.hideInvalidAddressMessage}
                                                        />
                                                        <p style={{display: this.state.isInvalidAddress?'':'none'}} className="failed-signin-message" >Address cannot be empty</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">

                                                        <label className="form-label" htmlFor="surface">Surface</label>
                                                        <input className="form-control form-select-lg"
                                                               id="surface"
                                                               name="commondata.surface"
                                                               defaultValue={this.state.realEstateDetails.surface}
                                                               placeholder="78"
                                                               ref={this.surfaceRef}
                                                               onBlur={this.validateSurface}
                                                               onFocus={this.hideInvalidSurfaceMessage}
                                                        />
                                                        <span style={{marginRight: '30pt'}}>
                                                        <input ref={this.measureUnityM2Ref} className="form-check-input" type="radio" value="SQUARE_METERS" id="measure-unity-m2" name="measureUnity"/>
                                                        <label className="form-check-label mb-0" htmlFor="measure-unity-m2">M²</label>
                                                    </span>
                                                        <span>
                                                        <input ref={this.measureUnityHaRef} className="form-check-input" type="radio" value="HECTARES" id="measure-unity-ha" name="measureUnity"/>
                                                        <label className="form-check-label mb-0" htmlFor="measure-unity-ha">Ha</label>
                                                    </span>
                                                        <p style={{display: this.state.isInvalidSurface?'':'none'}} className="failed-signin-message" >Surface cannot be empty and must be number</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="constructionYear">Année de construction</label>
                                                        <input className="form-control form-select-lg"
                                                               name="commondata.constructionYear"
                                                               id="constructionYear"
                                                               defaultValue={this.state.realEstateDetails.constructionYear}
                                                               placeholder="1909"
                                                               ref={this.constructionYearRef}
                                                               onBlur={this.validateConstructionYear}
                                                               onFocus={this.hideInvalidConstructionYearMessage}
                                                        />
                                                    </div>
                                                    <p style={{display: this.state.isInvalidConstructionYear?'':'none'}} className="failed-signin-message" >Construction year cannot be empty and must be number</p>
                                                </div>
                                            </div>


                                            <div className="row g-3 mb-3">
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="acquisitionDate">Date d'acquisition</label>
                                                        <input className="form-control form-select-lg datetimepicker"
                                                               name="commondata.acquisitionDate"
                                                               id="acquisitionDate"
                                                               defaultValue={this.state.realEstateDetails.acquisitionDate}
                                                               data-options='{"disableMobile":true}'
                                                               ref={this.acquisitionDateRef}
                                                               onBlur={this.validateacquisitionDate}
                                                               onFocus={this.hideInvalidacquisitionDateMessage}
                                                        />
                                                    </div>
                                                    <p style={{display: this.state.isInvalidacquisitionDate?'':'none'}} className="failed-signin-message" >Acquisition year cannot be empty and must be number</p>
                                                </div>
                                                <div className="col-md-6 ">

                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <label className="form-label" htmlFor="constructionYear">Photos</label>
                                                <div id="realestate-photos" className="col-md-12 dropzone">

                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <p style={{display: this.state.isInvalidUploadImage?'':'none'}} className="failed-signin-message" >{this.state.invalidUploadImageMessage}</p>
                                                <div className="col-md-12">
                                                    {this.displayPreviewImages()}
                                                    {this.displayPreviewImagesFromBytes()}
                                                </div>
                                            </div>

                                            <div style={{display: this.state.isHabitableRealEstate?'':'none'}}>
                                                <br/>
                                                <div className="card-header bg-light">
                                                    <h5 className="mb-0">Détails et composition du bien</h5>
                                                </div>
                                                <br/>
                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="component-type">Type</label>

                                                            <select
                                                                className="form-select form-select-lg mb-3"
                                                                aria-label=".form-select-lg example"
                                                                id="component-type"
                                                                name="component-type"
                                                                ref={this.habitableComponentTypeRef}
                                                                onBlur={this.validateHabitableComponentType}
                                                                onFocus={this.hideInvalidHabitableComponentTypeMessage}
                                                            >
                                                                {
                                                                    this.state.realEstateComponentTypes.map((type, index) => <option key={index} value={type}>{utils.i18n(type, 'en' )} </option>)
                                                                }
                                                            </select>
                                                            <p style={{display: this.state.isInvalidHabitableComponentType?'':'none'}} className="failed-signin-message" >Invalid real estate component type</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="surface">Surface</label>
                                                            <input className="form-control form-select-lg"
                                                                   id="component-surface"
                                                                   name="component-surface"
                                                                   placeholder="90"
                                                                   ref={this.habitableComponentSurfaceRef}
                                                                   onBlur={this.validateHabitableComponentSurface}
                                                                   onFocus={this.hideInvalidHabitableComponentSurfaceMessage}
                                                            />
                                                            <p style={{display: this.state.isInvalidHabitableComponentSurface?'':'none'}} className="failed-signin-message" >Component surface cannot be empty and must be number</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="component-label">Label</label>
                                                            <input className="form-control form-select-lg"
                                                                   name="comonent-label"
                                                                   id="component-label"
                                                                   placeholder="Room 1"
                                                                   ref={this.habitableComponentLabelRef}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 ">
                                                        <label className="form-label" htmlFor="component-label">&nbsp;&nbsp;&nbsp;</label>
                                                        <div style={{marginTop: '7px', display: this.state.isHabitableComponentAddComponentEnabled?'':'none'}}>
                                                            <button onClick={this.addNewHabitableComponent} className='btn btn-falcon-default btn-sm' type='button'>
                                                                <svg className='svg-inline--fa fa-plus fa-w-14' data-fa-transform='shrink-3 down-2'
                                                                     aria-hidden='true' focusable='false' data-prefix='fas' data-icon='plus'
                                                                     role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' data-fa-i2svg=''
                                                                     style={{transformOrigin: '0.4375em 0.625em'}}>
                                                                    <g transform='translate(224 256)'>
                                                                        <g transform='translate(0, 64)  scale(0.8125, 0.8125)  rotate(0 0 0)'>
                                                                            <path fill='currentColor'
                                                                                  d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'
                                                                                  transform='translate(-224 -256)'></path>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <span className='fas fa-plus' data-fa-transform='shrink-3 down-2'></span>
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>
                                                <br/>
                                                {
                                                    this.state.oldHabitableComponents.map((component, index) => {
                                                        const delete_ = this.deleteHabitableComponent;
                                                        return <HabitableComponent key={index} component={component} delete={delete_} readonly={false}/>
                                                    })
                                                }

                                            </div>

                                            <div className="form-check mt-2">
                                                <input
                                                    className="form-check-input"
                                                    id="customCheck2"
                                                    type="checkbox"
                                                    value={this.state.isManagedByCondominiumTrustee}
                                                    checked={this.state.isManagedByCondominiumTrustee}
                                                    onChange={this.handleManagedByCondominiumTrusteeChange}
                                                />
                                                <label className="form-check-label align-top" htmlFor="customCheck2">Le bien est en copropriété mode.</label>
                                            </div>

                                            <div style={{display: this.state.isManagedByCondominiumTrustee?'':'none'}}>

                                                <div className="card-header bg-light">
                                                    <h5 className="mb-0">Copropriété et Syndic</h5>
                                                </div>

                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="component-type">Nom</label>
                                                            <input className="form-control form-select-lg"
                                                                   placeholder="Syndic"
                                                                   defaultValue={this.state.realEstateDetails.condominiumTrustee?this.state.realEstateDetails.condominiumTrustee.name:''}
                                                                   ref={this.condominiumTrusteeNameRef}
                                                                   onBlur={this.validateCondominiumTrusteeName}
                                                                   onFocus={this.hideInvalidCodominiumNameMessage}
                                                            />
                                                            <p style={{display: this.state.isInvalidCondominiumTrusteeName?'':'none'}} className="failed-signin-message" >Le nom du syndic ne peut être vide</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="component-type">Téléphone</label>
                                                            <input className="form-control form-select-lg"
                                                                   name="comonent-label"
                                                                   id="component-label"
                                                                   placeholder="Room 1"
                                                                   defaultValue={this.state.realEstateDetails.condominiumTrustee?this.state.realEstateDetails.condominiumTrustee.phone:''}
                                                                   ref={this.condominiumTrusteePhoneRef}
                                                                   onBlur={this.validateCondominiumTrusteePhone}
                                                                   onFocus={this.hideInvalidCodominiumPhoneMessage}
                                                            />
                                                            <p style={{display: this.state.isInvalidCondominiumTrusteePhone?'':'none'}} className="failed-signin-message" >Le téléphone est invalide</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="component-type">Email</label>
                                                            <input className="form-control form-select-lg"
                                                                   name="comonent-label"
                                                                   id="component-label"
                                                                   placeholder="Room 1"
                                                                   defaultValue={this.state.realEstateDetails.condominiumTrustee?this.state.realEstateDetails.condominiumTrustee.email:''}
                                                                   ref={this.condominiumTrusteeEmailRef}
                                                                   onBlur={this.validateCondominiumTrusteeEmail}
                                                                   onFocus={this.hideInvalidCodominiumEmailMessage}
                                                            />
                                                            <p style={{display: this.state.isInvalidCondominiumTrusteeEmail?'':'none'}} className="failed-signin-message" >L'émail est invalide</p>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-6 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="component-type">Tantième du bien</label>
                                                            <input className="form-control form-select-lg"
                                                                   name="comonent-label"
                                                                   id="component-label"
                                                                   placeholder="Room 1"
                                                                   defaultValue={this.state.realEstateDetails.condominiumTrustee?this.state.realEstateDetails.condominiumTrustee.condominiumtrusteetenth:''}
                                                                   ref={this.condominiumTrusteeRealEstateTenthRef}
                                                                   onBlur={this.validateCondominiumTrusteeRealEstateTenth}
                                                                   onFocus={this.hideInvalidCodominiumRealEsateTenthMessage}
                                                            />
                                                            <p style={{display: this.state.isInvalidCondominiumTrusteeRealEstateTenth?'':'none'}} className="failed-signin-message" >Le tantième du bien est invalide</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 ">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="component-type">Tantième de copropriété</label>
                                                            <input className="form-control form-select-lg"
                                                                   name="comonent-label"
                                                                   id="component-label"
                                                                   placeholder="Room 1"
                                                                   defaultValue={this.state.realEstateDetails.condominiumTrustee?this.state.realEstateDetails.condominiumTrustee.realestatetenth:''}
                                                                   ref={this.condominiumTrusteeTenthRef}
                                                                   onBlur={this.validateCondominiumTrusteeTenth}
                                                                   onFocus={this.hideInvalidCodominiumTenthMessage}
                                                            />
                                                            <p style={{display: this.state.isInvalidCondominiumTrusteeTenth?'':'none'}} className="failed-signin-message" >Le tantième de la copropriété est invalide</p>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="row g-3 mb-3">
                                                <div className="col-md-4 ">&nbsp;&nbsp;&nbsp;</div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-3">
                                                        <button disabled={this.state.isDisabledSubmitButton} className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Save </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">&nbsp;&nbsp;&nbsp;</div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    };
}
export default RealEstateEdit;
