import React, {Component} from 'react';

class DataTableTenantCorporationName extends Component{

    constructor(props) {
        super(props);
        this.state = {
            status:this.props.status,
            name:this.props.name,
            siren:this.props.siren,
            siret:this.props.siret,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <div className="d-flex align-items-center position-relative">
                    <div className="flex-1 ms-3">
                        {this.state.status}
                    </div>
                    <div className="flex-1 ms-3">
                        <h6 className="mb-0 fw-semi-bold">
                            <a className="stretched-link text-900" href="#">{this.state.name} </a></h6>
                        <p className="text-500 fs--2 mb-0">Siren:{this.state.siren} &nbsp;&nbsp; Siret:{this.state.siret}</p>
                    </div>
                </div>
            </>
        );
    };
}
export default DataTableTenantCorporationName;