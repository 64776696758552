import React, {Component} from 'react';

class DataTableTenantIndividualName extends Component{

    constructor(props) {
        super(props);
        this.state = {
            gender:this.props.gender,
            firstname:this.props.firstname,
            surname:this.props.surname,
            birthdate:this.props.birthdate,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <div className="d-flex align-items-center position-relative">
                    <div className="avatar avatar-2xl">
                        {
                            (this.state.gender === 'MR')?<span className="fas fa-male fa-2x"></span>:
                                <span className="fas fa-female fa-2x"></span>
                        }
                    </div>
                    <div className="flex-1 ms-3">
                        <h6 className="mb-0 fw-semi-bold">
                            <a className="stretched-link text-900" href="#">{this.state.firstname} &nbsp; {this.state.surname}</a></h6>
                        <p className="text-500 fs--2 mb-0">{this.state.birthdate}</p>
                    </div>
                </div>
            </>
        );
    };
}
export default DataTableTenantIndividualName;