import React, {Component} from 'react';

class DataTableNewRowButton extends Component{

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <div style={{marginRight: '5pt'}} id='table-simple-pagination-replace-element'>
                    <button onClick={() => this.props.add()} className='btn btn-falcon-default btn-sm' type='button'>
                        <svg className='svg-inline--fa fa-plus fa-w-14' data-fa-transform='shrink-3 down-2'
                             aria-hidden='true' focusable='false' data-prefix='fas' data-icon='plus'
                             role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' data-fa-i2svg=''
                             style={{transformOrigin: '0.4375em 0.625em'}}>
                            <g transform='translate(224 256)'>
                                <g transform='translate(0, 64)  scale(0.8125, 0.8125)  rotate(0 0 0)'>
                                    <path fill='currentColor'
                                          d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'
                                          transform='translate(-224 -256)'></path>
                                </g>
                            </g>
                        </svg>
                        <span className='fas fa-plus' data-fa-transform='shrink-3 down-2'></span>
                        <span  className='d-none d-sm-inline-block ms-1' style={{fontSize:'.8333333333rem', letterSpacing:'.02em'}}>New</span>
                    </button>
                </div>
            </>
        );
    };
}
export default DataTableNewRowButton;