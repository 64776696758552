import React, {Component} from 'react';
import utils from "../utils";
import SigninController from "./SigninController";
import constants from "../../constants";

class PasswordReset extends Component {

    constructor(props) {
        super(props);
        this.passwordRef = React.createRef();
        this.passwordConfirmationRef = React.createRef();
        this.signinController =  new SigninController();
        this.state = {
            isInvalidPassword:false,
            isFailedPasswordReset:false,
            isDisabledSubmitButton:true,
        };
    }

    validatePassword = () => {
        this.setState({isInvalidPassword: this.isInValidPassword()});
        this.disableSubmitButton();
    }

    isInValidPassword = () => {
       return utils.isInValidPassword(this.passwordRef.current.value, this.passwordConfirmationRef.current.value)
    }

    hideInvalidPasswordMessage = () => {
        this.setState({isInvalidPassword: false, isFailedPasswordReset: false});
    }

    handlePasswordResetSubmit = (event) => {
        event.preventDefault();
        this.hideInvalidPasswordMessage()
        const formData = new FormData(event.target);
        this.signinController
            .resetPassword(formData, this.props.match.params.passwordRequestUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.text().then((data) => {
                            this.props.history.push(utils.contextualizePath('/signin'));
                        });
                    } else if (401 === response.status) {
                        console.log("An 401 response")
                    } else if (403 === response.status) {
                        console.log("Password renew request failed with 401 or 403 !!");
                        this.setState({isFailedPasswordReset: true});
                    } else if(422 === response.status){
                        //TODO : handle 422 use case
                    } else {
                        console.log("Password renew request failed with 500!!");
                        this.setState({isFailedPasswordReset: true});
                    }
                },
                (error) => {
                    console.log("Error occurred during the call", error);
                    this.setState({isFailedPasswordReset: true});
                }
            );

    }

    disableSubmitButton = () => {
        this.setState({isDisabledSubmitButton: this.isInValidPassword()});
    }

    componentDidMount(){
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row min-vh-100 bg-100">
                        <div className="col-6 d-none d-lg-block position-relative">
                            <div className="bg-holder"
                                 style={{backgroundImage:'url(../../../assets/img/generic/20.jpg)'}}>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                            <div className="row justify-content-center g-0">
                                <div className="col-lg-9 col-xl-8 col-xxl-6">
                                    <p style={{display: this.state.isInvalidPassword? 'block':'none'}} className="mb-3 invalid-input-message">
                                        Invalid password: Password and its confirmation should be identical, has at least 7 chars that contains letter, caps, numbers and one of the following
                                        special chars {constants.PASSWORD_ACCEPTED_SPECIAL_CHARS}</p>
                                    <p style={{display: this.state.isFailedPasswordReset? 'block':'none'}} className="mb-3 invalid-input-message">An error occured while reseting your password, please try again</p>
                                    <div className="card">
                                        <div className="card-header bg-circle-shape bg-shape text-center p-2"><a
                                            className="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light"
                                            href="../../../index.html">CI3.0</a></div>
                                        <div className="card-body p-4">
                                            <h3 className="text-center">Reset password</h3>
                                            <form onSubmit={this.handlePasswordResetSubmit} className="mt-3">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="split-reset-password">New
                                                        Password</label>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        id="split-reset-password"
                                                        name="password"
                                                        ref={this.passwordRef}
                                                        onBlur={this.validatePassword}
                                                        onFocus={this.hideInvalidPasswordMessage}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label"
                                                           htmlFor="split-reset-confirm-password">Confirm
                                                        Password</label>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        id="split-reset-confirm-password"
                                                        name="passwordConfirm"
                                                        ref={this.passwordConfirmationRef}
                                                        onBlur={this.validatePassword}
                                                        onFocus={this.hideInvalidPasswordMessage}
                                                    />
                                                </div>
                                                <button
                                                    className="btn btn-primary d-block w-100 mt-3"
                                                    type="submit"
                                                    name="submit"
                                                    disabled={this.state.isDisabledSubmitButton}
                                                >Set password
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default PasswordReset;
