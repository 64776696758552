import React, {Component} from 'react';
import "../Home.css";
import SignupController from "./SignupController";
import utils from "../utils";
import {isEmpty} from "lodash";
import message from "../alertMessage";

class Subscription extends Component {

    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.mobileRef = React.createRef();
        this.phoneRef = React.createRef();
        this.signupController = new SignupController();
        this.state = {
            isSubscriptionRenewEligible:false,
            isValidationLoading:false,
            isInValidMobile:false,
            isInValidPhone:false,
            isInValidEmail:false,
            isInvalidAddress:false,
            isDisabledSubmitButton2:true,
            isSuccessfulRequest:false,
            isFailedValidation:false,
            failedValidationMessage:'',
            isDisabledSubmitButton:true,
        };
    }

    componentDidMount() {
    }

    isInValidEmail = () => {
        return isEmpty(this.emailRef.current.value)
    }

    validateUsername = () => {
        this.setState({isInValidEmail: this.isInValidEmail()});
        this.disableSubmitButton();
    }

    hideInvalidEmailMessage = () => {
        this.setState({isInValidEmail: false, isFailedValidation:false, isSuccessfulRequest:false,
            isValidationLoading:false});
    }

    disableSubmitButton = () => {
        this.setState({isDisabledSubmitButton: this.isInValidEmail()});
    }

    validateMobile = () => {
        this.setState({isInValidMobile:this.isInValidMobile()});
        this.disableSubmitButton2();
    }

    isInValidMobile = () => {
        return isEmpty(this.mobileRef.current.value) || utils.fn_isInValidMobile(this.mobileRef.current.value)
    }

    hideInvalidMobileMessage = () => {
        this.setState({isInValidMobile: false});
        this.disableSubmitButton2();
    }

    validatePhone = () => {
        this.setState({isInValidPhone:this.isInValidPhone()});
        this.disableSubmitButton2();
    }

    isInValidPhone = () => {
        return !isEmpty(this.phoneRef.current.value) && utils.fn_isInValidTel(this.phoneRef.current.value)
    }

    hideInvalidPhoneMessage = () => {
        this.setState({isInValidPhone: false});
        this.disableSubmitButton2();
    }

    disableSubmitButton2 = () => {
        this.setState({isDisabledSubmitButton2: this.isInValidMobile() || this.isInValidPhone()});
    }

    handleSubscriptionValidationLink = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        this.setState({isValidationLoading:true});
        this.signupController
            .regenerateSubscriptionValidationLink(formData)
            .then(
                (response) => {
                    if (200 === response.status) {
                        this.setState({isSuccessfulRequest: true});
                    } else {
                        response.json().then( (data) => {
                            this.setState({isFailedValidation: true, failedSigninMessage: message.getFr(data.functionnalDetails)});
                        });
                    }
                },
                (error) => {
                    console.log("Error occurred during the call", error);
                    this.setState({isFailedSignin: true, failedSigninMessage: 'Error occurred while signing in, please try again later'});
                }
            );
        this.setState({isValidationLoading:false});
    }

    validate = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        this.setState({isValidationLoading:true});
        this.signupController
            .validateSubscription(this.props.match.params.subscriptionUuid, formData)
            .then(
                (response) => {
                    if (200 === response.status) {
                        this.setState({isValidationLoading:false})
                        this.props.history.push(utils.contextualizePath('/signin'));
                    } else if (404 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : ' + this.props.match.params.subscriptionUuid + ' found.'})
                        this.setState({isValidationLoading:false})
                        //this.props.history.push(utils.contextualizePath('/signin'));
                    } else if (410 === response.status){
                        this.setState({isValidationLoading:false, isSubscriptionRenewEligible:true, isFailedValidation:true,
                            failedValidationMessage:'Your validation link has expired. Please request a new one'})
                    } else if (422 === response.status){
                        this.setState({isValidationLoading:false, isFailedValidation:true})
                        response.json().then((data) => {
                            const toastMsg = utils.computeToastMessage(data);
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(toastMsg.messageDate), title: 'Invalid input', message: toastMsg.messageDetails})
                        });
                    } else {
                        response.json().then( (data) => {
                            console.log(" failed with a 500", data);
                            this.setState({isValidationLoading:false, isFailedValidation:true,
                                failedValidationMessage:'An error occurred while validating your subscription'})
                        });
                    }
                },
                (error) => {
                    console.log("Error occurred during the call", error);
                }
            )
    }

    render() {
        return (

            <>

                <div className="container-fluid">
                    <div className="row min-vh-100 bg-100">
                        <div className="col-6 d-none d-lg-block position-relative">
                            <div className="bg-holder overlay"
                                 style={{backgroundImage:'url(../../../assets/img/generic/17.jpg)', backgroundPosition: '50% 76%'}}>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                            <div className="row justify-content-center g-0">
                                <div className="col-lg-9 col-xl-8 col-xxl-6">
                                    <div style={{marginLeft:'40%', width:'20%', marginRight:'40%', display:this.state.isValidationLoading?'block':'none'}}>
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <p style={{display:this.state.isFailedValidation ?'block':'none'}} className="failed-signin-message" > {this.state.failedValidationMessage}</p>
                                    <p style={{display:this.state.isSuccessfulRequest?'block':'none'}} className="succeed-signup-message" >Your subscription validation request has been processed. You'll be sent an email.</p>
                                    <div className="card">
                                        <div className="card-header bg-circle-shape bg-shape text-center p-2"><a
                                            className="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light"
                                            href="../../../index.html">CI3.0</a>
                                        </div>
                                        <form onSubmit={this.handleSubscriptionValidationLink}>
                                            <div className="card-body p-4" style={{display:this.state.isSubscriptionRenewEligible?'block':'none'}}>
                                                <h4 className="mb-0"> Forgot your password?</h4><small>Enter your email and we'll send you a reset link.</small>
                                                    <div className="row g-3 mb-3">
                                                <div className="col-md-12">
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        placeholder="Email address"
                                                        name="email"
                                                        ref={this.emailRef}
                                                        onBlur={this.validateUsername}
                                                        onFocus={this.hideInvalidEmailMessage}
                                                    />
                                                    <p style={{display: this.state.isInValidEmail?'block':'none'}} className="mb-3 invalid-input-message">Invalid email</p>
                                                </div>
                                            </div>
                                                    <div className="row g-3 mb-3">
                                                    <div className="col-md-2 "></div>
                                                    <div className="col-md-8 ">
                                                        <button
                                                            className="btn btn-primary d-block w-100 mt-3"
                                                            type="submit"
                                                            disabled={this.state.isDisabledSubmitButton}
                                                            name="submit">Send subscription link
                                                        </button></div>
                                                    <div className="col-md-2 "></div>
                                                </div>
                                            </div>
                                        </form>
                                        <form onSubmit={this.validate}>
                                            <div className="card-body p-4" style={{display:this.state.isSubscriptionRenewEligible?'none':'block'}}>
                                                <h4 className="mb-0"> How can we contact you ?</h4> <br/>
                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-6 ">
                                                        <label className="form-label" htmlFor="name">Mobile</label>
                                                        <input className="form-control form-select-lg"
                                                               id="mobile"
                                                               name="mobile"
                                                               ref={this.mobileRef}
                                                               onBlur={this.validateMobile}
                                                               onFocus={this.hideInvalidMobileMessage}
                                                        />
                                                        <p style={{display:this.state.isInValidMobile?'block':'none'}} className="failed-signin-message" >Invalid mobile</p>
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <label className="form-label" htmlFor="name">Phone</label>
                                                        <input className="form-control form-select-lg"
                                                               id="phone"
                                                               name="phone"
                                                               ref={this.phoneRef}
                                                               onBlur={this.validatePhone}
                                                               onFocus={this.hideInvalidPhoneMessage}
                                                        />
                                                        <p style={{display: this.state.isInValidPhone?'block':'none'}} className="failed-signin-message" >Invalid phone</p>
                                                    </div>
                                                </div>

                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-12 ">
                                                        <label className="form-label" htmlFor="name">Address</label>
                                                        <input className="form-control form-select-lg"
                                                               id="address"
                                                               name="address"/>
                                                        <p style={{display: this.state.isInvalidAddress?'block':'none'}} className="failed-signin-message" >Invalid address</p>
                                                    </div>
                                                </div>

                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-12 ">
                                                        <button disabled={this.state.isDisabledSubmitButton2}
                                                                className="btn btn-primary d-block w-100 mt-3"
                                                                type="submit"
                                                                name="save"
                                                        >Enregistrer</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    };
}

export default Subscription;
