import React, {Component} from 'react';
import utils from "../utils";

class DataTableTenantIdentification extends Component{

    constructor(props) {
        super(props);
        this.state = {
            status:'TOTOTO',
            type: this.props.type,
            registrationNumber: this.props.registrationNumber,
            expirationDate: this.props.expirationDate,

        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <h5 className="fs-0 fw-normal">
                    {utils.i18n(this.state.type, 'en' )}: &nbsp; {this.state.registrationNumber}</h5>
                {this.state.expirationDate}
            </>
        );
    };
}
export default DataTableTenantIdentification;