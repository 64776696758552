import React, {Component} from 'react';
import "./Home.css";

class ToastAlertMessage extends Component {

    constructor(props) {
        super(props);
        this.toastBody = React.createRef();
        this.state = {
        };
    }

    componentDidMount() {
        this.computeMessage(this.props.message);
    }

    getToastMessageClass = (messageType) => {
        return 'error' === messageType ? 'toast-error' : 'success' === messageType ? 'toast-success' : 'toast-warning';
    }

    computeSendingTime = (rawSendingTime) => {
        return rawSendingTime;
    }

    computeMessage = (message) => {
        if(typeof message === "string"){
            this.toastBody.current.innerHTML = message;
        } else{
            let result = "<ul>";
            for(let i=0; i<message.length; i++){
                result+= '<li><b>' + message[i].fieldName + '</b>: ' + message[i].message + "</br></li>";
            }
            this.toastBody.current.innerHTML = result+"</ul>";
        }
    }

    render() {
        return (
            <>
                <div className="toast show mb-2" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className={'toast-header ' + this.getToastMessageClass(this.props.type)}>
                        <strong className="me-auto">{this.props.title}</strong>
                        <small style={{color:'white'}}>{this.computeSendingTime(this.props.sendingTime)}
                        </small>
                        <button className="ms-2 btn-close" type="button" data-bs-dismiss="toast"
                                aria-label="Close"></button>
                    </div>
                    <div
                        className="toast-body"
                        ref={this.toastBody}
                    >
                    </div>
                </div>
            </>
            );
        };
     }
     export default ToastAlertMessage;
