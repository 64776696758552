import SigninService from "./SigninService";

class SigninController {

    constructor() {
        this.signinService = new SigninService();
    }

    signin = (signinData) => {
        return this.signinService.signin(signinData);
    }

    renewPassword = (data) => {
        return this.signinService.renewPassword(data);
    }

    resetPassword = (data, resourceUuid) => {
        return this.signinService.resetPassword(data, resourceUuid);
    }
}
export default SigninController;
