import React, { Component } from 'react';
class Footer extends Component {

    render() {
        return (
            <footer className="footer">
                <div className="row g-0 justify-content-between fs--1 mt-4 mb-3">
                    <div className="col-3 col-sm-auto "> &nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="col-6 col-sm-auto text-center">
                        <p className="mb-0 text-600">CI3.0 <span className="d-none d-sm-inline-block"> | </span><br className="d-sm-none"/> 2023 &copy;</p>
                    </div>
                    <div className="col-3 col-sm-auto">&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
            </footer>
        );
    }

}
export default Footer;