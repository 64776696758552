import React, {Component} from 'react';
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import "../UploadImagePreview.css";
import RealEstateController from "./RealEstateController";
import DataTableOverViewActions from "../DataTableOverViewActions";
import utils from "../utils";
import ReactDOM from 'react-dom';
import DataTableNewRowButton from "../DataTableNewRowButton";

class RealEstateOverview extends Component{

    constructor(props) {
        super(props);
        this.realEstateController = new RealEstateController();
        this.dataTable = {};

        this.state = {
            currentUsersRealEstates: [],
        }
    }

    returnActions = (resourceUuid) => {
        return "<div id='actions_" + resourceUuid + "'></div>"
    }

    componentDidMount() {
        this.getUsersRealEstates();
    }

    getDataTableConfig = (data) => {
        return {
            "processing": true,
            paging: false,
            destroy: true,
            data: data,
            columns: [
                { title: 'Label' },
                { title: 'Type' },
                { title: 'Adresse' },
                { title: 'Surface.' },
                { title: "Date d'acquisition" },
                { title: 'Actions',
                },
            ],
        }
    }

    displayDataTable = (data) => {
        let dataTableContent = [];
        for(let i=0; i<data.length; i++){
            let row = [];
            row.push(data[i]['label']);
            row.push(data[i]['type']);
            row.push(data[i]['address']);
            row.push(data[i]['surface']);
            row.push(data[i]['acquisitionDate']);
            row.push(this.returnActions(data[i]['resourceUuid']));
            dataTableContent.push(row)
        }
        this.setState({currentUsersRealEstates: data});
        this.dataTable = window.jQuery("#realestate-datatable").DataTable(this.getDataTableConfig(dataTableContent));
        for(let j=0; j<data.length; j++){
            const resourceUuid = data[j]['resourceUuid'];
            const actionsContainer = 'actions_' + resourceUuid;
            ReactDOM.render(<DataTableOverViewActions history={this.props.history}  delete={() => this.deleteRealEstate(resourceUuid)}
                details={() => this.getRealEstateDetails(resourceUuid)} edit={() => this.editRealEstate(resourceUuid)}/>,
                document.getElementById(actionsContainer));
        }
    }

    addNewRealEstate = () => {
        this.props.history.push(utils.contextualizePath('/bien/new'));
    }

    getUsersRealEstates = () => {
        this.realEstateController.getUserRealEstates()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({currentUsersRealEstates: data});
                            this.displayDataTable(data);
                            window.jQuery("#realestate-datatable_filter > label").before("<div id='add-btn-container'></div>");
                            ReactDOM.render(<DataTableNewRowButton history={this.props.history}
                                add={() => this.addNewRealEstate()}/>, document.getElementById("add-btn-container"));
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal()
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(422 === response.status){
                        response.json().then((data) => {
                            const toastMsg = utils.computeToastMessage(data);
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(toastMsg.messageDate), title: 'Invalid input', message: toastMsg.messageDetails})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }


    getRealEstateDetails = (resourceUuid) => {
        this.props.history.push(utils.contextualizePath('/bien/') + resourceUuid + '/details');
    }

    editRealEstate = (resourceUuid) => {
        this.props.history.push(utils.contextualizePath('/bien/') + resourceUuid + '/edit');
    }

    deleteRealEstate = (resourceUuid) => {
        this.realEstateController.deleteRealEstate(resourceUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.text().then((data) => {
                            const currentUsersRealEstates = this.state.currentUsersRealEstates.filter(realestate => realestate['resourceUuid'] !== resourceUuid)
                            this.setState({currentUsersRealEstates: currentUsersRealEstates});
                            this.dataTable.destroy();
                            window.jQuery("#realestate-datatable").empty();
                            this.displayDataTable(currentUsersRealEstates);
                            this.props.addToastMessage({type: 'success', sendingTime: utils.formatDate(new Date()), title: 'Resource deleted', message: "Real estate delete with success"})
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(422 === response.status){
                        response.json().then((data) => {
                            const toastMsg = utils.computeToastMessage(data);
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(toastMsg.messageDate), title: 'Invalid input', message: toastMsg.messageDetails})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar history={this.props.history}/>

                        <div className="card mb-3">

                            <div className="card-header bg-light">
                                <h5 className="mb-0">My real estates</h5>
                            </div>
                            <div className="card-body bg-light">
                                <div className="tab-content">
                                    <div className="tab-pane preview-tab-pane active show" role="tabpanel">
                                        <div className="row g-3 mb-3">
                                            <div className="col-md-12 ">
                                                <div className="card-body px-0 pt-0">
                                                    <table className="table mb-0 fs--1" style={{width:'100%'}}
                                                           id="realestate-datatable">
                                                        <thead className="bg-200 text-900"></thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        );
    };
}
export default RealEstateOverview;
