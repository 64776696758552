import React, {Component} from 'react';
import AccountController from "./AccountController";
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import "./Account.css";
import UploadedImagePreview from "../UploadedImagePreview";
import utils from "../utils";
import ContactEdit from "./ContactEdit";
import BankAccountEdit from "./BankAccountEdit";
import ImageFromBytes from "../bien/ImageFromBytes";
import constants from "../../constants";
import IndividualEdit from "./IndividualEdit";
import CorporationEdit from "./CorporationEdit";
import {isEmpty} from "lodash";
import AuthService from "../AuthService";
import SignupController from "../signup/SignupController";

class AccountEdit extends Component {

    constructor(props) {
        super(props);
        this.accountControler = new AccountController();
        this.signupController = new SignupController();
        this.contactRef = React.createRef();
        this.individualRef = React.createRef();
        this.corporationRef = React.createRef();
        this.bankAccountRef = React.createRef();
        this.websiteRef = React.createRef();
        this.topNavBarRef = React.createRef();

        this.gallery = utils.getGligthBoxGallery();
        this.state = {
            signatureQueuedFiles: {},
            stampQueuedFiles: {},
            oldSignatures: [],
            oldStamps: [],
            isInvalidBankName:false,
            isInvalidBankAddress:false,
            isInvalidBankCode:false,
            isInvalidBankOfficeCode:false,
            isInvalidBankAccountNumber:false,
            isInvalidWebSite:false,
            isInvalidRib:false,
            isInvalidIban:false,
            isInvalidBicSwift:false,
            isInvalidUploadSignature:false,
            invalidUploadSignatureMessage:'invalid signature',
            isInvalidUploadStamp:false,
            invalidUploadStampMessage:'invalid stamp',
            isDisabledSubmitButton:false,
            //TODO: this should be fetched from accountDetails object that is in the general menu !!!!
            accountType:'',
            accountUuid:'',
            countries:[],
            accountDetails:{
                contacts:[],
                bankAccounts:[],
                signaturePicture:{},
                stampPicture:{},
            },
            oldSignaturePicture:null,
        }
    }

    componentDidMount() {
        new window.Dropzone('#signature', {
            url: 'https://ggle.ciom',
            autoProcessQueue: false,
            addedfile: this.handleSignatureAddedFiles,
            disablePreviews:true,
        });

        new window.Dropzone('#stamp', {
            url: 'https://ggle.ciom',
            autoProcessQueue: false,
            addedfile: this.handleStampAddedFiles,
            disablePreviews:true,
        });

        this.fetchAccount();
    }

    fetchAccount = () => {
        this.accountControler.getAccountDetails()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({accountType: data.type.toLowerCase(), accountUuid:data.resourceUuid});
                            this.getAccountDetails(data.resourceUuid, data.type.toLowerCase())
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : ' + this.props.match.params.realestateUuid + ' found.'})
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            )
    }

    getAccountDetails = (resourceUuid, type) => {
        this.accountControler.getAccount(resourceUuid, type)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            let oldSignatures = [];
                            let oldStamps = [];
                            if (data && data.signaturePicture){
                                oldSignatures.push(data.signaturePicture)
                            }
                            if (data && data.stampPicture){
                                oldStamps.push(data.stampPicture)
                            }
                            let accountDetails = data;
                            if(!!!accountDetails.contacts || accountDetails.contacts.length === 0){
                                accountDetails.contacts = [{}];
                            }
                            if(!!!accountDetails.bankAccounts || accountDetails.bankAccounts.length === 0){
                                accountDetails.bankAccounts = [{rib:''}]
                            }
                            this.setState({accountDetails: accountDetails, oldSignatures:oldSignatures, oldStamps:oldStamps});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : ' + this.props.match.params.realestateUuid + ' found.'})
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            )
    }

    handleAccountUpdateCancellation = (event) => {
        this.props.history.push(utils.contextualizePath('/account'));
    }

    handleAccountUpdateAccount = (event) => {
        event.preventDefault();
        let formData = new FormData();
        let commonData = {}
        this.addSignatureToForm(formData);
        this.addStampToForm(formData);
        this.addBankAccountDataToCommonData(commonData);
        this.addContactDataToCommonData(commonData);
        commonData['website'] = this.websiteRef.current.value;
        if(this.state.oldSignatures){
            commonData['oldSignaturePicture'] = this.state.oldSignatures.length !== 0? this.state.oldSignatures[0].resourceUuid:'';
        }
        if(this.state.oldStamps){
            commonData['oldStampPicture'] = this.state.oldStamps.length !== 0? this.state.oldStamps[0].resourceUuid:'';
        }
        commonData['creationStep'] = 2;
        if ('individual' === this.state.accountType){
            this.addIndividualDataToCommonData(commonData);
            this.addAccountDataToForm(formData, commonData, 'individual')
        }

        if ('corporation' === this.state.accountType){
            this.addCorporationDataToCommonData(commonData);
            this.addAccountDataToForm(formData, commonData, 'corporation')
        }

        let updatePromise = 'individual' === this.state.accountType?
            this.accountControler.updateIndividualAccount(this.state.accountUuid, formData):
            this.accountControler.updateCorporationAccount(this.state.accountUuid, formData);
        updatePromise.then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.props.history.push(utils.contextualizePath('/account'));
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : ' + this.props.match.params.realestateUuid + ' found.'})
                    } else if(422 === response.status){
                        response.json().then((data) => {
                            const toastMsg = utils.computeToastMessage(data);
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(toastMsg.messageDate), title: 'Invalid input', message: toastMsg.messageDetails})
                        });
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            )
    }

    addAccountDataToForm = (formData, commondata, type) => {
        formData.append(type, new Blob([JSON.stringify(commondata)], {
            type: "application/json"
        }));
    }

    addIndividualDataToCommonData = (commonData) => {
        const individualData = this.individualRef.current.getIndividualData();
        if (Object.keys(individualData).length !== 0) {
            for (const key in individualData) {
                if (individualData.hasOwnProperty(key)) {
                    commonData[key] = individualData[key]
                }
            }
        }
    };

    addCorporationDataToCommonData = (commonData) => {
        const corporationData = this.corporationRef.current.getCorporationData();
        if (Object.keys(corporationData).length !== 0) {
            for (const key in corporationData) {
                if (corporationData.hasOwnProperty(key)) {
                    commonData[key] = corporationData[key]
                }
            }
        }
    };

    addSignatureToForm(formData) {
        if (Object.keys(this.state.signatureQueuedFiles).length !== 0) {
            for (const key in this.state.signatureQueuedFiles) {
                if (this.state.signatureQueuedFiles.hasOwnProperty(key)) {
                    formData.append("signaturePicture", this.state.signatureQueuedFiles[key]);
                }
            }
        }
    }

    addStampToForm(formData) {
        if (Object.keys(this.state.stampQueuedFiles).length !== 0) {
            for (const key in this.state.stampQueuedFiles) {
                if (this.state.stampQueuedFiles.hasOwnProperty(key)) {
                    formData.append("stampPicture", this.state.stampQueuedFiles[key]);
                }
            }
        }
    }

    addContactDataToCommonData = (commonData) => {
        let contacts = [];
        const contactData = this.contactRef.current.getContactData();
        if (contactData) {
            contacts.push(contactData);
            commonData['contacts'] = contacts;
        }
    };

    addBankAccountDataToCommonData = (commonData) => {
        let bankAccounts = [];
        const bankData = this.bankAccountRef.current.getBankAccountData();
        if (bankData) {
            bankAccounts.push(bankData);
            commonData['bankAccounts'] = bankAccounts;
        }
    };

    displayPreviewSignatureImages = () => {
        let files = this.state.signatureQueuedFiles;
        const deleteFileFunction = key => this.deleteSignatureFile(key);
        return Object.keys(files).map(function(key, index) {
            return <UploadedImagePreview key={key} delete={() => deleteFileFunction(key)} file={files[key]} />
        })
    }

    displayPreviewStampImages = () => {
        let files = this.state.stampQueuedFiles;
        const deleteFileFunction = key => this.deleteStampFile(key);
        return Object.keys(files).map(function(key, index) {
            return <UploadedImagePreview key={key} delete={() => deleteFileFunction(key)} file={files[key]} />
        })
    }

    handleSignatureAddedFiles = (file) => {
        this.setState({isInvalidUploadSignature: false});
        if(!utils.isUploadedFileAnImage(file)){
            this.setState({isInvalidUploadSignature: true, invalidUploadSignatureMessage: 'Your uploaded signature is not supported'});
            this.disableSubmitButton();
            return;
        }
        if(this.isMaxSignatureImageTresholdReached()){
            this.setState({isInvalidUploadSignature: true, invalidUploadSignatureMessage: 'You cant upload more than 1 signature'});
            this.disableSubmitButton();
            return;
        }
        this.setState({signatureQueuedFiles: utils.updateQueuedFiles(file, this.state.signatureQueuedFiles)});
        this.disableSubmitButton();
    }

    handleStampAddedFiles = (file) => {
        this.setState({isInvalidUploadStamp: false});
        if(!utils.isUploadedFileAnImage(file)){
            this.setState({isInvalidUploadStamp: true, invalidUploadStampMessage: 'Your uploaded stamp is not supported'});
            this.disableSubmitButton();
            return;
        }
        if(this.isMaxStampImageTresholdReached()){
            this.setState({isInvalidUploadStamp: true, invalidUploadStampMessage: 'You cant upload more than 1 stamp'});
            this.disableSubmitButton();
            return;
        }
        this.setState({stampQueuedFiles: utils.updateQueuedFiles(file, this.state.stampQueuedFiles)});
        this.disableSubmitButton();
    }

    isMaxSignatureImageTresholdReached = () => {
        return 1 === Object.keys(this.state.signatureQueuedFiles).length + this.state.oldSignatures.length;
    }

    isMaxStampImageTresholdReached = () => {
        return 1 === Object.keys(this.state.stampQueuedFiles).length + this.state.oldStamps.length;
    }

    deleteStampFile = (key) => {
        delete this.state.stampQueuedFiles[key];
        this.setState({isInvalidUploadStamp: false});
        this.disableSubmitButton();
    }

    deleteSignatureFile = (key) => {
        delete this.state.signatureQueuedFiles[key];
        this.setState({isInvalidUploadSignature: true});
        this.disableSubmitButton();
    }

    displaySignaturePreviewImagesFromBytes = () => {
        if(!!!this.state.accountDetails.signaturePicture){
            return '';
        }
        let picture = this.state.accountDetails.signaturePicture;
        const gallery = this.gallery;
        const delete_ = this.deleteOldSignaturePicture;
        const url = constants.authenticationApiEndpoints.FILE_DATA;
        return <ImageFromBytes url={url} gallery={gallery}
                               key={picture['label'] + 0} name={picture['label']}
                               resourceUuid={picture['resourceUuid']} index={0} readOnly={false}
                               delete={delete_}
        />
    }

    displayStampPreviewImagesFromBytes = () => {
        if(!!!this.state.accountDetails.stampPicture){
            return '';
        }
        let picture = this.state.accountDetails.stampPicture;
        const gallery = this.gallery;
        const delete_ = this.deleteOldStampPicture;
        const url = constants.authenticationApiEndpoints.FILE_DATA;
        return <ImageFromBytes url={url} gallery={gallery}
                               key={picture['label'] + 0} name={picture['label']}
                               resourceUuid={picture['resourceUuid']} index={0} readOnly={false}
                               delete={delete_}
        />
    }

    deleteOldSignaturePicture  = (resourceUuid) => {
        let accountDetails = this.state.accountDetails;
        accountDetails.signaturePicture = null;
        this.setState({accountDetails: accountDetails, oldSignatures:[]});
    }

    deleteOldStampPicture  = (resourceUuid) => {
        let accountDetails = this.state.accountDetails;
        accountDetails.stampPicture = null;
        this.setState({accountDetails: accountDetails, oldStamps:[]});
    }

    disableSubmitButton = () => {
        this.setState({
            isDisabledSubmitButton: this.isInvalidWebSite() || this.contactRef.current.containsAtLeastOneInvalidField()
                || ('individual' === this.state.accountType && this.individualRef.current.containsAtLeastOneInvalidField())
                || ('corporation' === this.state.accountType && this.corporationRef.current.containsAtLeastOneInvalidField())
        });
    }

    setAccountType = (type) => {
        const accountType = this.state.accountType !== type? type: this.state.accountType;
        this.setState({accountType:accountType});
    }

    validateWebsite =  () => {
        this.setState({isInvalidWebSite:this.isInvalidWebSite()});
        this.disableSubmitButton();
    }

    isInvalidWebSite = () => {
        if (isEmpty(this.websiteRef.current.value))
            return false;
        return !utils.fn_isValidUrl(this.websiteRef.current.value);
    }

    hideInvalidWebSiteMessage =  () => {
        this.setState({isInvalidWebSite: false});
        this.disableSubmitButton();
    }

    isDefaultCheckedAccount = (type) => {
        return this.state.accountType === type;
    }

    handleAccountDeletion = (event) => {
        event.preventDefault();
        this.props.showConfirmationBox({
            message:'Are you sure you want to delete your account? This is an irreversible action',
            action:this.deleteAccount
        });
    }

    deleteAccount =  () => {
        this.signupController.deleteAccount()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.text().then((data) => {
                            AuthService.signout();
                            this.props.history.push(utils.contextualizePath('/'));
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : found.'})
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            )
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar
                            ref={this.topNavBarRef}
                            history={this.props.history}/>
                        <div className="card mb-3">
                            <div className="card-header bg-light">
                                <div className="row g-3 mb-3">
                                    <div className="col-md-6 ">
                                        <h4 className="mb-0">Mon compte</h4>
                                        <span style={{marginRight: '30pt'}}>
                                            <input  className="form-check-input"
                                                    type="radio"
                                                    value="corporation"
                                                    checked={this.isDefaultCheckedAccount('corporation')}
                                                    id="accountType_corporation"
                                                    name="accountType"
                                                    onChange={() => this.setAccountType('corporation')}
                                            />
                                        <label className="form-check-label mb-0" htmlFor="accountType_corporation">&nbsp; Entreprise</label>
                                        </span>
                                        <span>
                                            <input  className="form-check-input"
                                                    type="radio"
                                                    value="individual"
                                                    checked={this.isDefaultCheckedAccount('individual')}
                                                    id="accountType_individual"
                                                    name="accountType"
                                                    onChange={() => this.setAccountType('individual')}
                                            />
                                        <label className="form-check-label mb-0" htmlFor="accountType_individual">&nbsp; Particulier</label>
                                        </span>
                                    </div>
                                    <div className="col-md-2 ">
                                    </div>
                                    <div className="col-md-4 progress">
                                        <div className="progress-bar" role="progressbar" aria-label="Basic example"
                                             style={{width:'75%', height:'25px'}} aria-valuenow="75" aria-valuemin="0"
                                             aria-valuemax="100">25%</div>
                                    </div>

                                </div>
                            </div>

                            <div className="card-body bg-light">
                                <form  onSubmit={this.handleAccountUpdateAccount}
                                >
                                <div className="tab-content">
                                    <div className="tab-pane preview-tab-pane active show" role="tabpanel">
                                        {'individual' === this.state.accountType?
                                            <IndividualEdit
                                                ref={this.individualRef}
                                                accountDetails={this.state.accountDetails}
                                                disableParentSubmitButton={this.disableSubmitButton}
                                            />:
                                            <CorporationEdit
                                                ref={this.corporationRef}
                                                accountDetails={this.state.accountDetails}
                                                disableParentSubmitButton={this.disableSubmitButton}
                                            />
                                        }

                                        <div className="row g-3 mb-3">
                                            <div className="col-md-12 ">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="name">Site web</label>
                                                    <input className="form-control form-select-lg"
                                                           ref={this.websiteRef}
                                                           id="web_site"
                                                           name="webSite"
                                                           onBlur={this.validateWebsite}
                                                           onFocus={this.hideInvalidWebSiteMessage}
                                                           defaultValue={this.state.accountDetails.website}
                                                           placeholder="www.chuckNorris.com"/>
                                                    <p style={{display: this.state.isInvalidWebSite?'':'none'}} className="failed-signin-message" >Invalid web site</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="section-title">Contact</div>
                                        {
                                            this.state.accountDetails.contacts.map(contact => {
                                                return <ContactEdit
                                                    ref={this.contactRef}
                                                    contact={contact}
                                                    key={contact['resourceUuid']}
                                                    disableParentSubmitButton={this.disableSubmitButton}
                                                />
                                            })
                                        }

                                        <div className="section-title">Compte Bancaire</div>
                                        {
                                            this.state.accountDetails.bankAccounts.map(bankAccount => {
                                                return <BankAccountEdit
                                                    ref={this.bankAccountRef}
                                                    bankAccount={bankAccount}
                                                    key={bankAccount['resourceUuid']}/>
                                            })
                                        }


                                        <div className="row g-3 mb-3">
                                            <div className="col-md-6 ">
                                                <label className="form-label" htmlFor="name">Signature</label>
                                                <div id="signature" className="dropzone">

                                                </div>
                                            </div>
                                            <div className="col-md-6 ">
                                                <p style={{display: this.state.isInvalidUploadSignature?'':'none'}} className="failed-signin-message" >{this.state.invalidUploadSignatureMessage}</p>
                                                <div className="col-md-12">
                                                    {this.displayPreviewSignatureImages()}
                                                    {this.displaySignaturePreviewImagesFromBytes()}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row g-3 mb-3">
                                            <div className="col-md-6 ">
                                                <label className="form-label" htmlFor="name">Cachet</label>
                                                <div id="stamp" className="dropzone">

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p style={{display: this.state.isInvalidUploadStamp?'':'none'}} className="failed-signin-message" >{this.state.invalidUploadStampMessage}</p>
                                                <div className="col-md-12">
                                                    {this.displayPreviewStampImages()}
                                                    {this.displayStampPreviewImagesFromBytes()}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row g-3 mb-3">
                                            <div className="col-md-6 ">
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <button disabled={this.state.isDisabledSubmitButton}
                                                            className="btn btn-primary d-block w-100 mt-3"
                                                                type="submit"
                                                                name="save"
                                                                >Enregistrer</button>
                                                    </div>

                                                    <div className="col">
                                                        <button  className="btn btn-secondary d-block w-100 mt-3"
                                                                 name="cancel"
                                                                 onClick={this.handleAccountUpdateCancellation}
                                                                 >Annuler</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 ">

                                            </div>
                                        </div>

                                        <div className="row g-3 mb-3 border-top">
                                            <div className="col-md-8 ">

                                            </div>
                                            <div className="col-md-4 ">
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <button className="btn btn-danger d-block w-100 mt-3"
                                                                 style={{marginTop:'1px'}}
                                                                 name="delete"
                                                                onClick={this.handleAccountDeletion}
                                                        >Supprimer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default AccountEdit;
