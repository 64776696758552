import React, {Component} from 'react';
import "./Account.css";
import utils from "../utils";
import {isEmpty} from "lodash";
import AccountController from "./AccountController";

class CorporationEdit extends Component {

    constructor(props) {
        super(props);
        this.accountControler = new AccountController();
        this.nameRef = React.createRef();
        this.legalStatusRef = React.createRef();
        this.shareCapitalRef = React.createRef();
        this.shareCapitalRef = React.createRef();
        this.sirenRef = React.createRef();
        this.siretRef = React.createRef();
        this.vatNumberRef = React.createRef();
        this.state = {
            accountDetails:{},
            statuses: [],
            isInvalidLegalStatus:false,
            isInvalidShareCapital:false,
            isInvalidSiren:false,
            isInvalidSiret:false,
            isInvalidVatNumber:false,
        }
    }

    componentDidMount() {
        this.fetchStatuses();
    }

    fetchStatuses = () => {
        setTimeout(() => {
            this.accountControler.getLegalStatusesLike()
                .then(
                    (response) => {
                        if (200 === response.status) {
                            response.json().then((data) => {
                                this.setState({statuses: data});
                            });
                        } else if (401 === response.status) {
                            this.props.showReSigninModal();
                        } else {
                            this.props.addToastMessage({
                                type: 'error',
                                sendingTime: utils.formatDate(new Date()),
                                title: 'Server Error',
                                message: "Error occurred while processing you request. Details are"
                            })
                        }
                    },
                    (error) => {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                )
        }, 1000);
    };

    validateShareCapital = () => {
        this.setState({isInvalidShareCapital:this.isInvalidShareCapital()});
        this.props.disableParentSubmitButton();
    }

    isInvalidShareCapital = () => {
        if(isEmpty(this.shareCapitalRef.current.value))
            return false
        return isNaN(this.shareCapitalRef.current.value);
    }

    hideInvalidShareCapitalMessage =  () => {
        this.setState({isInvalidShareCapital: false});
        this.props.disableParentSubmitButton();
    }

    validateSiren = () => {
        this.setState({isInvalidSiren:this.isInvalidSiren()});
        this.props.disableParentSubmitButton();
    }

    isInvalidSiren = () => {
        if (isEmpty(this.sirenRef.current.value))
            return false;
        return !utils.fn_isValidSiren(this.sirenRef.current.value);
    }

    hideInvalidSirenMessage =  () => {
        this.setState({isInvalidSiren: false});
        this.props.disableParentSubmitButton();
    }

    validateSiret = () => {
        this.setState({isInvalidSiret:this.isInvalidSiret()});
        this.props.disableParentSubmitButton();
    }

    isInvalidSiret = () => {
        if (isEmpty(this.siretRef.current.value))
            return false;
        return !utils.fn_isValidSiret(this.siretRef.current.value);
    }

    hideInvalidSiretMessage =  () => {
        this.setState({isInvalidSiret: false});
        this.props.disableParentSubmitButton();
    }

    validateVatNumber = () => {
        this.setState({isInvalidVatNumber:this.isInvalidVatNumber()});
        this.props.disableParentSubmitButton();
    }

    isInvalidVatNumber = () => {
        if (isEmpty(this.vatNumberRef.current.value))
            return false;
        return !utils.fn_isValidVat(this.vatNumberRef.current.value);
    }

    hideInvalidVatNumberMessage =  () => {
        this.setState({isInvalidVatNumber: false});
        this.props.disableParentSubmitButton();
    }

    containsAtLeastOneInvalidField = () => {
        return this.isInvalidShareCapital() || this.isInvalidSiren() || this.isInvalidSiret() || this.isInvalidVatNumber();
    }

    getCorporationData = () => {
        return {
            name:this.nameRef.current.value,
            legalStatus:this.legalStatusRef.current.value,
            shareCapital:this.shareCapitalRef.current.value,
            siren:this.sirenRef.current.value,
            siret:this.siretRef.current.value,
            vatNumber:this.vatNumberRef.current.value,
        }
    }

    render() {
        return (

            <>
                <div className="section-title">Informations de l'Entreprise</div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Dénomination</label>
                            <input className="form-control form-select-lg"
                                   id="corporation_name"
                                   name="name"
                                   ref={this.nameRef}
                                   defaultValue={this.props.accountDetails.name}
                                   placeholder="The world corporation"/>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Forme Juridique:</label>

                            <select className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    id="corporation_legal_status"
                                    name="legalStatus"
                                    ref={this.legalStatusRef}
                            >
                                {
                                    this.state.statuses.map(status => {
                                    return <option selected={status === this.props.accountDetails.legalStatus} key={status} value={status}> {status} </option>
                                 })
                                }
                            </select>
                            <p style={{display: this.state.isInvalidLegalStatus?'':'none'}} className="failed-signin-message" >Invalid Corporation legal status</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Capital social</label>
                            <input className="form-control form-select-lg"
                                   id="corporation_capital_status"
                                   name="shareCapital"
                                   defaultValue={this.props.accountDetails.shareCapital}
                                   ref={this.shareCapitalRef}
                                   onBlur={this.validateShareCapital}
                                   onFocus={this.hideInvalidShareCapitalMessage}
                                   placeholder="45000099"/>
                            <p style={{display: this.state.isInvalidShareCapital?'':'none'}} className="failed-signin-message" >Invalid share capital</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Numéro SIREN</label>
                            <input className="form-control form-select-lg"
                                   id="corporation_siren"
                                   name="siren"
                                   defaultValue={this.props.accountDetails.siren}
                                   ref={this.sirenRef}
                                   onBlur={this.validateSiren}
                                   onFocus={this.hideInvalidSirenMessage}
                                   placeholder="9799234"/>
                            <p style={{display: this.state.isInvalidSiren?'':'none'}} className="failed-signin-message" >Invalid siren</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Numéro SIRET</label>
                            <input className="form-control form-select-lg"
                                   id="corporation_siret"
                                   name="siret"
                                   defaultValue={this.props.accountDetails.siret}
                                   ref={this.siretRef}
                                   onBlur={this.validateSiret}
                                   onFocus={this.hideInvalidSiretMessage}
                                   placeholder="979923400001"/>
                            <p style={{display: this.state.isInvalidSiret?'':'none'}} className="failed-signin-message" >Invalid siret</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Numéro de TVA</label>
                            <input className="form-control form-select-lg"
                                   id="corporation_vat_number"
                                   name="vatNumber"
                                   defaultValue={this.props.accountDetails.vatNumber}
                                   ref={this.vatNumberRef}
                                   onBlur={this.validateVatNumber}
                                   onFocus={this.hideInvalidVatNumberMessage}
                                   placeholder="FR 123945678901"/>
                            <p style={{display: this.state.isInvalidVatNumber?'':'none'}} className="failed-signin-message" >Invalid Vat Number</p>
                        </div>
                    </div>
                </div>

            </>
        );
    };
}

export default CorporationEdit;
