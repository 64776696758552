import RealEstateService from "./RealEstateService";

class RealEstateController {

    constructor() {
        this.realEstateService = new RealEstateService();
    }

    createRealEstate = (data) => {
        return this.realEstateService.createRealEstate(data);
    }

    updateRealEstate = (resourceUuid, data) => {
        return this.realEstateService.updateRealEstate(resourceUuid, data);
    }

    getRealEstateTypes = () => {
        return this.realEstateService.getRealEstateTypes();
    }

    getHabitableRealEstateTypes = () => {
        return this.realEstateService.getHabitableRealEstateTypes();
    }

    getUserRealEstates = () => {
        return this.realEstateService.getUserRealEstates()
    }

    getRealEstateDetails = (resourceUuid) => {
        return this.realEstateService.getRealEstateDetails(resourceUuid)
    }

    deleteRealEstate = (resourceUuid) => {
        return this.realEstateService.deleteRealEstate(resourceUuid)
    }

}
export default RealEstateController;
