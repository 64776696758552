import React, {Component} from 'react';
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import utils from "../utils";
import "../UploadImagePreview.css";
import RealEstateController from "./RealEstateController";
import ImageFromBytes from "./ImageFromBytes";
import HabitableComponent from "./HabitableComponent";

class RealEstateDetails extends Component{

    constructor(props) {
        super(props);
        this.realEstateController = new RealEstateController();
        this.gallery = utils.getGligthBoxGallery();
        this.state = {
            realEstateDetails:{
                habitable:{
                    habitableComponents:[]
                },
                condominiumTrustee:{}
                },
            isHabitableRealEstate: false,
            isManagedByCondominiumTrustee: false,
        }
    }

    displayPreviewImagesFromBytes = () => {
        let pictures;
        if(!this.state.realEstateDetails.pictures){
            pictures = []
        }else {
            pictures = this.state.realEstateDetails.pictures;
        }
        const gallery = this.gallery;
        return pictures.map(function(key, index) {
            return <ImageFromBytes gallery={gallery} key={key['label']} name={key['label']}
                                   resourceUuid={key['resourceUuid']} index={index} readOnly={true} />
        })
    }

    displayHabitableComponents() {
        const habitable = this.state.realEstateDetails.habitable;
        const habitableComponents = habitable?habitable.habitableComponents:[]
        return habitableComponents.map((component, index) => {
            return <HabitableComponent key={index} component={component} readonly={true}/>
        });
    }

    handleRealEstateEdit = () => {
        this.props.history.push(utils.contextualizePath('/bien/' + this.props.match.params.realestateUuid + '/edit'));
    }

    componentDidMount() {
        this.realEstateController.getRealEstateDetails(this.props.match.params.realestateUuid)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.setState({realEstateDetails: data, isHabitableRealEstate:data.habitable !== null,
                                isManagedByCondominiumTrustee:data.condominiumTrustee !== null});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        response.json().then((data) => {
                            this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No Real estate with id : ' + this.props.match.params.realestateUuid + ' found.'})
                        });
                    } else {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                    }
                },
                (error) => {
                    this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Server Error', message: "Error occurred while processing you request. Details are"})
                }
            )
    }


    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar history={this.props.history}/>
                        <div className="card mb-3">

                            <div className="card-header bg-light">
                                <h5 className="mb-0">General Information</h5>
                            </div>
                            <div className="card-body bg-light">
                                <div className="tab-content">
                                        <div className="tab-pane preview-tab-pane active show" role="tabpanel">
                                            <div className="row g-3 mb-3">
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <h5 className="mb-0">Name</h5>
                                                        <p className="mb-1"> {this.state.realEstateDetails.label}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <h5 className="mb-0">Type</h5>
                                                        <p className="mb-1"> {this.state.realEstateDetails.type}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <div className="col-md-12 ">
                                                    <div className="mb-3">
                                                        <h5 className="mb-0">Address</h5>
                                                        <p className="mb-1"> {this.state.realEstateDetails.address}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <h5 className="mb-0">Surface</h5>
                                                        <p className="mb-1"> {this.state.realEstateDetails.surface} <span> {this.state.realEstateDetails.measureUnity}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <h5 className="mb-0">Année de construction</h5>
                                                        <p className="mb-1"> {this.state.realEstateDetails.constructionYear}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <div className="col-md-6 ">
                                                    <div className="mb-3">
                                                        <h5 className="mb-0">Date d'acquisition</h5>
                                                        <p className="mb-1"> {this.state.realEstateDetails.acquisitionDate}</p>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 ">

                                                </div>
                                            </div>

                                            <div className="row g-3 mb-3">
                                                <h5 className="mb-0">Photos</h5>
                                                <div className="col-md-12">
                                                    {this.displayPreviewImagesFromBytes()}
                                                </div>
                                            </div>

                                            <div style={{display: this.state.isHabitableRealEstate?'':'none'}}>
                                                <div className="card-header bg-light">
                                                    <h5 className="mb-0">Détails et composition du bien</h5>
                                                </div>
                                                <br/><br/>
                                                {
                                                    this.displayHabitableComponents()
                                                }
                                            </div>

                                            <div style={{display: this.state.isManagedByCondominiumTrustee?'':'none'}}>
                                                <div className="card-header bg-light">
                                                    <h5 className="mb-0">Copropriété et Syndic</h5>
                                                </div>
                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <h5 className="mb-0">Nom</h5>
                                                            <p className="mb-1"> {!this.state.realEstateDetails.condominiumTrustee?'': this.state.realEstateDetails.condominiumTrustee.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <h5 className="mb-0">Téléphone</h5>
                                                            <p className="mb-1"> {!this.state.realEstateDetails.condominiumTrustee? '':this.state.realEstateDetails.condominiumTrustee.phone}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <h5 className="mb-0">Email</h5>
                                                            <p className="mb-1"> {!this.state.realEstateDetails.condominiumTrustee?'':this.state.realEstateDetails.condominiumTrustee.email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-3 mb-3">
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <h5 className="mb-0">Tantième du bien</h5>
                                                            <p className="mb-1"> {!this.state.realEstateDetails.condominiumTrustee?'':this.state.realEstateDetails.condominiumTrustee.realestatetenth}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            <h5 className="mb-0">Tantième de la copropriété</h5>
                                                            <p className="mb-1"> {!this.state.realEstateDetails.condominiumTrustee?'':this.state.realEstateDetails.condominiumTrustee.condominiumtrusteetenth}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ">
                                                        <div className="mb-3">
                                                            &nbsp;&nbsp;&nbsp;
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="row g-3 mb-3">
                                                <div className="col-md-4 ">&nbsp;&nbsp;&nbsp;</div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-3">
                                                        <button disabled={false} onClick={this.handleRealEstateEdit} className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Edit </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">&nbsp;&nbsp;&nbsp;</div>
                                            </div>

                                        </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    };
}
export default RealEstateDetails;
