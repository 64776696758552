import React, {Component} from 'react';
import SideNavBar from "./navbar/SideNavBar";
import TopNavBar from "./navbar/TopNavBar";

class UnderConstruction extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentDidMount(){
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />

                    <div className="content">
                        <TopNavBar/>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6 col-xxl-3 justify-content-center g-0 bg-holder"
                                 style={{backgroundImage: 'url(../../../assets/img/under-construction.jpg)', backgroundPosition: 'top'}}>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default UnderConstruction;
