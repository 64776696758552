import React, {Component} from 'react';
import "./Account.css";
import utils from "../utils";
import {isEmpty} from "lodash";
import AccountController from "./AccountController";

class IndividualEdit extends Component {

    constructor(props) {
        super(props);
        this.accountControler = new AccountController();
        this.genderRef = React.createRef();
        this.nationalityRef = React.createRef();
        this.surnameRef = React.createRef();
        this.firstnameRef = React.createRef();
        this.birthDateRef = React.createRef();
        this.placeOfBirthRef = React.createRef();
        this.state = {
            accountDetails:{},
            countries:[],
            isInvalidGender:false,
            isInvalidFirstName:false,
            isInvalidSurname:false,
            isInvalidBirthDate:false,
            isInvalidPlaceOfBirth:false,
            isInvalidNationality:false,
        }
    }

    componentDidMount() {
        this.dataTable = window.jQuery("#birthDate").flatpickr({
            dateFormat: "d/m/Y",
            maxDate: "today",
        });
        this.setState({accountDetails: this.props.accountDetails});
    }

    validatesurname =  () => {
        this.setState({isInvalidSurname:this.isInvalidSurname()});
        this.props.disableParentSubmitButton();
    }

    isInvalidSurname = () => {
        return isEmpty(this.surnameRef.current.value)
    }

    hideInvalidSurnameMessage =  () => {
        this.setState({isInvalidSurname: false});
        this.props.disableParentSubmitButton();
    }

    validateFirstName =  () => {
        this.setState({isInvalidFirstName:this.isInvalidFirstName()});
        this.props.disableParentSubmitButton();
    }

    isInvalidFirstName = () => {
        return isEmpty(this.firstnameRef.current.value)
    }

    hideInvalidFirstNameMessage =  () => {
        this.setState({isInvalidFirstName: false});
        this.props.disableParentSubmitButton();
    }

    validateBirthDate =  () => {
        this.setState({isInvalidBirthDate:this.isInvalidBirthDate()});
        this.props.disableParentSubmitButton();
    }

    isInvalidBirthDate = () => {
        const birthDate = this.birthDateRef.current.value;
        if(isEmpty(birthDate))
            return false;
        let today = new Date();
        today.setHours(0,0,0,0);
        return utils.fn_isInValidDate(birthDate) || birthDate>today;
    }

    hideInvalidBirthDateMessage =  () => {
        this.setState({isInvalidBirthDate: false});
        this.props.disableParentSubmitButton();
    }

    containsAtLeastOneInvalidField = () => {
        return this.isInvalidSurname() || this.isInvalidFirstName() || this.isInvalidBirthDate();
    }

    getIndividualData = () => {
        return {
            gender: this.genderRef.current.value,
            surname: this.surnameRef.current.value,
            firstName: this.firstnameRef.current.value,
            birthDate: this.birthDateRef.current.value,
            placeOfBirth: this.placeOfBirthRef.current.value,
            nationality: this.nationalityRef.current.value,
        }
    }

    fetchCountries = () => {
        setTimeout(() => {
            this.setState({countries: []});
            this.accountControler.getCountriesLike(this.nationalityRef.current.value)
                .then(
                    (response) => {
                        if (200 === response.status) {
                            response.json().then((data) => {
                                this.setState({countries: data});
                            });
                        } else if (401 === response.status) {
                            this.props.showReSigninModal();
                        } else {
                            this.props.addToastMessage({
                                type: 'error',
                                sendingTime: utils.formatDate(new Date()),
                                title: 'Server Error',
                                message: "Error occurred while processing you request. Details are"
                            })
                        }
                    },
                    (error) => {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                )
        }, 1000);
    };

    render() {
        return (

            <>
                <div className="section-title">Informations Personnelles</div>

                <div className="row g-3 mb-3">
                    <div className="col-md-12 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Civilité:</label>
                            <select className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    ref={this.genderRef}
                            >
                                <option selected={this.props.accountDetails.gender === ''} value=""/>
                                <option selected={this.props.accountDetails.gender === 'MR'} value="MR">Mr</option>
                                <option selected={this.props.accountDetails.gender === 'MS'} value="MS">Mme</option>
                                <option selected={this.props.accountDetails.gender === 'MRS'} value="MRS">Mlle</option>
                            </select>
                            <p style={{display: this.state.isInvalidGender?'':'none'}} className="failed-signin-message" >Gender is not valid</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Prénoms:</label>
                            <input className="form-control form-select-lg"
                                   id="firstName"
                                   name="firstName"
                                   ref={this.surnameRef}
                                   onBlur={this.validatesurname}
                                   onFocus={this.hideInvalidSurnameMessage}
                                   defaultValue={this.props.accountDetails.surname}
                                   placeholder="Jean"/>
                            <p style={{display: this.state.isInvalidSurname?'':'none'}} className="failed-signin-message" >Surname name cannot be empty</p>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Nom de famille:</label>
                            <input className="form-control form-select-lg"
                                   id="surname"
                                   name="surname"
                                   ref={this.firstnameRef}
                                   onBlur={this.validateFirstName}
                                   onFocus={this.hideInvalidFirstNameMessage}
                                   defaultValue={this.props.accountDetails.firstName}
                                   placeholder="Dupont"/>
                            <p style={{display: this.state.isInvalidFirstName?'':'none'}} className="failed-signin-message" >Firstname cannot be empty</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-4 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Date de naissance:</label>
                            <input className="form-control form-select-lg"
                                   id="birthDate"
                                   name="birthDate"
                                   ref={this.birthDateRef}
                                   onBlur={this.validateBirthDate}
                                   onFocus={this.hideInvalidBirthDateMessage}
                                   defaultValue={this.props.accountDetails.birthDate}
                                   data-options='{"disableMobile":true}'
                                   placeholder="21/09/1955"/>
                            <p style={{display: this.state.isInvalidBirthDate?'':'none'}} className="failed-signin-message" >Invalid birth date</p>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Lieu de naissance:</label>
                            <input className="form-control form-select-lg"
                                   id="placeOfBirth"
                                   name="placeOfBirth"
                                   ref={this.placeOfBirthRef}
                                   defaultValue={this.props.accountDetails.placeOfBirth}
                                   placeholder="Paris"/>
                            <p style={{display: this.state.isInvalidPlaceOfBirth?'':'none'}} className="failed-signin-message" >Lieu de naissance ne peut être vide</p>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">Nationalité:</label>
                            <input className="form-control form-select-lg"
                                   id="nationality"
                                   name="nationality"
                                   list="nationalities"
                                   ref={this.nationalityRef}
                                   onInput={this.fetchCountries}
                                   defaultValue={this.props.accountDetails.nationality}
                                   autoComplete="off"
                                   placeholder="France"/>
                            <datalist
                                id="nationalities"
                                className="form-label"
                            >
                                <option value=""/>
                                {
                                    this.state.countries.map(country => {
                                        return <option key={country.codeIso3} value={country.frName}> {country.frName} </option>
                                    })
                                }
                            </datalist>
                            <p style={{display: this.state.isInvalidNationality?'':'none'}} className="failed-signin-message" >Invalid nationality</p>
                        </div>
                    </div>
                </div>

            </>
        );
    };
}

export default IndividualEdit;
