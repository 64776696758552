import React, {Component} from 'react';
import "./Account.css";

class Corporation extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }


    render() {
        return (

            <>
                <div className="section-title fs-1 mb-1">Informations de l'Entreprise</div>

                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Dénomination</h6>
                            <p className="fs-0 mb-0"> {this.props.accountDetails.name}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Forme Juridique:</h6>
                            <p className="mb-1"> {this.props.accountDetails.legalStatus}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Capital social</h6>
                            <p className="mb-1"> {this.props.accountDetails.shareCapital}</p>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Numéro SIREN</h6>
                            <p className="mb-1"> {this.props.accountDetails.siren}</p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Numéro SIRET</h6>
                            <p className="mb-1"> {this.props.accountDetails.siret}</p>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="mb-3">
                            <h6 className="fs-0 mb-0">Numéro de TVA</h6>
                            <p className="mb-1"> {this.props.accountDetails.vatNumber}</p>
                        </div>
                    </div>
                </div>

            </>
        );
    };
}

export default Corporation;
