import utils from "../utils";
import constants from "../../constants";
class AccountService {

    getAccountDetails = () => {
        const url = utils.proxify(constants.authenticationApiEndpoints.ACCOUNT_DETAILS);
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    getAccount = (resourceUuid, type) => {
        const url = utils.proxify(constants.authenticationApiEndpoints.ACCOUNT) + resourceUuid + '/' + type;
        const headers = utils.addBearerAuthorizationHeader(utils.getDefaultRequestHeaders);
        return fetch(url,
            {
                method: 'GET',
                headers: headers,
                mode: 'cors'
            });
    }

    updateIndividualAccount = (resourceUuid, data) => {
        const url = utils.proxify(constants.authenticationApiEndpoints.INDIVIDUAL) + resourceUuid;
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'PUT',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

    updateCorporationAccount = (resourceUuid, data) => {
        const url = utils.proxify(constants.authenticationApiEndpoints.CORPORATION) + resourceUuid;
        let headers = {
            'Accept': 'application/json',
        };
        headers = utils.addBearerAuthorizationHeader(headers);
        return fetch(url,
            {
                method: 'PUT',
                headers: headers,
                mode: 'cors',
                body: data
            });
    }

}
export default AccountService;
