import React, {Component} from 'react';
import RealEstateController from "./AccountController";
import SideNavBar from "../navbar/SideNavBar";
import TopNavBar from "../navbar/TopNavBar";
import utils from "../utils";
import Corporation from "./Corporation";
import Contact from "./Contact";
import BankAccount from "./BankAccount";
import Individual from "./Individual";
import ImageFromBytes from "../bien/ImageFromBytes";
import constants from "../../constants";
import SignupController from "../signup/SignupController";
import AuthService from "../AuthService";

class AccountDetails extends Component {

    constructor(props) {
        super(props);
        this.accountControler = new RealEstateController();
        this.signupController = new SignupController();
        this.gallery = utils.getGligthBoxGallery();
        this.state = {
            accountDetails:{
                contacts:[],
                bankAccounts:[],
            },
        }
    }


    componentDidMount() {
        this.getAccount();
    }

    handleAccountDeletion = () => {
        this.props.showConfirmationBox({
            message:'Are you sure you want to delete your account? This is an irreversible action',
            action:this.deleteAccount
        });
    }

    deleteAccount =  () => {
        this.signupController.deleteAccount()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.text().then((data) => {
                            AuthService.signout();
                            this.props.history.push(utils.contextualizePath('/'));
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : found.'})
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            )
    }

    getAccount = () => {
        this.accountControler.getAccountDetails()
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            this.getAccountDetails(data.resourceUuid, data.type.toLowerCase())
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : ' + this.props.match.params.realestateUuid + ' found.'})
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            )
    }

    getAccountDetails = (resourceUuid, type) => {
        this.accountControler.getAccount(resourceUuid, type)
            .then(
                (response) => {
                    if (200 === response.status) {
                        response.json().then((data) => {
                            let accountDetails = data;
                            if(!!!accountDetails.contacts || accountDetails.contacts.length === 0){
                                accountDetails.contacts = [{}]
                            }
                            if(!!!accountDetails.bankAccounts || accountDetails.bankAccounts.length === 0){
                                accountDetails.bankAccounts = [{rib:''}]
                            }
                            this.setState({accountDetails: accountDetails});
                        });
                    } else if (401 === response.status) {
                        this.props.showReSigninModal();
                    } else if (403 === response.status) {
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Access denied', message: "You're either not logged in or has no authorizations"})
                    } else if(404 === response.status){
                        this.props.addToastMessage({type: 'error', sendingTime: utils.formatDate(new Date()), title: 'Not found Resource', message: 'No account with id : ' + this.props.match.params.realestateUuid + ' found.'})
                    } else {
                        this.props.addToastMessage({
                            type: 'error',
                            sendingTime: utils.formatDate(new Date()),
                            title: 'Server Error',
                            message: "Error occurred while processing you request. Details are"
                        })
                    }
                },
                (error) => {
                    this.props.addToastMessage({
                        type: 'error',
                        sendingTime: utils.formatDate(new Date()),
                        title: 'Server Error',
                        message: "Error occurred while processing you request. Details are"
                    })
                }
            )
    }


    handleAccountModification = () => {
        this.props.history.push(utils.contextualizePath('/account/edit'));
    }

    displayPicture =  (pictureData) => {
        const gallery = this.gallery;
        const url = constants.authenticationApiEndpoints.FILE_DATA;
        return <ImageFromBytes url={url} gallery={gallery} key={pictureData.label}
                               name={pictureData.label}
                               resourceUuid={pictureData.resourceUuid}
                               index={0} readOnly={true} />
    }

    render() {
        return (

            <>
                <div className="container" data-layout="container">
                    <SideNavBar />
                    <div className="content">
                        <TopNavBar
                            ref={this.topNavBarRef}
                            history={this.props.history}/>
                        <div className="card mb-3">
                            <div className="card-header bg-light">
                                <div className="row g-3 mb-3">
                                    <div className="col-md-4 ">
                                        <h4 className="mb-0">Mon compte</h4>
                                        <label>
                                            <h6>{this.state.accountDetails.type}</h6>
                                        </label>
                                    </div>
                                    <div className="col-md-8 "></div>
                                </div>
                                {
                                    (this.state.accountDetails.type && 'corporation' === this.state.accountDetails.type.toLowerCase())?
                                        <Corporation accountDetails={this.state.accountDetails}/>
                                    :<Individual accountDetails={this.state.accountDetails}/>
                                }

                                <div className="row g-3 mb-3">
                                    <div className="col-md-12 ">
                                        <div className="mb-3">
                                            <h6 className="fs-0 mb-0">Site web</h6>
                                            <p className="mb-1"> {this.state.accountDetails.website}</p>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.accountDetails.contacts.map(contact => {
                                        return <Contact
                                                contact={contact}
                                                key={contact['resourceUuid']}
                                            />
                                    })
                                }

                                {
                                    this.state.accountDetails.bankAccounts.map(bankAccount => {
                                        return <BankAccount
                                                bankAccount={bankAccount}
                                                key={bankAccount['resourceUuid']}
                                            />
                                    })
                                }

                            </div>

                            <div className="row g-3 mb-3">
                                <div className="col-md-1 "></div>
                                <div className="col-md-5 ">
                                    <div className="mb-3">
                                        <h6 className="fs-0 mb-0" style={{marginLeft:'10px'}}>Signature</h6>
                                        {
                                            this.state.accountDetails.signaturePicture? this.displayPicture(this.state.accountDetails.signaturePicture):''
                                        }
                                    </div>
                                </div>

                                <div className="col-md-1 "></div>
                                <div className="col-md-5 ">
                                    <div className="mb-3">
                                        <h6 className="fs-0 mb-0" style={{marginLeft:'10px'}}>Cachet</h6>
                                        {
                                            this.state.accountDetails.stampPicture? this.displayPicture(this.state.accountDetails.stampPicture):''
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="row g-3 mb-3 border-top">
                                <div className="col-md-8"></div>

                                <div className="col-md-2 ">
                                    <button disabled={this.state.isDisabledSubmitButton}
                                            className="btn btn-primary d-block w-100 mt-3"
                                            onClick={this.handleAccountModification}
                                            name="modify"
                                    >Modifier</button>
                                </div>
                                <div className="col-md-2 ">
                                    <div className="row mb-3">
                                        <div className="col">
                                            <button  disabled={this.state.isDisabledSubmitButton}
                                                     className="btn btn-danger d-block w-100 mt-3"
                                                     style={{marginTop:'1px'}}
                                                     onClick={this.handleAccountDeletion}
                                                     name="delete"
                                            >Supprimer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

export default AccountDetails;
