import React, {Component} from 'react';

class DataTableOverViewActions extends Component{

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
             <button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end" type="button" id="dropdown-simple-pagination-table-item-13"
                  data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent">
              <span className="fas fa-ellipsis-h fs--1"></span></button>
              <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown-simple-pagination-table-item-13">
              <a className="dropdown-item text-success" href="#!" onClick={() => this.props.details()}>Details</a>
              <a className="dropdown-item text-warning" href="#!" onClick={() => this.props.edit()}>Edit</a>
              <a className="dropdown-item text-danger" href="#!" onClick={() => this.props.delete()}>Delete</a> </div>
            </>
        );
    };
}
export default DataTableOverViewActions;